/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

export const startLoading = (message) => {
    return {
        type: types.START_LOADING,
        message,
    };
};

export const doneLoading = () => {
    return {
        type: types.DONE_LOADING,
    };
};
