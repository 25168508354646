/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { COPYRIGHT } from 'site-kit';
import { Button } from 'sarsaparilla';

export default function FooterRibbon() {
    const renderCopyright = () => {
        if (process.env.SN_TERMS_OF_USE_ARTICLE) {
            return (
                <div className="footer-copyright">
                    {COPYRIGHT} |
                    <Button
                        appearance="link"
                        className="ml-half"
                        href={process.env.SN_TERMS_OF_USE_ARTICLE}
                    >
                        Terms of Use
                    </Button>
                </div>
            );
        }

        return <div className="footer-copyright">{COPYRIGHT}</div>;
    };
    return (
        <footer className="footer-ribbon">
            <div className="footer-ribbon-links">
                <a href="/internal/account/hub">The Hub</a>
                &nbsp;&nbsp; | &nbsp;&nbsp;
                <a href="/internal/account/site-map">Site Map</a>
            </div>
            {renderCopyright()}
        </footer>
    );
}
