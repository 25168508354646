/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import TextHighlight from './TextHighlight';

const propTypes = {
    value: PropTypes.string.isRequired,
    //row: PropTypes.object,
    state: PropTypes.shape({
        globalFilter: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
};

export default function HighlightCell({ value, state: { globalFilter } }) {
    return <TextHighlight body={value} searchTerm={globalFilter?.value || ''} />;
}

HighlightCell.propTypes = propTypes;
