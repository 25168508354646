/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    accountToSave: null,
    concessionaire: null,
    accountSaved: null,
    creating: false,
    error: null,
    loadingLocationsError: null,
    loadingConcessionairesError: null,
    deletedUser: null,
    deletedUsers: [],
    deleteUserError: null,
    roleAssignmentOptions: {},
    assignedRoleForLocationId: {},
    reloadAccount: false,
    assignedLocations: [],
    assignedConcessionaires: [],
    noteAddedForExistingUser: false,
    readOnly: false,
    requestConfirmResponse: null,
    userIdEnabledPasswordReset: null,
    errorEnablingPasswordReset: null,
    assignablePermits: [],
    fetchAssignableConcessionairePermitsError: null,
};

const updateUser = (state = initialState, action) => {
    switch (action.type) {
        case types.CANCEL_UPDATE:
            return {
                ...state,
                accountToSave: null,
                error: null,
                assignedRoleForLocationId: {},
                assignedLocations: [],
                assignablePermits: [],
                assignedConcessionaires: [],
                userIdEnabledPasswordReset: null,
                errorEnablingPasswordReset: null,
            };
        case types.ACCOUNT_SAVED:
            return {
                ...state,
                assignedRoleForLocationId: {},
                assignedLocations: [],
                assignablePermits: [],
                assignedConcessionaires: [],
                accountSaved: action.user,
                accountToSave: action.user,
                loadingLocationsError: null,
                loadingConcessionairesError: null,
                error: null,
            };
        case types.UPDATING_ROLES_SUCCESSFUL:
            return { ...state, accountSaved: action.user, error: null };
        case types.SAVE_FAILED:
            return { ...state, error: action.error, reloadAccount: false };
        case types.CREATING_USER:
            return {
                ...state,
                assignedRoleForLocationId: {},
                roleAssignmentOptions: {},
                assignedLocations: [],
                assignablePermits: [],
                assignedConcessionaires: [],
                accountToSave: null,
                creating: true,
                loadingLocationsError: null,
                loadingConcessionairesError: null,
                reloadAccount: true,
                error: null,
                readOnly: false,
                userIdEnabledPasswordReset: null,
                errorEnablingPasswordReset: null,
            };
        case types.EDITING_USER:
            return {
                ...state,
                assignedRoleForLocationId: {},
                roleAssignmentOptions: {},
                assignedLocations: [],
                assignablePermits: [],
                assignedConcessionaires: [],
                accountToSave: action.accountToSave,
                creating: false,
                loadingLocationsError: null,
                loadingConcessionairesError: null,
                reloadAccount: true,
                error: null,
                noteAddedForExistingUser: false,
                readOnly: action.readOnly,
                userIdEnabledPasswordReset: null,
                errorEnablingPasswordReset: null,
            };
        case types.ROLE_ASSIGNED: {
            const update = {};

            update[action.role.location.location_id] = action.role;
            const updatedRoleForLocationId = {
                ...state.assignedRoleForLocationId,
                ...update,
            };

            const optionsForAssignedRole =
                state.roleAssignmentOptions[action.role.location.location_id];
            const updatedInitialOption = optionsForAssignedRole.dropDownOptions.find(
                (option) => option.value === action.role.role_type
            );

            const updatedRoleAssignmentOptions = { ...state.roleAssignmentOptions };
            updatedRoleAssignmentOptions[action.role.location.location_id] = {
                ...optionsForAssignedRole,
                initiallySelectedOption: updatedInitialOption,
            };

            return {
                ...state,
                assignedRoleForLocationId: updatedRoleForLocationId,
                roleAssignmentOptions: updatedRoleAssignmentOptions,
            };
        }
        case types.ASSIGN_LOCATION:
            return {
                ...state,
                error: null,
                assignedLocations: [...state.assignedLocations, action.location],
            };
        case types.DELETE_LOCATION: {
            const updatedRoleAssignmentMap = { ...state.assignedRoleForLocationId };
            delete updatedRoleAssignmentMap[action.location.location_id];

            const assignedLocationIndexToDelete = state.assignedLocations.indexOf(
                action.location
            );

            return {
                ...state,
                assignedRoleForLocationId: updatedRoleAssignmentMap,
                assignedLocations: [
                    ...state.assignedLocations.slice(0, assignedLocationIndexToDelete),
                    ...state.assignedLocations.slice(assignedLocationIndexToDelete + 1),
                ],
            };
        }
        case types.GET_ASSIGNED_CONCESSIONAIRES_SUCCESS:
            return {
                ...state,
                assignedConcessionaires: action.concessionaires,
                loadingAssignedConcessionairesError: false,
            };
        case types.GET_ASSIGNED_CONCESSIONAIRES_FAILED:
            return {
                ...state,
                assignedConcessionaires: [],
                loadingAssignedConcessionairesError: action.error,
            };
        case types.ASSIGN_CONCESSIONAIRE:
            return {
                ...state,
                error: null,
                assignedConcessionaires: [
                    ...state.assignedConcessionaires,
                    action.concessionaire,
                ],
            };
        case types.DELETE_CONCESSIONAIRE: {
            const assignedConcessionaireIndexToDelete =
                state.assignedConcessionaires.indexOf(action.concessionaire);

            return {
                ...state,
                assignedConcessionaires: [
                    ...state.assignedConcessionaires.slice(
                        0,
                        assignedConcessionaireIndexToDelete
                    ),
                    ...state.assignedConcessionaires.slice(
                        assignedConcessionaireIndexToDelete + 1
                    ),
                ],
            };
        }
        case types.LOADING_MANAGED_LOCATIONS_SUCCESSFUL:
            return { ...state, loadingLocationsError: null };
        case types.LOADING_MANAGED_LOCATIONS_FAILED:
            return { ...state, loadingLocationsError: action.error };
        case types.LOADING_MANAGED_CONCESSIONAIRES_SUCCESSFUL:
            return { ...state, loadingConcessionairesError: null };
        case types.LOADING_MANAGED_CONCESSIONAIRES_FAILED:
            return { ...state, loadingConcessionairesError: true };
        case types.CONTINUE_FROM_ASSIGNING_LOCATIONS:
            return {
                ...state,
                loadingLocationsError: null,
                error: null,
                roleAssignmentOptions: action.roleAssignmentOptions,
                assignedRoleForLocationId: action.assignedRoleForLocationId,
            };
        case types.CANCEL_ASSIGNING_LOCATIONS:
            return { ...state, loadingLocationsError: null, error: null };
        case types.CONTINUE_FROM_ASSIGNING_CONCESSIONAIRES:
            return { ...state, loadingConcessionairesError: null, error: null };
        case types.CANCEL_ASSIGNING_CONCESSIONAIRES:
            return { ...state, loadingConcessionairesError: null, error: null };
        case types.CONTINUE_FROM_CREATING_USER:
            return { ...state, accountToSave: action.createdUser, error: null };
        case types.GETTING_USER:
            return { ...state, error: null, accountToSave: null };
        case types.GET_USER_FAILED:
        case types.ROLE_DELETE_FAILED:
            return { ...state, error: action.error };
        case types.ROLE_DELETED: {
            const rolesBeforeDelete = state.accountToSave.roles;
            const indexOfDeleted = rolesBeforeDelete.indexOf(action.role);

            const updatedUser = {
                ...state.accountToSave,
                roles: [
                    ...rolesBeforeDelete.slice(0, indexOfDeleted),
                    ...rolesBeforeDelete.slice(indexOfDeleted + 1),
                ],
            };

            let updatedAssignedConcessionaires = state.assignedConcessionaires;
            if (
                action.user &&
                action.user.concessionaire_ids &&
                updatedAssignedConcessionaires.length
            ) {
                const newConcIDs = action.user.concessionaire_ids;
                const filteredAssignedConcessionaires =
                    state.assignedConcessionaires.filter((conc) =>
                        newConcIDs.includes(conc.concessionaire_id)
                    );
                updatedAssignedConcessionaires = [...filteredAssignedConcessionaires];
            }

            return {
                ...state,
                accountToSave: action.user || updatedUser,
                assignedConcessionaires: updatedAssignedConcessionaires,
            };
        }
        case types.USER_DELETED:
            return {
                ...state,
                creating: false,
                deletedUser: action.deletedUser,
                deletedUsers: action.append
                    ? state.deletedUsers.concat(action.deletedUser)
                    : [],
                deleteUserError: null,
                reloadUserGrid: true,
            };
        case types.USER_DELETE_FAILED:
            return { ...state, deleteUserError: action.error };

        case types.NOTE_ADDED_FOR_EXISTING_USER:
            return { ...state, noteAddedForExistingUser: true };

        case types.LOCATION_CHANGE:
            return {
                ...state,
                deleteUserError: null,
                deletedUser: null,
                deletedUsers: [],
                loadingLocationsError: null,
                loadingConcessionairesError: null,
                accountSaved: null,
            };

        case types.RESET_UPDATE_USER:
        case types.FOUND_NEW_USER:
        case types.LOGGED_OUT:
            return initialState;

        case types.REQUEST_CONFIRM_STATUS:
            return {
                ...state,
                requestConfirmResponse: {
                    ttl: action.ttl,
                    error: action.error,
                },
            };

        case types.RESET_CONFIRM_STATUS:
            return { ...state, requestConfirmResponse: null };

        case types.RESET_RESET_STATUS:
            return {
                ...state,
                userIdEnabledPasswordReset: null,
                errorEnablingPasswordReset: null,
            };

        case types.ENABLING_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                userIdEnabledPasswordReset: action.userId,
                errorEnablingPasswordReset: null,
            };

        case types.ENABLING_PASSWORD_RESET_FAILED:
            return {
                ...state,
                userIdEnabledPasswordReset: null,
                errorEnablingPasswordReset: action.error,
            };

        // FOR Concesisonaire Location Filtering
        case types.FETCH_ASSIGNABLE_CONCESSIONAIRE_PERMIT_SUCCESSFUL:
            return {
                ...state,
                assignablePermits: action.permits,
                fetchAssignableConcessionairePermitsError: null,
            };

        case types.FETCH_ASSIGNABLE_CONCESSIONAIRE_PERMIT_FAILED:
            return {
                ...state,
                assignablePermits: [],
                fetchAssignableConcessionairePermitsError: action.error,
            };

        case types.EMAIL_CHANGE_CANCELLED:
            return {
                ...state,
                accountToSave: { ...state.accountToSave, email_change: null },
            };
        case types.LOGIN_GOV_UNLINKED:
            return {
                ...state,
                accountToSave: {
                    ...state.accountToSave,
                    oidc_user_info: {
                        loginDotGov: {},
                    },
                },
            };

        default:
            return state;
    }
};

export default updateUser;
