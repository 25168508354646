/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    TYPES,
    FlexRow,
    FlexCol,
    Button,
    Icon,
    TextFieldStateless,
    Spacer,
    Alert,
} from 'sarsaparilla';
import SiteWrapper from './SiteWrapper';
import UserManagementHeader from './UserManagementHeader';

import {
    assignConcessionaire as assignConcessionaireAction,
    cancelAssigningConcessionaires as cancelAssigningConcessionairesAction,
    upsertConcessionaire as upsertConcessionaireAction,
    deleteConcessionaire as deleteConcessionaireAction,
} from '../actions/updateUser';
import { loadManagedConcessionaires as loadManagedConcessionairesAction } from '../actions/manageConcessionaires';

import EditUserProgress from '../components/EditUserProgress';
import {
    errorForAssigningConcessionaire,
    errorForLoadingConcessionaires,
} from '../utilities/errorMessages';
import SearchTable from '../components/tableUtil/SearchTable';
import TextHighlight from '../components/tableUtil/TextHighlight';
import HighlightCell from '../components/tableUtil/HighlightCell';

const propTypes = {
    history: TYPES.HISTORY,
    cancelAssigningConcessionaires: PropTypes.func,
    assignConcessionaire: PropTypes.func,
    deleteConcessionaire: PropTypes.func,
    loadManagedConcessionaires: PropTypes.func,
    upsertConcessionaire: PropTypes.func,
    loadingConcessionairesError: PropTypes.string,
    creating: PropTypes.bool,
    error: PropTypes.string,
    accountToSave: PropTypes.any,
    assignedConcessionaires: PropTypes.array,
    managedConcessionaires: PropTypes.array,
};

function EditConcessionaires({
    history,
    cancelAssigningConcessionaires,
    assignConcessionaire,
    deleteConcessionaire,
    loadManagedConcessionaires,
    upsertConcessionaire,
    loadingConcessionairesError,
    creating,
    error,
    accountToSave,
    assignedConcessionaires,
    managedConcessionaires,
}) {
    useEffect(() => {
        loadManagedConcessionaires();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getActivePermits(conc) {
        // *MAY be* OLD has been moved to action (charlie comments lie)
        return conc.permits.filter((permit) => {
            const startDays =
                -1 * moment({ hours: 0 }).diff(permit.permit_start_date, 'days');
            const endDays =
                -1 * moment({ hours: 0 }).diff(permit.permit_end_date, 'days');
            return startDays <= 0 && endDays > 0;
        });
    }

    function getStateListString(concessionaire) {
        const states = [];
        concessionaire.permits.forEach((permit) => {
            if (permit.location_state_code) {
                const state = permit.location_state_code.toUpperCase();
                if (states.indexOf(state) === -1) {
                    states.push(state);
                }
            }
        });
        return states.join(', ');
    }

    function getNumberOfFacilities(concessionaire) {
        const activePermits =
            concessionaire.activePermits || getActivePermits(concessionaire);
        return [
            ...new Set(activePermits.map((p) => `${p.location_id}_${p.location_type}`)),
        ].length;
    }

    const columns = useMemo(() => {
        const renderConcessionaireName = (value, original, globalFilter) => {
            return (
                <Button appearance="link" onClick={() => assignConcessionaire(original)}>
                    <TextHighlight searchTerm={globalFilter} body={value} />
                </Button>
            );
        };

        const renderActions = (original) => {
            return (
                <Button appearance="link" onClick={() => assignConcessionaire(original)}>
                    Add
                </Button>
            );
        };

        return [
            {
                Header: 'Concessionaire Company',
                accessor: 'concessionaire_name',
                Cell: ({ value, row: { original }, state: { globalFilter } }) =>
                    renderConcessionaireName(value, original, globalFilter),
                width: 80,
                minWidth: 80,
            },
            {
                Header: 'Concessionaire ID',
                accessor: 'concessionaire_id',
                Cell: HighlightCell,
                width: 50,
                minWidth: 50,
            },
            {
                Header: 'State Permits Operate In',
                id: 'state_permits_operate_in',
                accessor: getStateListString,
                Cell: HighlightCell,
                sortable: false,
                width: 80,
                minWidth: 80,
            },
            {
                Header: 'Number of Facilities',
                id: 'number_of_facilities',
                disableGlobalFilter: true,
                accessor: getNumberOfFacilities,
                width: 30,
                minWidth: 30,
            },
            {
                Header: 'Actions',
                id: 'actions_column',
                sortable: false,
                disableGlobalFilter: true,
                Cell: ({ row: { original } }) => renderActions(original),
                width: 30,
                minWidth: 30,
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterUI = useCallback(
        ({ setGlobalFilter, globalFilter }) => (
            <TextFieldStateless
                label="Search Text"
                isLabelVisible={false}
                placeholder="Find Concessionaires"
                onChange={({ target: { value } }) => setGlobalFilter(value)}
                handleClearButtonClick={() => setGlobalFilter('')}
                hasClearButton
                value={globalFilter || ''}
                iconElement={<Icon iconName="search" />}
                id="filter-text-search"
            />
        ),
        []
    );

    const assignedConcessionaireList = useMemo(() => {
        if (assignedConcessionaires.length === 0) {
            return (
                <div className="edit-locations-empty-message">
                    No Concessionaires Selected
                </div>
            );
        }
        return assignedConcessionaires.map((concessionaire) => (
            <FlexRow
                className="edit-locations-assigned-locations-row"
                key={`assigned-concessionaire-${concessionaire.concessionaire_id}`}
            >
                <FlexCol xs={4}>
                    <Button
                        appearance="link"
                        onClick={() => deleteConcessionaire(concessionaire)}
                    >
                        {concessionaire.concessionaire_name}
                    </Button>
                </FlexCol>
                <FlexCol xs={2}>{concessionaire.concessionaire_id}</FlexCol>
                <FlexCol xs={2}>{getStateListString(concessionaire)}</FlexCol>
                <FlexCol xs={2}>{getNumberOfFacilities(concessionaire)}</FlexCol>
                <FlexCol xs={2}>
                    <Button
                        appearance="link"
                        onClick={() => deleteConcessionaire(concessionaire)}
                    >
                        Remove
                    </Button>
                </FlexCol>
            </FlexRow>
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedConcessionaires]);

    const filteredManagedConcessionaires = useMemo(() => {
        const assignedIds = assignedConcessionaires.map((conc) => conc.concessionaire_id);
        const data = managedConcessionaires.filter((conc) => {
            const isAssigned = assignedIds.includes(conc.concessionaire_id);
            const activePermits = conc.activePermits || getActivePermits(conc);

            return !isAssigned && activePermits.length > 0;
        });

        return data;
    }, [assignedConcessionaires, managedConcessionaires]);

    return (
        <SiteWrapper>
            <div className="page-title">
                <UserManagementHeader editing={false} />
            </div>
            <div id="UserManagement" className="page-content wrapper">
                <div className="edit-user-wrapper">
                    <div className="progress-ribbon-wrapper">
                        <EditUserProgress
                            showUserDetails={creating}
                            type="concessionaire"
                            current="1"
                        />
                        <div className="user-management-button-header">
                            <button
                                type="button"
                                className="edit-concessionaires-cancel edit-locations-cancel rec-button-tertiary"
                                onClick={() => cancelAssigningConcessionaires(history)}
                            >
                                Previous
                            </button>
                            <button
                                type="button"
                                className="edit-concessionaires-continue edit-locations-continue rec-button-primary"
                                onClick={() => upsertConcessionaire(history)}
                            >
                                Save
                            </button>
                        </div>
                    </div>

                    {!!error && (
                        <Alert className="mb-2" type="error" hasAriaLive>
                            {errorForAssigningConcessionaire(error)}
                        </Alert>
                    )}

                    <div className="edit-concessionaire edit-locations-wrapper">
                        <div className="ia-create-user-note h5">
                            Select a role at each location for
                            <b>
                                {accountToSave?.first_name || 'NA'}{' '}
                                {accountToSave?.last_name || 'NA'}
                            </b>
                            :
                        </div>
                        <div className="edit-locations-assigned-wrapper">
                            <div className="edit-locations-assigned-locations-header">
                                Assigned Concessionaires
                            </div>
                            {assignedConcessionaireList}
                        </div>

                        {!!loadingConcessionairesError && (
                            <Alert type="error" hasAriaLive>
                                {errorForLoadingConcessionaires()}
                            </Alert>
                        )}

                        <Spacer size="sm" />
                        <SearchTable
                            data={filteredManagedConcessionaires}
                            columns={columns}
                            filtersComponent={filterUI}
                            noDataText="No concessionaires found."
                            defaultSortId="concessionaire_id"
                        />
                    </div>
                </div>
            </div>
        </SiteWrapper>
    );
}

EditConcessionaires.propTypes = propTypes;

const mapStateToProps = (state) => {
    return {
        assignedConcessionaires: state.updateUser.assignedConcessionaires,
        //TODO: track down which of these redux states/actions/reducers are the 'old' one and remove. (probably fetchManagedConcessionaries) -_- Charlie!
        managedConcessionaires: state.manageConcessionaires.managedConcessionaires.length
            ? state.manageConcessionaires.managedConcessionaires
            : state.fetchManagedConcessionaires.managedConcessionaires,
        creating: state.updateUser.creating,
        accountToSave: state.updateUser.accountToSave,
        error: state.updateUser.error,
        loadingConcessionairesError: state.updateUser.loadingConcessionairesError,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            assignConcessionaire: assignConcessionaireAction,
            deleteConcessionaire: deleteConcessionaireAction,
            cancelAssigningConcessionaires: cancelAssigningConcessionairesAction,
            loadManagedConcessionaires: loadManagedConcessionairesAction,
            upsertConcessionaire: upsertConcessionaireAction,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(EditConcessionaires);
