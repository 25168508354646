/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';
import * as globals from '../constants/globals';
import * as roles from '../utilities/roles';
import { makeAuthorizedRequest } from './login';
import { doneLoading, startLoading } from './loading';
import { setCooperator, setEmployee } from './manageCooperatorsSet';
import { validateCooperator } from '../utilities/validation';
import { makeCompatNavigate } from '../utilities/compatNavigateUtil';

// LOAD
const reloadManagedCooperatorErrors = () => {
    return {
        type: types.RELOAD_MANAGE_COOPERATOR_ERRORS,
    };
};

export const loadedManagedCooperators = (cooperators) => {
    return {
        cooperators,
        type: types.LOADING_MANAGED_COOPERATORS_SUCCESSFUL,
    };
};

const loadingManagedCooperatorsFailed = (error) => {
    return {
        error,
        type: types.LOADING_MANAGED_COOPERATORS_FAILED,
    };
};

export const loadManagedCooperators = () => {
    return async (dispatch) => {
        dispatch(reloadManagedCooperatorErrors());
        dispatch(startLoading('Loading Cooperator Data'));

        try {
            const managedCooperatorsResponse = await makeAuthorizedRequest(
                `${globals.API_URL}/cooperator`,
                'GET',
                dispatch
            );
            const cooperators = managedCooperatorsResponse.cooperators;

            dispatch(loadedManagedCooperators(cooperators));
        } catch (error) {
            dispatch(loadingManagedCooperatorsFailed(error));
        }

        dispatch(doneLoading());
    };
};

const loadedCooperatorEmployees = (employees) => {
    return {
        employees,
        type: types.LOADING_COOPERATOR_EMPLOYEES_SUCCESSFUL,
    };
};

const loadingCooperatorEmployeesFailed = (error) => {
    return {
        error,
        type: types.LOADING_COOPERATOR_EMPLOYEES_FAILED,
    };
};

export const loadCooperatorEmployees = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const coopId = state.manageCooperatorsSet.managedCooperator.cooperator_id;

        dispatch(reloadManagedCooperatorErrors());
        dispatch(startLoading('Loading Employees'));

        try {
            const cooperatorUsersResponse = await makeAuthorizedRequest(
                `${globals.API_URL}/cooperator/${coopId}/user`,
                'GET',
                dispatch
            );
            const employees = cooperatorUsersResponse.users;

            dispatch(loadedCooperatorEmployees(employees));
        } catch (error) {
            dispatch(loadingCooperatorEmployeesFailed(error));
        }

        dispatch(doneLoading());
    };
};

// SAVE
const saveCooperatorSuccessful = (cooperator) => {
    return {
        cooperator,
        type: types.SAVING_COOPERATOR_SUCCESSFUL,
    };
};

const saveCooperatorFailed = (error) => {
    return {
        error,
        type: types.SAVING_COOPERATOR_FAILED,
    };
};

const saveCooperatorsSuccessful = () => {
    return {
        type: types.SAVING_MANAGED_COOPERATORS_SUCCESSFUL,
    };
};

const saveCooperatorsFailed = (error) => {
    return {
        error,
        type: types.SAVING_MANAGED_COOPERATORS_FAILED,
    };
};

const saveCooperator = async (dispatch, cooperator, add) => {
    const coopId = cooperator.cooperator_id;
    const method = coopId ? 'PUT' : 'POST';
    const url = coopId ? `/${coopId}` : '';

    // TODO: Allow user to set with picker at some point
    const bodyObj = {
        location_id: '233396',
        location_type: 'Permit',
        ...cooperator,
    };

    try {
        validateCooperator(cooperator);

        const body = JSON.stringify(bodyObj);

        const cooperatorResponse = await makeAuthorizedRequest(
            `${globals.API_URL}/cooperator${url}`,
            method,
            dispatch,
            body
        );
        const updated = cooperatorResponse.cooperator;

        dispatch(saveCooperatorSuccessful(updated));

        if (add) {
            dispatch(setCooperator(updated));
        }
    } catch (error) {
        dispatch(saveCooperatorFailed(error));
        throw error;
    }
};

export const saveCooperators = (cooperators, add, history) => {
    return async (dispatch) => {
        dispatch(reloadManagedCooperatorErrors());
        dispatch(startLoading('Saving...'));

        try {
            const savePromises = [];
            for (const coop of cooperators) {
                const promise = saveCooperator(dispatch, coop, add);
                savePromises.push(promise);
            }

            await Promise.all(savePromises);

            dispatch(saveCooperatorsSuccessful());
            if (add) {
                const navigate = makeCompatNavigate(history);
                navigate('/internal/account/cooperator-details');
            }
        } catch (error) {
            dispatch(saveCooperatorsFailed(error));
        }

        dispatch(doneLoading());
    };
};

const saveEmployeeSuccessful = (employee) => {
    return {
        employee,
        type: types.SAVING_EMPLOYEE_SUCCESSFUL,
    };
};

const saveEmployeesSuccessful = () => {
    return {
        type: types.SAVING_COOPERATOR_EMPLOYEES_SUCCESSFUL,
    };
};

const saveEmployeesFailed = (error) => {
    return {
        error,
        type: types.SAVING_COOPERATOR_EMPLOYEES_FAILED,
    };
};

const saveEmployee = async (dispatch, employee, add) => {
    const userId = employee.user_id;
    const method = userId ? 'PUT' : 'POST';
    const url = userId ? `/${userId}` : '';
    const body = JSON.stringify(employee);

    const userResponse = await makeAuthorizedRequest(
        `${globals.API_URL}/user${url}`,
        method,
        dispatch,
        body
    );
    const updated = userResponse.user;

    dispatch(saveEmployeeSuccessful(updated));

    if (add) {
        dispatch(setEmployee(updated));
    }
};

export const saveEmployees = (employees, add, history) => {
    return async (dispatch, getState) => {
        const state = getState();
        const cooperator = state.manageCooperatorsSet.managedCooperator;
        const location = {
            location_id: cooperator.location_id,
            location_type: cooperator.location_type,
            location_path: cooperator.location_path,
        };

        dispatch(reloadManagedCooperatorErrors());
        dispatch(startLoading('Saving...'));

        try {
            const savePromises = [];
            for (const emp of employees) {
                const sendEmp = {
                    cooperator_id: cooperator.cooperator_id,
                    is_cooperator: true,
                    roles: [
                        {
                            role_type: roles.COOPERATOR,
                            location,
                        },
                    ],
                    ...emp,
                };

                const promise = saveEmployee(dispatch, sendEmp, add);
                savePromises.push(promise);
            }

            await Promise.all(savePromises);

            dispatch(saveEmployeesSuccessful());
            if (add) {
                const navigate = makeCompatNavigate(history);
                navigate('/internal/account/cooperator-details');
            }
        } catch (error) {
            dispatch(saveEmployeesFailed(error));
        }

        dispatch(doneLoading());
    };
};

// DELETE
const deleteCooperatorSuccessful = (cooperator) => {
    return {
        cooperator,
        type: types.DELETING_COOPERATOR_SUCCESSFUL,
    };
};

const deleteCooperatorsSuccessful = () => {
    return {
        type: types.DELETING_MANAGED_COOPERATORS_SUCCESSFUL,
    };
};

const deleteCooperatorsFailed = (error) => {
    return {
        error,
        type: types.DELETING_MANAGED_COOPERATORS_FAILED,
    };
};

const deleteCooperator = async (dispatch, cooperator) => {
    const coopId = cooperator.cooperator_id;
    const method = 'DELETE';
    const url = `/${coopId}`;

    await makeAuthorizedRequest(`${globals.API_URL}/cooperator${url}`, method, dispatch);

    dispatch(deleteCooperatorSuccessful(cooperator));
};

export const deleteCooperators = (cooperators) => {
    return async (dispatch) => {
        dispatch(reloadManagedCooperatorErrors());
        dispatch(startLoading('Deleting...'));

        try {
            const deletePromises = [];
            for (const coop of cooperators) {
                const promise = deleteCooperator(dispatch, coop);
                deletePromises.push(promise);
            }

            await Promise.all(deletePromises);

            dispatch(deleteCooperatorsSuccessful());
        } catch (error) {
            dispatch(deleteCooperatorsFailed(error));
        }

        dispatch(doneLoading());
    };
};

const deleteEmployeeSuccessful = (employee) => {
    return {
        employee,
        type: types.DELETING_EMPLOYEE_SUCCESSFUL,
    };
};

const deleteEmployeesSuccessful = () => {
    return {
        type: types.DELETING_COOPERATOR_EMPLOYEES_SUCCESSFUL,
    };
};

const deleteEmployeesFailed = (error) => {
    return {
        error,
        type: types.DELETING_COOPERATOR_EMPLOYEES_FAILED,
    };
};

const deleteEmployee = async (dispatch, employee) => {
    const userId = employee.user_id;
    const method = 'DELETE';
    const url = `/${userId}`;

    await makeAuthorizedRequest(`${globals.API_URL}/user${url}`, method, dispatch);

    dispatch(deleteEmployeeSuccessful(employee));
};

export const deleteEmployees = (employees) => {
    return async (dispatch) => {
        dispatch(reloadManagedCooperatorErrors());
        dispatch(startLoading('Deleting...'));

        try {
            const deletePromises = [];
            for (const emp of employees) {
                const promise = deleteEmployee(dispatch, emp);
                deletePromises.push(promise);
            }

            await Promise.all(deletePromises);

            dispatch(deleteEmployeesSuccessful());
        } catch (error) {
            dispatch(deleteEmployeesFailed(error));
        }

        dispatch(doneLoading());
    };
};
