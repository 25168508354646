/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import {
    FlexCol,
    FlexRow,
    FormattedDateTime,
    StyledModal,
    Text,
} from '@fp/sarsaparilla';

import RangerAppQrCodeLogo from '../../img/ranger-app-qr-code-logo.png';
import RangerAppWarning from '../../img/ranger-app-warning.png';
import MfaQrCodePlaceholder from '../../img/mfa-qr-code-placeholder.png';

const propTypes = {
    token: PropTypes.object,
    qrCode: PropTypes.object,
    closeModal: PropTypes.func,
};

export default function ViewQrCodeTokenModal({ token, qrCode, closeModal }) {
    const imgSrc = qrCode ? URL.createObjectURL(qrCode) : MfaQrCodePlaceholder;

    return (
        <StyledModal
            size="sm"
            isOpen={true}
            heading="One-Time Use Only"
            id="RangerTokenQrCodeModal"
            onRequestClose={closeModal}
        >
            <FlexRow className="mx-0 ">
                <FlexCol md={10} className="mb-3">
                    <Text fontWeight="normal" size="lg">
                        Scan with your
                    </Text>
                    <Text fontWeight="normal" size="lg">
                        {process.env.SITE_NAME} Ranger App
                    </Text>
                </FlexCol>
                <FlexCol md={2} className="pl-3 mb-3">
                    <img src={RangerAppQrCodeLogo} alt="QR Code Logo" height={64} />
                </FlexCol>
            </FlexRow>
            <FlexRow className="mx-0">
                <FlexCol className="mb-3">
                    <Text fontWeight="normal" align="center" size="lg">
                        RANGER APP
                    </Text>
                    <Text fontWeight="semibold" align="center" size="lg">
                        Token Authentication
                    </Text>
                </FlexCol>
            </FlexRow>
            <FlexRow className="mx-0 ">
                <FlexCol md={2} className="pl-6 pt-1 mb-3">
                    <img src={RangerAppWarning} alt="Warning" height={24} />
                </FlexCol>
                <FlexCol md={10} className="mb-3">
                    <Text fontWeight="semibold">
                        Do not post or distribute. For single device
                    </Text>
                    <Text fontWeight="semibold">authentication only.</Text>
                </FlexCol>
            </FlexRow>
            <FlexRow className="mx-0">
                <FlexCol md={7} className="mb-3">
                    <Text size="sm">Token Information</Text>
                    <hr />
                    <Text size="sm">
                        <b>Nickname: </b>
                        {token.device_nickname ?? 'N/A'}
                    </Text>
                    <Text size="sm">
                        <b>Token created by: </b>
                        {token.created_by_name} -
                    </Text>
                    <Text size="sm">
                        <FormattedDateTime
                            date={new Date(token.created_at)}
                            format={'LL'}
                        />
                    </Text>
                    <Text size="sm">
                        <b>Expiration Date: </b>
                        <FormattedDateTime
                            date={new Date(token.expire_at)}
                            format={'LL'}
                        />
                    </Text>
                </FlexCol>
                <FlexCol md={5} className="pl-3 mb-3">
                    <img src={imgSrc} alt="QR Code" width={128} height={128} />
                </FlexCol>
            </FlexRow>
            <FlexRow className="mx-0">
                <FlexCol className="mb-3">
                    <Text fontWeight="normal" align="center" size="lg">
                        Scan QR code to authenticate your device.
                    </Text>
                </FlexCol>
            </FlexRow>
        </StyledModal>
    );
}

ViewQrCodeTokenModal.propTypes = propTypes;
