/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    account: null,
    loggedIn: false,
    error: null,
    attempts: 0,
    warnForAutoLogout: false,
    user: null,
};

const login = (state = initialState, action) => {
    switch (action.type) {
        case types.CLEAR_LOGIN_ERROR:
            return {
                ...state,
                error: null,
            };
        case types.MFA_VALIDATE_REQUIRED:
            return {
                ...state,
                mfa_validate_required: true,
            };
        case types.MFA_ENROLL_REQUIRED:
            return {
                ...state,
                mfa_enroll_required: true,
                mfa_opt_out_allowed: action.mfa_opt_out_allowed,
            };
        case types.LOGIN_SUCCESSFUL:
            return {
                ...state,
                account: action.account,
                user: action.account.user,
                loggedIn: true,
                error: null,
                attempts: 0,
                warnForAutoLogout: false,
            };
        case types.SETTING_DEFAULT_ROLE_SUCCESSFUL:
            if (action.updatedUser.user_id !== state.user.user_id) {
                return state;
            }
            return { ...state, user: action.updatedUser };
        case types.UPDATING_ROLES_SUCCESSFUL:
        case types.ACCOUNT_SAVED: {
            if (action.user.user_id !== state.user.user_id) {
                return state;
            }

            const account = { ...state.account, user: action.user };

            return { ...state, account, user: action.user };
        }
        case types.LOGIN_FAILED:
            return {
                ...state,
                loggedIn: false,
                user: null,
                error: action.error,
                attempts: state.attempts + 1,
            };
        case types.LOGGED_OUT:
            return initialState;
        case types.LOGGED_OUT_FOR_INACTIVITY: {
            return initialState;
        }

        case types.LOGGED_OUT_FOR_MUST_RESET_PASSWORD: {
            return {
                ...state,
                account: null,
                user: null,
                loggedIn: false,
                error: null,
                attempts: 0,
                warnForAutoLogout: false,
            };
        }
        case types.DISPLAY_AUTO_LOGOUT_WARNING:
            return { ...state, warnForAutoLogout: true };
        case types.CLOSE_AUTO_LOGOUT_WARNING:
            return { ...state, warnForAutoLogout: false };
        case types.FETCH_DOMAINS_FOR_ROLES_SUCCESS: {
            const accountUpdate = {
                ...state.account,
                domains_by_location_id: action.locDomains,
            };

            return { ...state, account: accountUpdate };
        }
        case types.LOGIN_GOV_UNLINKED_MY_PROFILE: {
            const updatedUser = {
                ...state.user,
                oidc_user_info: {
                    loginDotGov: {},
                },
            };

            return { ...state, user: updatedUser };
        }
        default:
            return state;
    }
};

export default login;
