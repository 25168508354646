/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, FlexCol, FlexRow, Icon, Spacer } from 'sarsaparilla';
import {
    computeLocationStateCode,
    displayStringForLocationType,
    getLocationDisplayName,
} from '../../utilities/locations';

export default function DisplayRoleLocation({ assignedLocations, deleteLocation }) {
    const [sortAsc, setSortAsc] = React.useState(true);
    const [collapseHierarchy, setCollapseHierarchy] = React.useState({});

    const locAssignedLocations = React.useMemo(() => {
        const lal = [...assignedLocations];
        lal.sort((a, b) => {
            const flip = sortAsc ? 1 : -1;
            return (
                getLocationDisplayName(a).localeCompare(getLocationDisplayName(b)) * flip
            );
        });
        return lal;
    }, [assignedLocations, sortAsc]);

    const renderList = () => {
        return (
            <>
                <Spacer size="sm" />
                <Button
                    onClick={() =>
                        locAssignedLocations.forEach((loc) => deleteLocation(loc))
                    }
                    appearance="link"
                >
                    Remove All
                </Button>
                {locAssignedLocations.map((assignedLocation) => {
                    const locID = assignedLocation.location_id;
                    const isHierarchy = assignedLocation.hierarchyChildren;
                    return (
                        <div key={`div-location-row-${locID}`}>
                            <FlexRow
                                className="display-locations-row"
                                key={`location-row-${locID}`}
                            >
                                <FlexCol xs={3}>
                                    {isHierarchy ? (
                                        <Button
                                            onClick={() => {
                                                const newHierarchy = {
                                                    ...collapseHierarchy,
                                                };
                                                if (collapseHierarchy[locID])
                                                    newHierarchy[locID].collapsed =
                                                        !collapseHierarchy[locID]
                                                            .collapsed;
                                                else
                                                    newHierarchy[locID] = {
                                                        collapsed: true,
                                                    };

                                                setCollapseHierarchy(newHierarchy);
                                            }}
                                            iconAfterElement={
                                                collapseHierarchy[locID] &&
                                                collapseHierarchy[locID].collapsed ? (
                                                    <Icon iconName="arrow-down" />
                                                ) : (
                                                    <Icon iconName="arrow-left" />
                                                )
                                            }
                                            appearance="link"
                                        >
                                            {`${getLocationDisplayName(assignedLocation)}  ${
                                                isHierarchy
                                                    ? `(${assignedLocation.hierarchyChildren?.length})`
                                                    : ``
                                            }`}
                                        </Button>
                                    ) : (
                                        <>{getLocationDisplayName(assignedLocation)}</>
                                    )}
                                </FlexCol>
                                <FlexCol xs={2}>
                                    {displayStringForLocationType(
                                        assignedLocation.location_type
                                    )}
                                </FlexCol>
                                <FlexCol xs={2}>{locID}</FlexCol>
                                <FlexCol xs={2}>
                                    {computeLocationStateCode(assignedLocation)}
                                </FlexCol>
                                <FlexCol xs={2}>
                                    {assignedLocation.location_agency_name}
                                </FlexCol>
                                <FlexCol xs={1}>
                                    <Button
                                        onClick={() => deleteLocation(assignedLocation)}
                                        appearance="link"
                                    >
                                        Remove
                                    </Button>
                                </FlexCol>
                            </FlexRow>
                            {isHierarchy &&
                                collapseHierarchy[locID] &&
                                collapseHierarchy[locID].collapsed &&
                                assignedLocation?.hierarchyChildren?.map((leaf) => (
                                    <FlexRow
                                        className="display-locations-row"
                                        key={`leaf-location-row-${leaf.location_id}`}
                                    >
                                        <FlexCol xs={3}>
                                            {getLocationDisplayName(leaf)}
                                        </FlexCol>
                                        <FlexCol xs={2}>
                                            {displayStringForLocationType(
                                                leaf.location_type
                                            )}
                                        </FlexCol>
                                        <FlexCol xs={2}>{leaf.location_id}</FlexCol>
                                        <FlexCol xs={2}>
                                            {computeLocationStateCode(leaf)}
                                        </FlexCol>
                                        <FlexCol xs={2}>
                                            {leaf.location_agency_name}
                                        </FlexCol>
                                    </FlexRow>
                                ))}
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <div className="display-locations-assigned-wrapper">
            <Button
                onClick={() => setSortAsc(!sortAsc)}
                iconAfterElement={
                    sortAsc ? (
                        <Icon iconName="arrow-down" />
                    ) : (
                        <Icon iconName="arrow-up" />
                    )
                }
                appearance="link"
            >
                Assigned Locations
            </Button>
            {locAssignedLocations.length > 0 ? (
                renderList()
            ) : (
                <div className="edit-locations-empty-message">No Locations Selected</div>
            )}
        </div>
    );
}

DisplayRoleLocation.propTypes = {
    assignedLocations: PropTypes.arrayOf(PropTypes.shape({})),
    deleteLocation: PropTypes.func.isRequired,
};
