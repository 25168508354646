/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    body: PropTypes.string,
    searchTerm: PropTypes.string,
    disableCaseSensitivity: PropTypes.bool,
};

export default function TextHighlight({
    body,
    searchTerm,
    disableCaseSensitivity = false,
}) {
    if (!searchTerm || !body || searchTerm.length === 0 || body.length === 0) {
        return body || null;
    }

    const matchTerms = (content, search_term) => {
        const matches = [];
        const size = search_term.length;

        for (let start = 0; start + size < content.length; start += 1) {
            const end = start + size;
            const sub = disableCaseSensitivity
                ? content.toLowerCase().substring(start, end)
                : content.substring(start, end);
            const term = disableCaseSensitivity ? search_term.toLowerCase() : search_term;
            if (sub === term) matches.push({ start, end });
        }

        return matches;
    };

    const nodes = [];
    let end = 0;
    const matches = matchTerms(body, searchTerm);

    matches.forEach((match) => {
        nodes.push(body.substring(end, match.start));
        end = match.end;
        nodes.push(
            <span key={`highlight-span-${match.start}-${end}`} className="find-highlight">
                {body.substring(match.start, end)}
            </span>
        );
    });
    nodes.push(body.substring(end, body.length));

    return nodes;
}

TextHighlight.propTypes = propTypes;
