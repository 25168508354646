/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { FlexCol, FlexRow, Icon, Spacer } from 'sarsaparilla';

const propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    filtersComponent: PropTypes.elementType,
    noDataText: PropTypes.string,
    defaultSortId: PropTypes.string,
    tableCaption: PropTypes.string,
};

const globalFilter = memoize((rows, columnIds, globalFilterValue) => {
    if (globalFilterValue?.length > 0) {
        return rows.filter((row) =>
            columnIds.some((columnId) => {
                return row.values[columnId]
                    .toLowerCase()
                    ?.includes(globalFilterValue.toLowerCase());
            })
        );
    }
    return rows;
});

export default function SearchTable({
    columns,
    data,
    filtersComponent,
    noDataText,
    defaultSortId,
    tableCaption,
}) {
    const getWidthStyle = ({ width, maxWidth, minWidth }) => ({
        style: {
            flex: `${minWidth} 0 auto`,
            width: `${width}px`,
            maxWidth: `${maxWidth}px`,
            minWidth: `${minWidth}px`,
        },
    });
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        state,
    } = useTable(
        {
            columns,
            data,
            globalFilter,
            initialState: defaultSortId
                ? { sortBy: [{ id: defaultSortId, desc: false }] }
                : undefined,
        },
        useGlobalFilter,
        useSortBy
    );

    return (
        <div className="ia-basic-table-wrap">
            <FlexRow
                className="cooperator-list-table-header-wrap"
                justifyContent="space-between"
            >
                <FlexCol sm={12}>
                    {filtersComponent({
                        setGlobalFilter,
                        globalFilter: state.globalFilter,
                    })}
                </FlexCol>
            </FlexRow>
            <Spacer size="sm" />
            <div className="shared-ui-react-table-with-layout rec-ia-table ia-basic-table">
                <div className="rt-table" {...getTableProps()} aria-label={tableCaption}>
                    <div className="rt-thead">
                        {headerGroups.map((headerGroup) => (
                            <div className="rt-tr" {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((header) => (
                                    <div
                                        className="rt-th"
                                        {...header.getHeaderProps(
                                            header.getSortByToggleProps(
                                                getWidthStyle(header)
                                            )
                                        )}
                                    >
                                        {header.render('Header')}
                                        <span className="sort-indicator">
                                            {header.isSorted &&
                                                (header.isSortedDesc ? (
                                                    <Icon iconName="arrow-up" />
                                                ) : (
                                                    <Icon iconName="arrow-down" />
                                                ))}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="rt-tbody" {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <div className="rt-tr-group" key={row.getRowProps().key}>
                                    <div className="rt-tr" {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <div
                                                className="rt-td"
                                                {...cell.getCellProps(
                                                    getWidthStyle(cell.column)
                                                )}
                                            >
                                                {cell.render('Cell')}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                        {rows.length < 1 && (
                            <div className="ia-basic-table-no-data">{noDataText}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

SearchTable.propTypes = propTypes;
