/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    notes: [],
    notesForCreate: [],
    error: null,
};

const notesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_USER_NOTES_SUCCESS:
            return { ...state, notes: action.notes, error: null };
        case types.ADD_NOTE_FAILED:
            return { ...state, error: action.error };
        case types.FETCH_USER_NOTES_FAILED:
            return { ...state, notes: [], error: action.error };
        case types.STORE_NOTE_FOR_CREATE:
            return { ...state, notesForCreate: [action.note, ...state.notesForCreate] };
        case types.ACCOUNT_SAVED:
            return { ...state, notesForCreate: [] };
        case types.CREATING_USER:
        case types.EDITING_USER:
        case types.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};

export default notesReducer;
