/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { useReducer, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as globals from '../constants/globals';
import { makeAuthorizedRequest } from '../actions/login';
import { isFacilityNode } from '../utilities/locations';
import { CAMPGROUND, TICKET_FACILITY, TIMED_ENTRY } from '../utilities/roles';
import useInitLocationPickerLocations from './useInitLocationPickerLocations';
import { addTreeData } from '../utilities/locationPickerTree';
import { ERROR_ROW_OBJECT, NO_RESULTS_OBJECT } from '../constants/locationPicker';

function isMissingReservables(parent) {
    const hasReservables = [CAMPGROUND, TICKET_FACILITY, TIMED_ENTRY].includes(
        parent.location_type
    );
    if (!hasReservables) return false;

    const hasCampsites = parent.campsites && parent.campsites.length > 0;
    const hasTours = parent.tours && parent.campsites.tours > 0;
    return !(hasCampsites || hasTours);
}

async function fetchLocationWithReservables(location, dispatch) {
    const result = { ...location };
    const locType = encodeURIComponent(result.location_type);
    const locID = encodeURIComponent(result.location_id);

    const endpoint = `${globals.API_INVENTORY_URL}/location/${locType}/${locID}`;
    try {
        const locationResult = await makeAuthorizedRequest(endpoint, 'GET', dispatch);

        if (locationResult.location.campsites) {
            result.campsites = locationResult.location.campsites;
        } else if (locationResult.location.tours) {
            result.tours = locationResult.location.tours;
        }
    } catch (e) {
        result.campsites = [{ location_name: 'Error Occurred Loading Data' }];
        result.tours = [{ location_name: 'Error Occurred Loading Data' }];
    }
    return result;
}

async function requestChildLocationsForParent(parent, dispatch) {
    const endpoint = `${globals.API_INVENTORY_URL}/location-children?location_id=${encodeURIComponent(
        parent.location_id
    )}&location_type=${encodeURIComponent(parent.location_type)}`;
    const result = await makeAuthorizedRequest(endpoint, 'GET', dispatch);

    return result.children.map((child) => addTreeData(child, parent));
}

async function doSearch(search, dispatch) {
    let searchTerm;
    if (search.startsWith('id:')) {
        const id = search.replace(' ', '').split('id:')[1];
        searchTerm = `location-id=${id}`;
    } else {
        searchTerm = `location-name=${encodeURIComponent(search)}`;
    }

    const response = await makeAuthorizedRequest(
        `${globals.API_INVENTORY_URL}/location/search?${searchTerm}`,
        'GET',
        dispatch
    );

    return response.locations.map(addTreeData);
}

const initialState = {
    searchValue: '',
    dataSource: [],
    loading: false,
    error: null,
};

const types = {
    SEARCH_START: 'SEARCH_START',
    SEARCH_FAIL: 'SEARCH_FAIL',
    SEARCH_SUCCESS: 'SEARCH_SUCCESS',
    APPEND_CHILDREN: 'APPEND_CHILDREN',
    REPLACE: 'REPLACE',
    RESET: 'RESET',
};

function reducer(state, action) {
    switch (action.type) {
        case types.SEARCH_START:
            return { ...state, loading: true, searchValue: action.searchValue };
        case types.SEARCH_FAIL:
            if (state.searchValue !== action.searchValue) {
                return state;
            }
            return {
                ...state,
                loading: false,
                error: action.error,
                dataSource: [ERROR_ROW_OBJECT],
            };
        case types.SEARCH_SUCCESS: {
            if (state.searchValue !== action.searchValue) {
                if (state.searchValue === '')
                    return { ...state, loading: false, error: null };
                return state;
            }
            const dataS = action.data.length > 0 ? action.data : [NO_RESULTS_OBJECT];
            return { ...state, dataSource: dataS, loading: false, error: null };
        }
        case types.APPEND_CHILDREN: {
            const ds = state.dataSource.filter((d) => d.parentId !== action.parentId); //Filter out pre-existing children
            return { ...state, dataSource: ds.concat(action.data) };
        }
        case types.REPLACE: {
            const dSource = state.dataSource.filter((d) => d.id !== action.data.id);
            return { ...state, dataSource: dSource.concat(action.data) };
        }
        case types.RESET:
            return {
                ...state,
                dataSource: action.dataSource,
                loading: action.loading,
                error: action.error,
            };
        default:
            return state;
    }
}

function startSearch(searchValue) {
    return { type: types.SEARCH_START, searchValue };
}

function searchSuccess(searchValue, data) {
    return { type: types.SEARCH_SUCCESS, searchValue, data };
}

function searchFail(searchValue, error) {
    return { type: types.SEARCH_FAIL, searchValue, error };
}

function append(parentId, data) {
    return { type: types.APPEND_CHILDREN, parentId, data };
}

function replace(data) {
    return { type: types.REPLACE, data };
}

function reset(state) {
    return { type: types.RESET, ...state };
}

export default function useLocationDataSource({ useFullHierarchy = false }) {
    const [{ searchValue, dataSource, loading, error }, localDispatch] = useReducer(
        reducer,
        initialState
    );

    const initDataSource = useInitLocationPickerLocations({ useFullHierarchy });
    const dispatch = useDispatch();

    useEffect(() => {
        if (searchValue === '') {
            localDispatch(reset(initDataSource));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initDataSource?.loading, initDataSource?.error, initDataSource?.loaded]); //Optional chaining only for unit tests

    useEffect(() => {
        if (searchValue === '') {
            localDispatch(reset(initDataSource));
        } else {
            const loadData = async () => {
                try {
                    const data = await doSearch(searchValue, dispatch);
                    localDispatch(searchSuccess(searchValue, data));
                } catch (err) {
                    //Ideally makeAuthorizedRequest would return a status code...
                    if (err.message === 'NO_RESULTS') {
                        localDispatch(searchSuccess(searchValue, []));
                    } else {
                        localDispatch(searchFail(searchValue, err));
                    }
                }
            };
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const fetchChildren = useCallback(
        (location) => {
            const loadData = async () => {
                if (isFacilityNode(location)) {
                    if (isMissingReservables(location)) {
                        const parentWithReservables = await fetchLocationWithReservables(
                            location,
                            dispatch
                        );
                        localDispatch(replace(parentWithReservables));
                    }
                } else {
                    try {
                        const children = await requestChildLocationsForParent(
                            location,
                            dispatch
                        );
                        localDispatch(append(location.id, children));
                    } catch (err) {
                        localDispatch(
                            append(location.id, [addTreeData(ERROR_ROW_OBJECT, location)])
                        );
                    }
                }
            };
            loadData();
        },
        [dispatch, localDispatch]
    );

    const setSearchValue = useCallback((value) => {
        localDispatch(startSearch(value));
    }, []);

    return {
        dataSource,
        searchValue,
        setSearchValue,
        fetchChildren,
        loading,
        error,
    };
}
