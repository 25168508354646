/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import {
    Icon,
    HelmetWrapperInternal,
    ContentContainer,
    Heading,
    Box,
    Divider,
    useWindowSize,
    useStickySidebar,
} from 'sarsaparilla';

import SiteWrapper from './SiteWrapper';
import InternalContactUsNotification from '../components/contactUs/InternalContactUsNotification';
import ContactUsTopInfo from '../components/contactUs/ContactUsTopInfo';
import ContactUsForm from '../components/contactUs/ContactUsForm';
import ContactUsFAQ from '../components/contactUs/ContactUsFAQ';

export default function ContactUs() {
    const title = window.location.hash
        ? decodeURI(window.location.hash.substring(1))
        : 'Contact Us';

    const { width } = useWindowSize();
    const { containerRef, stuckStatus, containerWidth } = useStickySidebar(
        0,
        width > 768
    );
    // ^^ Stick to very top of page; only listen for scroll changes
    //    if window is larger than 768px

    const style = {
        width: `${containerWidth}px`,
    };

    if (stuckStatus === 'top') {
        style.position = 'fixed';
        style.top = '16px';
    }

    if (stuckStatus === 'bottom') {
        style.position = 'absolute';
        style.bottom = '0';
    }

    return (
        <SiteWrapper>
            <HelmetWrapperInternal title={title} />
            <ContentContainer className="ia-contact-wrap my-5">
                <Heading headingLevel={1} appearance="h3" className="mb-2">
                    {title}
                </Heading>
                <Box alignX="left">
                    <Box style={{ flexGrow: '999', flexBasis: '0' }} role="main">
                        <InternalContactUsNotification />
                        <ContactUsTopInfo />
                        <Divider />
                        <ContactUsForm />
                    </Box>

                    {/* Right Rail */}
                    <Box
                        innerRef={containerRef}
                        className="ia-contact-right-faq-wrap ml-3"
                        style={{
                            width: '30%',
                            position: 'relative',
                        }}
                    >
                        <Box
                            role="complementary"
                            className="ia-contact-right-faq"
                            style={style}
                        >
                            <ContactUsFAQ />
                        </Box>
                    </Box>
                </Box>
            </ContentContainer>

            <button
                onClick={() =>
                    window.open(
                        process.env.SN_INTERNAL_CHAT_URL,
                        'Chat Support',
                        'width=300,height=600'
                    )
                }
                type="button"
                className="nav-contact-chat-btn"
            >
                <Icon iconName="chat" />
                Chat with us
            </button>
        </SiteWrapper>
    );
}
