/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Icon } from 'sarsaparilla';

export default function CooperatorDetailsTableActions({
    selectedFlatRows,
    onDeleteClick,
    onEditClick,
}) {
    const selectedRows = selectedFlatRows.map(({ original }) => original);

    const allowDelete = selectedRows.length > 0;
    const allowEdit = selectedRows.length === 1;

    return (
        <ButtonGroup isStretchedToFit>
            <Button
                id="deleteCooperatorsButton"
                iconBeforeElement={<Icon iconName="delete" />}
                isDisabled={!allowDelete}
                onClick={() => onDeleteClick(selectedRows)}
                appearance="tertiary-danger"
            >
                Delete
            </Button>
            <Button
                id="editCooperatorsButton"
                iconBeforeElement={<Icon iconName="edit" />}
                onClick={() => onEditClick(selectedRows[0])}
                isDisabled={!allowEdit}
                appearance="tertiary"
            >
                Edit
            </Button>
        </ButtonGroup>
    );
}

CooperatorDetailsTableActions.propTypes = {
    onDeleteClick: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    selectedFlatRows: PropTypes.arrayOf(
        PropTypes.shape({
            original: PropTypes.object,
        })
    ).isRequired,
};
