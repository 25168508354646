/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import axios from 'axios';
import { InternalSecurityUtils } from '../../index';
import { INT_FEEDBACK_SUCCESS, INT_FEEDBACK_FAILED } from '../constants/types';

const feedbackSucceeded = () => ({
    type: INT_FEEDBACK_SUCCESS,
});

const feedbackFailed = () => ({
    type: INT_FEEDBACK_FAILED,
});

export const sendFeedback = (form) => {
    return async (dispatch) => {
        try {
            const url = `${process.env.API}/accounts/internal/feedback`;
            const headers = { Authorization: InternalSecurityUtils.getAuthHeader() };
            const resp = await axios.post(url, form, { headers });
            if (resp.status === 200) {
                dispatch(feedbackSucceeded());
            }
        } catch (error) {
            dispatch(feedbackFailed());
        }
    };
};
