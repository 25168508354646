/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default class RadioWithLegendObj extends React.PureComponent {
    static propTypes = {
        /** The id given to the input and label's htmlFor */
        id: PropTypes.string.isRequired,

        /** The content of the label (usually just text) */
        label: PropTypes.node.isRequired,

        /** The content of addional text added below the label (only for non-inline radio inputs) */
        legend: PropTypes.node,

        /** Internal value of the input */
        value: PropTypes.string.isRequired,

        /** The input name. All radios in the group should have the same name. */
        name: PropTypes.string,

        /** If the checkbox is checked */
        isChecked: PropTypes.bool,

        /** If the radio is disabled */
        isDisabled: PropTypes.bool,

        /** Makes the radio inputs arranged inline horizontally */
        isInline: PropTypes.bool,

        /** If the radio is required */
        isRequired: PropTypes.bool,

        /** The ref to be passed to the input */
        innerRef: PropTypes.node,

        /** Additional className applied to the wrapping element */
        className: PropTypes.string,
    };

    static defaultProps = {
        name: '',
        isDisabled: false,
        innerRef: undefined,
        className: undefined,
        legend: '',
        isChecked: null,
        isInline: false,
    };

    render() {
        const {
            id,
            label,
            legend,
            value,
            isDisabled,
            isChecked,
            isRequired,
            innerRef,
            className,
            name,
            isInline,
            ...otherProps
        } = this.props;

        return (
            <label
                htmlFor={id}
                className={cx('rec-label-radio', {
                    disabled: isDisabled,
                    'rec-radio-inline': isInline,
                    [className]: className,
                })}
            >
                <input
                    type="radio"
                    id={id}
                    value={value}
                    disabled={isDisabled}
                    required={isRequired}
                    ref={innerRef}
                    className={`rec-input-hide ${isInline}`}
                    checked={isChecked}
                    name={name}
                    {...otherProps}
                />
                <span className="rec-input-radio" />
                {!isInline && legend && legend !== '' ? (
                    <>
                        <b>{label}</b>
                        <div className="rec-radio-legend">{legend}</div>
                    </>
                ) : (
                    { label }
                )}
            </label>
        );
    }
}
