/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

export const API_URL: string = `${process.env.API}/internal/account`;
export const API_URL_SUFFIX: string = `/internal/account`;
export const API_INVENTORY_URL: string = `${process.env.API}/inventory`;
export const API_INVENTORY_SUFFIX: string = `/inventory`;
export const API_PERMITS_URL: string = `${process.env.API}/permits`;
export const JEST_DOMAIN: string = `${process.env.API}`;
export const TOKEN_LOCAL_STORAGE_KEY: string = 'internalaccount';
export const OIDC_STATE_STORAGE_KEY: string = 'oidc_state';
export const CONFIRM_EMAIL_CHANGE_TOKEN: string = 'confirm_email_change_token';
export const TOKEN_COOKIE_NAME: string = 'ia_token';
export const LOGIN_PATH: string = '/internal/account/login';
export const REPORT_DOWNLOAD_PATH: string = '/internal/reporting/scheduled/download';
export const EMPTY_TIME_STRING: string = '0001-01-01T00:00:00Z';
export const EXCLUDE_LOGIN_PATHS: string[] = [
    '/internal/account/recover-password',
    '/internal/account/reset-password',
    '/internal/account/confirm-email',
    '/internal/account/change-password',
    '/internal/account/oidc',
];
export const DASHBOARD_PATH: string = '/internal/account/hub';

export const INFO_ALERT_LEVEL: string = 'INFO';
export const WARNING_ALERT_LEVEL: string = 'WARNING';
export const SUCCESS_ALERT_LEVEL: string = 'SUCCESS';
export const ERROR_ALERT_LEVEL: string = 'ERROR';

export const THROTTLE_AT_COUNT: number = 100;

export const PERSIST_KEY: string = 'internalaccount';

export const POC_PRIMARY: string = 'POC_PRIMARY';
export const POC_SECONDARY: string = 'POC_SECONDARY';
export const POC_ADDITIONAL: string = 'POC_ADDITIONAL';

export const LOCATION_NAME_FIELD_TYPE: string = 'location-name';
export const FIRST_NAME_FIELD_TYPE: string = 'first-name';
export const LAST_NAME_FIELD_TYPE: string = 'last-name';
export const EMAIL_FIELD_TYPE: string = 'email';
