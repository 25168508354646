/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { CompatRoute } from 'react-router-dom-v5-compat';
import { NotFoundPage, RequireAuthInternal } from 'sarsaparilla';

import LaunchPad from '../src/containers/LaunchPad';
import SiteMap from '../src/containers/SiteMap';
import LoginPage from '../src/containers/LoginPage';
import MfaLoginPage from '../src/containers/MfaLoginPage';
import RecoverPasswordPage from '../src/containers/RecoverPasswordPage';
import AttestationManagement from '../src/containers/AttestationManagement';
import UserListBox from '../src/containers/UserListBox';
import UpdateUserPage from '../src/containers/UpdateUserPage';
import ResetPasswordPage from '../src/containers/ResetPasswordPage';
import ConfirmEmailPage from '../src/containers/ConfirmEmailPage';
import ConfirmEmailChangePage from '../src/containers/ConfirmEmailChangePage';
import ChangePassword from '../src/containers/ChangePassword';
import EditLocations from '../src/containers/EditLocations';
import EditRoles from '../src/containers/EditRoles';
import MachineApiPermissionsPage from '../src/containers/MachineApiPermissionsPage';
import MachineApiKeysPage from '../src/containers/MachineApiKeysPage';
import RangerTokenManagementPage from '../src/containers/RangerTokenManagementPage';
import MyProfilePage from '../src/containers/MyProfilePage';
import ManageConcessionairesContainer from '../src/containers/ManageConcessionairesContainer';
import EditConcessionaires from '../src/containers/EditConcessionaires';
import EditConcessionaireContainer from '../src/containers/EditConcessionaireContainer';
import ConcessionaireDetailsContainer from '../src/containers/ConcessionaireDetailsContainer';
import EditPermitContainer from '../src/containers/EditPermitContainer';
import ManageCooperatorsContainer from '../src/containers/ManageCooperatorsContainer';
import CooperatorDetailsContainer from '../src/containers/CooperatorDetailsContainer';
import EditCooperatorContainer from '../src/containers/EditCooperatorContainer';
import EditEmployeeContainer from '../src/containers/EditEmployeeContainer';
import FacilityInfoPage from '../src/containers/FacilityInfoPage';
import ContactUs from '../src/containers/ContactUs';
import TrainingLinks from '../src/containers/TrainingLinks';
import UnsupportedBrowserRedirect from '../src/containers/UnsupportedBrowserRedirect';
import OidcCallback from '../src/components/login/OidcCallback';

import routesArray from './routesArray';

const Components = {
    LoginPage,
    MfaLoginPage,
    LaunchPad,
    SiteMap,
    RecoverPasswordPage,
    ResetPasswordPage,
    ConfirmEmailPage,
    ConfirmEmailChangePage,
    ChangePassword,
    MachineApiPermissionsPage,
    MachineApiKeysPage,
    RangerTokenManagementPage,
    MyProfilePage,
    AttestationManagement,
    UserListBox,
    UpdateUserPage,
    EditLocations,
    EditRoles,
    EditConcessionaires,
    ManageConcessionairesContainer,
    EditConcessionaireContainer,
    ConcessionaireDetailsContainer,
    EditPermitContainer,
    ManageCooperatorsContainer,
    CooperatorDetailsContainer,
    EditCooperatorContainer,
    EditEmployeeContainer,
    FacilityInfoPage,
    ContactUs,
    TrainingLinks,
    UnsupportedBrowserRedirect,
    OidcCallback,
};

export default function makeSwitchRoutes() {
    const routeComponents = routesArray.map((route) => {
        const Component = Components[route.componentName];

        if (route.isPublic) {
            return (
                <CompatRoute
                    key={route.path}
                    path={route.path}
                    component={Component}
                    exact
                />
            );
        }

        return (
            <CompatRoute
                key={route.path}
                path={route.path}
                exact={route.exact || false}
                render={(props) => (
                    <RequireAuthInternal>
                        <Component {...props} />
                    </RequireAuthInternal>
                )}
            />
        );
    });

    routeComponents.push(
        <CompatRoute path="*" exact key="notFound" component={NotFoundPage} />
    );

    return routeComponents;
}
