/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { keepaliveThrottled } from '../actions/keepalive';

const propTypes = {
    last_active_at: PropTypes.any,
    keepalive: PropTypes.func.isRequired,
};

function ActivityPing(props) {
    useEffect(() => {
        async function keepalive() {
            await props.keepalive();
        }
        keepalive().then();
    }, [props, props.last_active_at]);

    return null;
}

ActivityPing.propTypes = propTypes;

const mapStateToProps = (state) => {
    return {
        last_active_at: state.idle?.lastActive,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        keepalive: () => dispatch(keepaliveThrottled()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPing);
