/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { SET_TOAST_NOTIFICATION, DISMISS_TOAST_NOTIFICATION } from '../constants/types';

export const INITIAL_STATE = {
    toasts: [],
};

export default function toastReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_TOAST_NOTIFICATION:
            return {
                ...state,
                toasts: [...state.toasts, action.payload],
            };

        case DISMISS_TOAST_NOTIFICATION:
            return {
                ...state,
                toasts: state.toasts.filter((toast) => toast.id !== action.payload),
            };

        default:
            return state;
    }
}
