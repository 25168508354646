/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';
import * as errors from '../constants/errors';
import { doneLoading, startLoading } from './loading';
import {
    resetIdleMonitor,
    loggedIn,
    loginSuccessful,
    makeAuthorizedRequest,
    MFAFactors,
} from './login';
import { setAuthStorage } from '../utilities/internalSecurityUtil';

export const discoverFactor = (code) => {
    if (code?.length === 6) {
        return MFAFactors.TOTP;
    }

    return code?.length > 6 ? MFAFactors.BackupCodes : MFAFactors.Any;
};

const generateMfaTotpFailed = (error) => {
    return {
        type: types.GENERATE_MFA_TOTP_FAILED,
        generateMfaTotpError: true,
        error,
    };
};

const postMfaTotp = async (dispatch) => {
    try {
        const url = `${process.env.API}/internal/account/mfa/totp`;
        const response = await makeAuthorizedRequest(url, 'POST', dispatch);
        return response;
    } catch (error) {
        dispatch(generateMfaTotpFailed(errors.GENERATE_MFA_TOTP_FAILED));
        return false;
    }
};

export const generateMfaTotp = () => {
    return async (dispatch) => {
        dispatch(startLoading());
        dispatch({
            type: types.GENERATE_MFA_TOTP_CLEAR,
        });
        const response = await postMfaTotp(dispatch);
        if (response) {
            const { manual_key, qr_code } = response;
            dispatch({
                manualKey: manual_key,
                qrCode: qr_code,
                type: types.GENERATE_MFA_TOTP_SUCCESSFUL,
            });
        }
        dispatch(doneLoading());
    };
};

export const clearGenerateMfaTotp = () => {
    return async (dispatch) => {
        dispatch({
            type: types.GENERATE_MFA_TOTP_CLEAR,
        });
    };
};

const patchMfaOptOutFailed = (error) => {
    return {
        type: types.PATCH_MFA_OPT_OUT_FAILED,
        patchMfaOptOutError: true,
        error,
    };
};

const patchMfaOptOut = async (dispatch) => {
    try {
        const url = `${process.env.API}/internal/account/mfa/optout`;
        const response = await makeAuthorizedRequest(url, 'PATCH', dispatch);
        return response;
    } catch (error) {
        dispatch(patchMfaOptOutFailed(errors.PATCH_MFA_OPT_OUT_FAILED));
        return false;
    }
};

export const assignMfaOptOut = (nextPathname, history) => {
    return async (dispatch) => {
        dispatch(startLoading());
        const response = await patchMfaOptOut(dispatch);
        if (response) {
            dispatch({
                type: types.PATCH_MFA_OPT_OUT_SUCCESSFUL,
            });

            // User is now logged in
            resetIdleMonitor();
            dispatch(loggedIn(response, nextPathname, history));
        }
        dispatch(doneLoading());
    };
};

const validateMfaFactorFailed = (error) => {
    return {
        type: types.VALIDATE_MFA_FACTOR_FAILED,
        validateMfaFactorError: true,
        error,
    };
};

const postMfaValidate = async (dispatch, token, factor) => {
    try {
        const url = `${process.env.API}/internal/account/mfa/validate`;
        const payload = {
            token,
            factor,
        };
        const response = await makeAuthorizedRequest(url, 'POST', dispatch, payload);
        return response;
    } catch (error) {
        dispatch(validateMfaFactorFailed(errors.VALIDATE_MFA_FACTOR_FAILED));
        return false;
    }
};

export const validateMfaFactor = (token, factor) => {
    return async (dispatch) => {
        dispatch(startLoading());
        const response = await postMfaValidate(dispatch, token, factor);
        if (response) {
            dispatch({
                type: types.VALIDATE_MFA_FACTOR_SUCCESSFUL,
            });

            // User is now logged in but do not redirect back to the hub
            resetIdleMonitor();
            setAuthStorage(response);
            dispatch(loginSuccessful(response));
        }
        dispatch(doneLoading());
    };
};

const createMfaBackupCodesFailed = (error) => {
    return {
        type: types.CREATE_MFA_BACKUP_CODES_FAILED,
        createMfaBackupCodesError: true,
        error,
    };
};

export const postMfaBackupCodes = async (dispatch, userId) => {
    try {
        const url = `${process.env.API}/internal/account/mfa/backup/${userId}`;
        const response = await makeAuthorizedRequest(url, 'POST', dispatch);
        return response;
    } catch (error) {
        dispatch(createMfaBackupCodesFailed(errors.CREATE_MFA_BACKUP_CODES_FAILED));
        return false;
    }
};

export const createMfaBackupCodes = () => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        const state = getState();
        const response = await postMfaBackupCodes(dispatch, state.login?.user?.user_id);
        if (response) {
            const { codes } = response;
            if (codes && codes.length > 0) {
                dispatch({
                    type: types.CREATE_MFA_BACKUP_CODES_SUCCESSFUL,
                    backupCodes: codes,
                });
            } else {
                dispatch(
                    createMfaBackupCodesFailed(errors.CREATE_MFA_BACKUP_CODES_FAILED)
                );
            }
        }
        dispatch(doneLoading());
    };
};
