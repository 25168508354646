/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { ReactElement, AriaAttributes } from 'react';
import cx from 'classnames';
import { Icon, ScreenReaderTip } from 'sarsaparilla';

export default function SortableReactTableColumnHeader({
    children,
    className,
    isSorted,
    isAsc,
    ...rest
}: {
    children: ReactElement;
    className: string;
    isSorted: string;
    isAsc: boolean;
}) {
    let sortArrows = null;

    if (isSorted) {
        if (isAsc) {
            sortArrows = (
                <div className="sort">
                    <div className="disabled">
                        <Icon iconName="arrow-up" />
                    </div>
                    <div className="sorted">
                        <Icon iconName="arrow-down" />
                    </div>
                </div>
            );
        } else {
            sortArrows = (
                <div className="sort">
                    <div className="sorted">
                        <Icon iconName="arrow-up" />
                    </div>
                    <div className="disabled">
                        <Icon iconName="arrow-down" />
                    </div>
                </div>
            );
        }
    } else {
        sortArrows = (
            <div className="sort">
                <div className="enabled">
                    <Icon iconName="arrow-up" />
                </div>
                <div className="enabled">
                    <Icon iconName="arrow-down" />
                </div>
            </div>
        );
    }

    let sort: AriaAttributes['aria-sort'] = 'none';
    if (isSorted) {
        sort = isAsc ? 'ascending' : 'descending';
    }

    return (
        <div
            className={cx('rt-th', 'rt-sortable-column-header', className, {
                '-sort-desc': isSorted && !isAsc,
                '-sort-asc': isSorted && isAsc,
            })}
            aria-sort={sort}
            role="columnheader"
            {...rest}
        >
            <ScreenReaderTip content="Click to sort the table by this column">
                <button type="button">
                    <span>{children}</span>
                    {sortArrows}
                </button>
            </ScreenReaderTip>
        </div>
    );
}
