/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import './login.scss';

import * as React from 'react';

import {
    Button,
    Text,
    Heading,
    Alert,
    TextField,
    FormValidationAlert,
} from 'sarsaparilla';

import { errorForLogin } from '../../utilities/errorMessages';
import { useAppDispatch } from '../../utilities/redux-helpers';
import { logOut } from '../../actions/login';
import {
    VerificationCodeField,
    type ValidationState,
    type VerificationCodeFieldRef,
} from '../mfaEnrollment/VerificationCodeField';

interface MfaValidateFormProps {
    submitFn: (code: string) => void;
    error: string;
    showStartOver?: boolean;
    submitText?: string;
}

export function MfaValidateForm({
    submitFn,
    error,
    showStartOver,
    submitText,
}: MfaValidateFormProps) {
    const dispatch = useAppDispatch();
    const inputRef = React.useRef<VerificationCodeFieldRef>(null);
    const backupCodeInputRef = React.useRef<TextField>(null);
    const labelRef = React.useRef<HTMLDivElement>(null);
    const [isCodeView, setCodeView] = React.useState(true);
    const [inputValidation, setInputValidation] = React.useState<
        ValidationState | null | undefined
    >(null);

    const logoutHandler = () => {
        dispatch(logOut());
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const code = formData.get('code') as string;

        const validation = isCodeView
            ? inputRef.current?.validate()
            : (backupCodeInputRef.current?.validate() as ValidationState);

        setInputValidation(validation);

        if (code && !validation?.isInvalid) {
            submitFn(code);
        }
    };

    return (
        <div className="ia-enter-mfa">
            <Heading
                headingLevel={1}
                appearance="h3"
                headingAlign="center"
                className="mb-3"
            >
                Sign In
            </Heading>

            {error ? (
                <Alert shouldFocusOnMount type="error" className="my-3">
                    {errorForLogin(error, 0)}
                </Alert>
            ) : inputValidation?.isInvalid ? (
                // @ts-ignore - type will be fixed in next sarsa release
                <FormValidationAlert errors={[inputValidation]} className="my-3" />
            ) : null}

            <div ref={labelRef} tabIndex={-1} style={{ outline: 'none' }}>
                <Heading headingLevel={2} headingAlign="center" appearance="h6">
                    {isCodeView
                        ? '6-digit Code from Authenticator App'
                        : 'One-time Backup Code'}
                </Heading>
                {isCodeView ? (
                    <div className="rec-sr-only">. Enter the code in the input below</div>
                ) : (
                    <Text
                        size="sm"
                        tag="div"
                        color="gray"
                        align="center"
                        className="mt-half"
                    >
                        The back up code you use will expire and can not be used again
                    </Text>
                )}
            </div>

            <form className="center-vertical" onSubmit={onSubmit} noValidate>
                {isCodeView ? (
                    <VerificationCodeField
                        ref={inputRef}
                        label="Verification Code"
                        className="my-3"
                        name="code"
                        autoFocus
                    />
                ) : (
                    <TextField
                        ref={backupCodeInputRef}
                        id="backup-code"
                        label="One-Time Backup Code"
                        isRequired
                        isLabelVisible={false}
                        className="my-3 big-text-field"
                        name="code"
                        shouldFocusOnMount
                    />
                )}

                <Button type="submit" id="login" gaTrackingId="237605532484">
                    {submitText ?? 'Log In'}
                </Button>
                <Button
                    type="button"
                    appearance="link"
                    className="mt-2"
                    gaTrackingId="237605532484"
                    onClick={() => {
                        setInputValidation(null);
                        setCodeView((prev) => !prev);
                        labelRef.current?.focus();
                    }}
                >
                    {isCodeView
                        ? 'Use One-Time Backup Code'
                        : 'Use 6-digit Code from Authenticator App'}
                </Button>
            </form>

            <Heading
                headingLevel={2}
                appearance="h6"
                className="mt-5 mb-half"
                headingAlign="center"
            >
                Having trouble logging in?
            </Heading>
            <Text tag="div" align="center">
                Reach out to your manager or contact the Help Desk&apos;s Internal Help
                Line.
                {showStartOver && (
                    <div className="mt-half">
                        <Button
                            appearance="link"
                            id="logout"
                            to="/internal/account/login"
                            onClick={logoutHandler}
                            gaTrackingId="237605532484"
                        >
                            Start over
                        </Button>
                    </div>
                )}
            </Text>
        </div>
    );
}
