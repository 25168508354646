/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { INT_FEEDBACK_SUCCESS, INT_FEEDBACK_FAILED } from '../constants/types';

const initialState = {
    sendSuccess: false,
    sendErr: false,
};

const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case INT_FEEDBACK_SUCCESS:
            return { ...state, sendErr: false, sendSuccess: true };

        case INT_FEEDBACK_FAILED:
            return { ...state, ssndErr: true, sendSuccess: false };

        default:
            return state;
    }
};

export default contactReducer;
