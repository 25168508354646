/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'sarsaparilla';

const propTypes = {
    error: PropTypes.any,
    errorStringMapping: PropTypes.func,
};

export default class ActionErrorDisplay extends React.Component {
    static propTypes = propTypes;

    validationErrorUI(errorMessage, index) {
        const key = `update-user-validation-error-key-${index}`;

        return <li key={key}>{errorMessage}</li>;
    }

    errorUI() {
        if (this.props.error) {
            if (this.props.error.validationErrors) {
                return (
                    <Alert shouldFocusOnMount type="error">
                        <ul className="rec-notification-validation-errors">
                            {this.props.error.validationErrors.map(
                                this.validationErrorUI
                            )}
                        </ul>
                    </Alert>
                );
            }
            if (this.props.errorStringMapping) {
                return (
                    <Alert shouldFocusOnMount type="error">
                        {this.props.errorStringMapping(this.props.error)}
                    </Alert>
                );
            }
            if (typeof this.props.error === 'string') {
                return (
                    <Alert shouldFocusOnMount type="error">
                        {this.props.error}
                    </Alert>
                );
            }

            return (
                <Alert shouldFocusOnMount type="error">
                    Error encountered in code execution.
                </Alert>
            );
        }

        return null;
    }

    render() {
        if (this.props.error) {
            return this.errorUI();
        }

        return null;
    }
}
