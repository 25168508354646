/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import {
    FlexCol,
    FlexRow,
    FormattedDateTime,
    StyledModal,
    Text,
    Box,
} from '@fp/sarsaparilla';

import { displayLevelAndRoleString } from '../../utilities/roles';

const propTypes = {
    token: PropTypes.object,
    closeModal: PropTypes.func,
};

export default function ViewTokenDetailsModal({ token, closeModal }) {
    const locations = Object.keys(token.roles).map((locationID) => ({
        ...token.roles[locationID],
        id: locationID,
    }));

    return (
        <StyledModal
            size="lg"
            isOpen={true}
            heading="Token Information"
            id="RangerTokenModal"
            onRequestClose={closeModal}
        >
            <FlexRow className="mx-0 ">
                <FlexCol md={6} className="mb-3">
                    <Text fontWeight="semibold" size="lg">
                        Token Id
                    </Text>
                    <Text>Token {token.token_id}</Text>
                </FlexCol>
                <FlexCol md={6} className="mb-3">
                    <Text fontWeight="semibold" size="lg">
                        Created by
                    </Text>
                    <Text>{token.created_by_name}</Text>
                </FlexCol>
            </FlexRow>
            <FlexRow className="mx-0">
                <FlexCol md={6} className="mb-3">
                    <Text fontWeight="semibold" size="lg">
                        Expiration Date
                    </Text>
                    <Text>
                        <FormattedDateTime
                            date={new Date(token.expire_at)}
                            format={'L'}
                        />
                    </Text>
                </FlexCol>
                <FlexCol className="mb-3">
                    <Text fontWeight="semibold" size="lg">
                        Device Id
                    </Text>
                    <Text>{token.device_id}</Text>
                </FlexCol>

                <FlexCol md={6} className="mb-3">
                    <Text fontWeight="semibold" size="lg">
                        Device Nickname
                    </Text>
                    <Text>{token.device_nickname}</Text>
                </FlexCol>
            </FlexRow>

            <Box background="gray-2" padding="md">
                <FlexRow>
                    <FlexCol md={6} className="mb-3 mb-lg-0">
                        <Text fontWeight="semibold" size="lg" className="">
                            Role
                        </Text>

                        {locations.map((location) => (
                            <Text className="my-1" key={location.id}>
                                {displayLevelAndRoleString(location.role_type)}
                            </Text>
                        ))}
                    </FlexCol>
                    <FlexCol md={6} className="">
                        <Text className="" fontWeight="semibold" size="lg">
                            Facility
                        </Text>

                        {locations.map((location) => (
                            <Text className="my-1" key={location.id}>
                                {location.location_name}
                            </Text>
                        ))}
                    </FlexCol>
                </FlexRow>
            </Box>
        </StyledModal>
    );
}
ViewTokenDetailsModal.propTypes = propTypes;
