/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import {
    EditableWrapper,
    DisplayTemplate,
    EditTemplate,
    Icon,
    DisplayHtmlContent,
    Button,
} from 'sarsaparilla';
import { RichTextField } from 'sarsaparilla/RichTextField';

import {
    get,
    set,
    clone,
    cloneDeep,
    isEqual,
    map,
    size,
    includes,
    delay,
    isEmpty,
} from 'lodash';

export default class EditableNotes extends React.Component {
    static propTypes = {
        onNotesChange: PropTypes.func.isRequired,
        onNotesCancelClick: PropTypes.func.isRequired,
        notes: PropTypes.arrayOf(PropTypes.object),
        noteType: PropTypes.string,
        idSuffix: PropTypes.string,
        id: PropTypes.string,
        label: PropTypes.string,
    };

    static defaultProps = {
        notes: [],
        noteType: 'warning',
    };

    constructor(props) {
        super(props);

        this.state = {
            notes: props.notes,
            original_notes: props.notes,
            itemListDisplayMode: 'display',
            highlighted: [],
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let stateUpdate = {};
        if (!isEqual(nextProps.notes, prevState.original_notes)) {
            stateUpdate = {
                notes: nextProps.notes,
                original_notes: nextProps.notes,
            };
        }
        if (!isEmpty(stateUpdate)) {
            return stateUpdate;
        }
        return null;
    }

    getDisplayModeFromState = (path) => {
        return get(this.state, path, 'display');
    };

    toggleForm = (path) => {
        // Anytime using old state's value the following pattern should be used.
        this.setState((prevState) => {
            const newState = { ...prevState };
            const oldValue = get(prevState, path);
            const newValue = oldValue === 'edit' ? 'display' : 'edit';
            set(newState, path, newValue);
            return newState;
        });
    };

    itemListEditClick = () => {
        this.toggleForm('itemListDisplayMode');
    };

    itemListConfirmClick = () => {
        this.props.onNotesChange(this.state.notes);
        this.toggleForm('itemListDisplayMode');
    };

    itemListCancelClick = () => {
        this.props.onNotesCancelClick();
        this.setState((prevState) => {
            return { ...prevState, notes: [...prevState.original_notes] };
        });
        this.toggleForm('itemListDisplayMode');
    };

    handleNoteChange = (value, id) => {
        const index = parseInt(id.slice(15), 10);
        const { notes } = this.state;
        notes[index].active = true; // make sure it's always active for now
        notes[index].note_text = value;
        if (!notes[index].is_added) {
            notes[index].is_updated = true;
        }
        this.setState({ notes });
    };

    addNote = () => {
        const currNotes = get(this.state, 'notes', []);
        let newSeq = 1;
        if (currNotes.length > 0) {
            const lastItem = currNotes[currNotes.length - 1];
            if (lastItem) {
                newSeq = lastItem.seq_num + 1;
            }
        }
        const notes = clone(this.state.notes);
        notes.push({
            active: true,
            note_text: '',
            note_type: this.props.noteType,
            is_added: true,
            seq_num: newSeq,
        });
        this.setState((prevState) => {
            return { ...prevState, notes };
        });
    };

    deleteNote = (index) => {
        if (index < 0) {
            return;
        }
        const notes = cloneDeep(this.state.notes);
        const noteToDelete = notes[index];
        if (!noteToDelete.is_added) {
            noteToDelete.is_deleted = true;
        } else {
            notes.splice(index, 1);
        }
        this.setState((prevState) => {
            return { ...prevState, notes };
        });
    };

    moveNote = (index, direction) => {
        const notes = cloneDeep(this.state.notes);
        const currentNote = notes[index];
        const replacementIndex = index + direction;
        if (replacementIndex >= 0 && replacementIndex < size(this.state.notes)) {
            const replacementNote = notes[replacementIndex];
            currentNote.note_order = replacementIndex;
            replacementNote.note_order = index;

            currentNote.is_updated = true;
            replacementNote.is_updated = true;

            const currentSeqNum = currentNote.seq_num;
            currentNote.seq_num = replacementNote.seq_num;
            replacementNote.seq_num = currentSeqNum;

            notes[index] = replacementNote;
            notes[replacementIndex] = currentNote;
        }

        this.setState({ highlighted: [] }, () => {
            delay(() => {
                this.setState({ notes, highlighted: [index, replacementIndex] });
            }, 300);
        });
    };

    renderDisplayNotes = () => {
        const { notes } = this.state;
        return map(notes, (note, index) => {
            const key = `facility-notes-${note.note_type}-${index}`;
            if (!note.is_deleted) {
                return (
                    <li key={key}>
                        <DisplayHtmlContent html={note.note_text} />
                    </li>
                );
            }
            return null;
        });
    };

    renderEditNotes = () => {
        const { notes } = this.state;

        return map(notes, (note, index) => {
            const key = `facility-notes-${note.note_type}-${index}`;

            let highlightClass = '';

            if (includes(this.state.highlighted, index)) {
                highlightClass = 'ia-note-item-highlight';
            }
            if (!note.is_deleted) {
                return (
                    <div className="ia-form-check-wrap" key={key}>
                        <div className="ia-note-input">
                            <span className="ia-note-order">
                                <Button
                                    appearance="tertiary"
                                    className="ia-note-order-button"
                                    onClick={() => this.moveNote(index, -1)}
                                    iconBeforeElement={<Icon iconName="chevron-up" />}
                                    isDisabled={index === 0}
                                    screenReaderTextBefore="Move position up"
                                />
                                <Button
                                    appearance="tertiary"
                                    className="ia-note-order-button"
                                    onClick={() => this.moveNote(index, 1)}
                                    iconBeforeElement={<Icon iconName="chevron-down" />}
                                    isDisabled={index === size(notes) - 1}
                                    screenReaderTextBefore="Move position down"
                                />
                            </span>
                            <RichTextField
                                className={`rich-text-field ${highlightClass}`}
                                label={`Item ${index + 1}`}
                                isLabelVisible={false}
                                id={`rich-text-item-${index}-${this.props.idSuffix}`}
                                html={note.note_text}
                                onChange={this.handleNoteChange}
                                placeholder="Add your content here ..."
                            />
                            <Button
                                className="ia-close-icon dlt-note-btn"
                                appearance="subtle-danger"
                                iconBeforeElement={<Icon iconName="delete" />}
                                screenReaderTextBefore="Delete item"
                                onClick={() => this.deleteNote(index)}
                            />
                            <br />
                        </div>
                    </div>
                );
            }
            return null;
        });
    };

    renderAddNoteButton = () => {
        const disabled = size(this.state.notes) >= 15;
        return (
            <Button
                appearance="tertiary"
                size="sm"
                onClick={this.addNote}
                iconBeforeElement={<Icon iconName="add-circle" />}
                isDisabled={disabled}
            >
                Add More
            </Button>
        );
    };

    render() {
        const itemListDisplayMode = this.getDisplayModeFromState('itemListDisplayMode');

        return (
            <div>
                <div className="ia-edit-multi-section-wrap">
                    <EditableWrapper
                        mode={itemListDisplayMode}
                        label={this.props.label}
                        id={this.props.id}
                    >
                        <DisplayTemplate onEditClick={this.itemListEditClick}>
                            <ul className="single-column">{this.renderDisplayNotes()}</ul>
                        </DisplayTemplate>
                        <EditTemplate
                            onConfirmClick={this.itemListConfirmClick}
                            onCancelClick={this.itemListCancelClick}
                        >
                            {this.renderEditNotes()}
                            {this.renderAddNoteButton()}
                        </EditTemplate>
                    </EditableWrapper>
                </div>
            </div>
        );
    }
}
