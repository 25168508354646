/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Spacer, useFlags } from 'sarsaparilla';
import LabelAndTimestampAgo from './LabelAndTimestampAgo';
import LabelRow from './LabelRow';

const propTypes = {
    user: PropTypes.shape({
        should_receive_sms: PropTypes.bool,
        locked: PropTypes.bool,
        is_email_confirmed: PropTypes.bool,
        user_id: PropTypes.string,
        last_logged_in_at: PropTypes.string,
        must_set_password: PropTypes.bool,
        password_set_at: PropTypes.string,
        last_attested_at: PropTypes.string,
        created_at: PropTypes.string,
        enforce_effective_dates: PropTypes.bool,
        effective_start_at: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        effective_end_at: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        mfa_data: PropTypes.object,
        oidc_user_info: PropTypes.object,
    }),
};

export default function AdditionalInformation({ user }) {
    const { enableIaEffectiveDates } = useFlags();

    if (!user) {
        return null;
    }

    const showEffectiveDates = enableIaEffectiveDates && user.enforce_effective_dates;
    // TODO: replace moment here
    const accountInactive = moment().isBefore(user?.effective_start_at);
    const accountExpired = moment().isAfter(user?.effective_end_at);

    return (
        <div className="update-user-options">
            <h6>History</h6>
            <LabelAndTimestampAgo label={'Created at'} timestamp={user.created_at} />
            <LabelAndTimestampAgo
                label={'Last logged in'}
                timestamp={user.last_logged_in_at}
            />
            <LabelAndTimestampAgo
                label={'Password last set'}
                timestamp={user.password_set_at}
            />
            <LabelAndTimestampAgo
                label={'Last attested to'}
                timestamp={user.last_attested_at}
            />

            {user.mfa_data && (
                <>
                    {user.mfa_data?.bypass_at && (
                        <LabelAndTimestampAgo
                            label="MFA Bypassed On"
                            timestamp={user.mfa_data?.bypass_at}
                        />
                    )}
                    {user.mfa_data?.opt_out_at && (
                        <LabelAndTimestampAgo
                            label="MFA Opted-Out On"
                            timestamp={user.mfa_data?.opt_out_at}
                        />
                    )}
                    {user.mfa_data?.verified_at && (
                        <LabelAndTimestampAgo
                            label="MFA Enabled On"
                            timestamp={user.mfa_data?.verified_at}
                        />
                    )}
                </>
            )}

            {user.oidc_user_info && user.oidc_user_info?.loginDotGov && (
                <LabelAndTimestampAgo
                    label="Login.gov connected"
                    timestamp={user.oidc_user_info?.loginDotGov?.modified_at}
                />
            )}

            <Spacer size="md" />
            {user.must_set_password && (
                <LabelRow>User&apos;s password has expired</LabelRow>
            )}
            {!user.is_email_confirmed && (
                <LabelRow>User&apos;s email has not been confirmed</LabelRow>
            )}

            {showEffectiveDates && accountInactive && (
                <LabelRow>
                    Account not active until{' '}
                    {moment(user?.effective_start_at).format('MM/DD/YYYY')}
                </LabelRow>
            )}

            {showEffectiveDates && accountExpired && (
                <LabelRow>
                    Account expired on{' '}
                    {moment(user?.effective_end_at).format('MM/DD/YYYY')}
                </LabelRow>
            )}

            {user.should_receive_sms && <LabelRow>Opted-in to text messages</LabelRow>}
        </div>
    );
}

AdditionalInformation.propTypes = propTypes;
