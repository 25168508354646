/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { LocationsOption } from '../../../ui-communication/src/constants/types';

export type RowObject = {
    id: string;
    parentId: null;
    location_id: string;
    location_name: string;
    count: number;
};

export const ALL_LOCATIONS_OPTION: LocationsOption = {
    location_id: 'ALL_LOCATIONS',
    location_type: 'Agency',
    location_name: ' My Assigned Locations',
    location_description: '',
};

export const ERROR_ROW_OBJECT: RowObject = {
    id: 'nodata',
    parentId: null,
    location_id: 'nodata',
    location_name: 'Error Occurred Loading Data',
    count: 0,
};

export const NO_RESULTS_OBJECT: RowObject = {
    id: 'empty',
    parentId: null,
    location_id: 'empty',
    location_name: 'No Results Found',
    count: 0,
};
