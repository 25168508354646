/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { template, isEqual, get, assign } from 'lodash';
import { Icon, BrandingIcons } from 'sarsaparilla';
import {
    FIELD_SALES_SALES_CHANNEL,
    CALL_CENTER_SALES_CHANNEL,
} from '../../../ui-field-sales/src/constants/types';
import * as tConst from '../../../ui-field-sales/src/constants/tabs';
import { ACTIVITY_PASS, REC_AREA } from './locationConstants';

export const OPERATIONS = 'Operations';
export const INVENTORY_MANAGEMENT = 'Management';
export const USERS_AND_REPORTS = 'Admin';
export const launchBuckets = {
    [OPERATIONS]: 'Daily Operations',
    [INVENTORY_MANAGEMENT]: 'Inventory Management',
    [USERS_AND_REPORTS]: 'Administration',
};

const PARK_PASS_CONTENT_MANAGEMENT = 'PARK_PASS_CONTENT_MANAGEMENT';
export const domainToTileMap = {
    USER_MANAGEMENT: {
        path: '/internal/account/manage-users',
        text: 'User Management',
        icon: <Icon iconName="people-outline" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    ATTESTATION_MANAGEMENT: {
        path: '/internal/account/manage-attestation',
        text: 'Attestation Management',
        icon: <Icon iconName="people-outline" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    CUSTOMER_SERVICE_REPRESENTATIVE: {
        path: '/internal/csr/',
        text: 'CSR Hub',
        icon: <Icon iconName="people-outline" size="lg" />,
        bucket: OPERATIONS,
    },
    // This is a duplicate for PMOs and CSRs
    RESEARCH: {
        path: '/internal/csr/',
        text: 'Research',
        icon: <Icon iconName="people-outline" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    // FACILITY_WIDE: {
    //   path: '/internal/account/facility-wide', // TODO need to define this
    //   text: 'Facility Wide',
    //   icon: <Icon iconName="mall" size="lg" />
    // },
    FIELD_SALES: {
        path: '/internal/sales/field/${location_type}/${location_id}',
        text: 'Field Sales',
        icon: <Icon iconName="atm" size="lg" />,
        bucket: OPERATIONS,
        salesChannel: FIELD_SALES_SALES_CHANNEL,
    },
    // ATR: {
    //   path: '/internal/account/atr', // TODO need to define this
    //   text: 'Field Sales',
    //   icon: <Icon iconName="star-border" size="lg" />
    // },
    COMMUNICATION: {
        path: '/internal/communication',
        text: 'Alerts & Communications',
        icon: <Icon iconName="email" size="lg" />,
        bucket: OPERATIONS,
    },
    PASS_DOWNLOAD: {
        path: '/internal/pass/requisitions-downloads',
        text: 'Interagency Pass Orders',
        icon: <Icon iconName="file-download" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    PASS_HOME: {
        path: '/internal/pass',
        text: 'Interagency Annual Pass Requisitions',
        icon: <Icon iconName="card" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    POS_MANAGEMENT: {
        path: '/internal/pos/${location_type}/${location_id}/management', // POS
        text: 'Point of Sales Management',
        icon: <Icon iconName="cog" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    DOMAIN_BAH_FINANCIAL: {
        path: '/internal/bahfinance',
        text: 'BAH Finance',
        icon: <Icon iconName="atm" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    FINANCIAL_TRANSACTION_MANAGEMENT: {
        path: '/internal/ftm',
        text: 'Finance',
        icon: <Icon iconName="atm" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    REPORTS: {
        path: '/internal/reporting',
        text: 'Reports & Dashboards',
        icon: <Icon iconName="description" size="lg" />,
        bucket: OPERATIONS,
    },
    // TRAINING: {
    //   path: '/internal/training', // TODO needs to be updated by other microservice team, when path exists
    //   text: 'Training',
    //   icon: <Icon iconName="cog" size="lg" />
    // },
    FEE_MANAGEMENT: {
        path: '/internal/${location_type}/${location_id}/fees',
        text: 'Season & Fee Management',
        icon: <Icon iconName="cog" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    GEOFENCING_MANAGEMENT: {
        path: '/internal/geofencing/${location_id}',
        text: 'Geofencing Management',
        icon: <Icon iconName="explore" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    MERCHANDISE_MANAGEMENT: {
        path: '/internal/merchandise/${location_id}',
        text: 'Merchandise Sales',
        icon: <Icon iconName="atm" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    ...(process.env.ALLOWED_INVENTORY_TYPES?.merchandise
        ? {
              VENDOR_MANAGEMENT: {
                  path: '/internal/vendor/${location_id}',
                  text: 'Vendor Management',
                  icon: <Icon iconName="card" size="lg" />,
                  bucket: USERS_AND_REPORTS,
              },
          }
        : {}),
    // Before Sept. 2023, gateway management's path used location_id which is not the id that's
    // used on the external site or for any of the gateway-related api calls except for the
    // old get gateway details api call. See notes in in the GatewayManagement code
    CAMPING_GATEWAY_MANAGEMENT: {
        path:
            process.env.GATEWAY_VERSION === 'v2'
                ? '/internal/gateways/${asset_id}'
                : '/internal/${location_type}/gateway/${asset_id}',
        text: 'Gateway Management',
        icon: <Icon iconName="terrain" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    CAMPING_RESERVATION_MANAGEMENT: {
        path: '/internal/${location_type}/${location_id}/reservations',
        text: 'Camping Reservation Management',
        icon: <Icon iconName="check-circle" size="lg" />,
        bucket: OPERATIONS,
    },
    CAMPING_LOTTERY_CONFIGURATION: {
        path: '/internal/campinglottery/configuration/${location_id}',
        text: 'Camping Lottery Configuration',
        icon: <Icon iconName="lottery" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    CAMPING_INVENTORY_MANAGEMENT: {
        path: '/internal/${location_type}/facilities/${location_id}',
        text: 'Camping Inventory Management',
        icon: <Icon iconName="tent" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    PERMIT_RESERVATION_MANAGEMENT: {
        path: '/internal/${location_type}/${location_id}/reservations',
        text: 'Reservation Management',
        icon: <Icon iconName="check-circle" size="lg" />,
        bucket: OPERATIONS,
    },
    RENTAL_RESERVATION_MANAGEMENT: {
        path: '/internal/rentals/${location_id}/reservations',
        text: 'Rental Reservation Management',
        icon: <Icon iconName="check-circle" size="lg" />,
        bucket: OPERATIONS,
    },
    PERMIT_INVENTORY_MANAGEMENT: {
        path: '/internal/${location_type}/${location_id}/inventory',
        text: 'Inventory Management',
        icon: <Icon iconName="ticket" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    PERMIT_LOTTERY_MANAGEMENT: {
        path: '/internal/${location_type}/${location_id}/lotteries',
        text: 'Lottery Management',
        icon: <BrandingIcons.Lottery size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    POI_MANAGEMENT: {
        path: process.env.POI_VERSION === 'v2' ? '/internal/poi/${asset_id}' : '/',
        text: 'Point of Interest Management',
        icon: <Icon iconName="terrain" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    QUEUE_LOTTERY_MANAGEMENT: {
        path: '/internal/${location_type}/${location_id}/queuelotteries',
        text: 'Early Access Lottery Management',
        icon: <BrandingIcons.Lottery size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    TICKET_RESERVATION_MANAGEMENT: {
        path: '/internal/tickets/${location_id}/reservations',
        text: 'Reservation Management',
        icon: <Icon iconName="check-circle" size="lg" />,
        bucket: OPERATIONS,
    },
    TICKET_INVENTORY_MANAGEMENT: {
        path: '/internal/tickets/${location_id}/inventory',
        text: 'Inventory Management',
        icon: <Icon iconName="ticket" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    TICKET_LOTTERY_MANAGEMENT: {
        path: '/internal/tickets/facility/${location_id}/lotteries',
        text: 'Lottery Management',
        icon: <BrandingIcons.Lottery size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    // TODO Bring this tile back when the service is available
    // DISCOUNT_MANAGEMENT: {
    //   path: '/internal/discounts/${location_id}/management',
    //   text: 'Discount Management',
    //   icon: <Icon iconName="atm" size="lg" />,
    //   bucket: INVENTORY_MANAGEMENT
    // },
    PARK_PASS_INVENTORY_MANAGEMENT: {
        path: '/internal/passes-management/locations',
        text: 'Digital Pass Internal Management',
        icon: <Icon iconName="card" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    [PARK_PASS_CONTENT_MANAGEMENT]: {
        path: '/internal/${location_type}/content-management/${location_id}/general-information',
        text: 'Digital Pass Management',
        icon: <Icon iconName="card" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    PARK_PASS_RESERVATION_MANAGEMENT: {
        path: '/internal/parkpass/location-selection',
        text: 'Validation Scanner',
        icon: <Icon iconName="card" size="lg" />,
        bucket: OPERATIONS,
    },
    SITE_PASS_RESERVATION_MANAGEMENT: {
        path: '/internal/scanner/parkpass/location-selection',
        text: 'Validation Scanner',
        icon: <Icon iconName="card" size="lg" />,
        bucket: OPERATIONS,
    },
    ACTIVITY_PASS_RESERVATION_MANAGEMENT: {
        path: '/internal/activitypass/location-selection',
        text: 'Validation Scanner',
        icon: <Icon iconName="card" size="lg" />,
        bucket: OPERATIONS,
    },
    SHIFT_HISTORY: {
        path: '/internal/shift/shift-history/${location_type}/${location_id}',
        text: 'Shift & Deposits',
        icon: <Icon iconName="clock" size="lg" />, // TODO: Right icon?
        bucket: OPERATIONS,
    },
    COMMERCIAL_MANAGEMENT: {
        path: '/internal/operators/companies',
        text: 'Commercial Management',
        icon: <Icon iconName="convenience-store" size="lg" />, // TODO: Right icon?
        bucket: USERS_AND_REPORTS,
    },
    CONCESSIONAIRES: {
        path: '/internal/account/manage-concessionaires',
        text: 'Concessionaires',
        icon: <Icon iconName="supervisor-account" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    REQUEST_REFUND: {
        path: '/internal/refunds/reservations',
        text: 'Request Refund',
        icon: <Icon iconName="refund" size="lg" />,
        bucket: OPERATIONS,
    },
    REFUND_REQUESTS: {
        path: '/internal/refunds/requests',
        text: 'Refund Requests',
        icon: <Icon iconName="refund" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    DOMAIN_COOPERATOR_MANAGEMENT: {
        path: '/internal/account/manage-cooperators',
        text: 'Cooperators',
        icon: <Icon iconName="mood" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    DOMAIN_FACILITY_INFORMATION: {
        path: '/internal/account/facility-info/${location_type}/${location_id}',
        text: 'Facility Information',
        icon: <Icon iconName="rec-menu" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    RATINGS_REVIEWS: {
        path: '/internal/rating-review',
        text: 'Ratings & Reviews',
        icon: <Icon iconName="star-half" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    RATINGS_REVIEWS_APPROVAL_INBOX: {
        path: '/internal/rating-review/moderation',
        text: 'Ratings & Reviews Approval Inbox',
        icon: <Icon iconName="star-half" size="lg" />,
        bucket: OPERATIONS,
    },
    MACHINE_API_PERMISSIONS: {
        path: '/internal/account/machine-api-permissions',
        text: 'Machine API Permissions Management',
        icon: <Icon iconName="key" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    MACHINE_API_KEYS: {
        path: '/internal/account/machine-api-keys',
        text: 'Machine API Keys',
        icon: <Icon iconName="key" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    TOKEN_MANAGEMENT: {
        path: '/internal/account/device-tokens',
        text: 'Ranger App Authentication',
        icon: <Icon iconName="phone-iphone-validate" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    pos_path_for_map: {
        path: '/internal/sales/field/pos/${location_type}/${location_id}',
    },
    pass_path_for_map: {
        path: '/internal/sales/field/pass/${location_type}/${location_id}',
    },
    ccs_path_for_map: {
        path: '/internal/sales/call-center/${location_type}/${location_id}',
    },
    reservation_management: {
        path: '/internal/${location_type}/${location_id}/reservations',
    },
    DOMAIN_PERMIT_CONTENT: {
        path: '/internal/${location_type}/${location_id}/content',
        text: 'Content Management',
        icon: <Icon iconName="dashboard" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    QRCODE_MANAGEMENT: {
        path: '/internal/qr-codes/management',
        text: 'QR Management',
        icon: <Icon iconName="qr-code" size={'lg'} />,
        bucket: INVENTORY_MANAGEMENT,
    },
    DOMAIN_TREE_PERMIT_INTERNAL_MANAGEMENT: {
        path: '/internal/tree-permits/locations-management',
        text: 'Tree Permit Internal Management',
        icon: <Icon iconName="tree" size={'lg'} />,
        bucket: USERS_AND_REPORTS,
    },
    DOMAIN_XMAS_TREE_PERMIT_MANAGEMENT: {
        path: '/internal/tree-permits/inventory-management/${location_id}',
        text: 'Christmas Tree Permit Management',
        icon: <Icon iconName="tree" size={'lg'} />,
        bucket: INVENTORY_MANAGEMENT,
    },
    DOMAIN_LICENSE_PLATE_READER: {
        path: '/internal/timed-entry/lpr',
        text: 'License Plate Readers',
        icon: <Icon iconName="camera" size={'lg'} />,
        bucket: USERS_AND_REPORTS,
    },
    DOMAIN_GIS_DATA: {
        path: '/internal/camping/${location_id}/gis/upload',
        text: 'GIS Data',
        icon: <Icon iconName="pin-drop" size={'lg'} />,
        bucket: INVENTORY_MANAGEMENT,
    },
    DOMAIN_CAMPING_BULK_CLOSURES_MANAGEMENT: {
        path: '/internal/camping/bulk_facilities/${location_id}',
        text: 'Bulk Facilities Management',
        icon: <Icon iconName="calendar-busy" size={'lg'} />,
        bucket: INVENTORY_MANAGEMENT,
    },
    DOMAIN_MARKETING: {
        path: '/internal/marketing/qr-code-generator',
        text: 'Marketing QR Codes',
        icon: <Icon iconName="atm" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    DOMAIN_DNC_MANAGEMENT: {
        path: '/internal/communication/dnc',
        text: 'DNC Management',
        icon: <Icon iconName="phone" size={'lg'} />,
        bucket: USERS_AND_REPORTS,
    },
    DOMAIN_VENUE_INVENTORY_MANAGEMENT: {
        path: '/internal/venue/inventory-management/${location_id}/venues-list',
        text: 'Venue Inventory Management',
        icon: <Icon iconName="dashboard" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    DOMAIN_VENUE_INTERNAL_MANAGEMENT: {
        path: '/internal/venue/locations',
        text: 'Venue Internal Management',
        icon: <Icon iconName="calendar-available" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    DOMAIN_VENUE_FACILITY_MANAGEMENT: {
        path: '/internal/venue/facility-management/${location_id}/website-content',
        text: 'Facility Management',
        icon: <Icon iconName="calendar-available" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    DOMAIN_VENUE_RESERVATION_MANAGEMENT: {
        path: '/internal/venue/${location_id}/reservations',
        text: 'Venue Reservation Management',
        icon: <Icon iconName="check-circle" size="lg" />,
        bucket: OPERATIONS,
    },
    DOMAIN_VENUE_CLOSURE_MANAGEMENT: {
        path: '/internal/venue/${location_id}/closures',
        text: 'Venue Closure Management',
        icon: <Icon iconName="calendar-busy" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    DOMAIN_COMMUNICATION_TEMPLATE_MANAGEMENT: {
        path: '/internal/communication/templates',
        text: 'Alerts & Comms Template Management',
        icon: <Icon iconName="warning" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    DOMAIN_PROMO_CODE: {
        path: '/internal/ftm/promo-discount-codes',
        text: 'Promotional Discount Codes',
        icon: <Icon iconName="offer" size="lg" />,
        bucket: INVENTORY_MANAGEMENT,
    },
    AVAILABILITY_CHANGE: {
        path: '/internal/camping/${location_id}/occupancyAlert/list',
        text: 'Occupancy Change Notifications',
        icon: <Icon iconName="bell" size="lg" />,
        bucket: OPERATIONS,
    },
    SHOW_OUTREACH_MATERIALS_TEMPLATES: {
        path: process.env.OUTREACH_MATERIALS_TEMPLATES_URL ?? 'hub',
        text: 'Outreach Materials and Templates',
        icon: <Icon iconName="volume-up" size="lg" />,
        bucket: USERS_AND_REPORTS,
    },
    SPECIAL_ACCESS_PASS_INTERNAL_MANAGEMENT: {
        path: '/internal/special-access-pass/passes',
        text: 'Special Access Pass Management',
        icon: <Icon iconName="card" size={'lg'} />,
        bucket: USERS_AND_REPORTS,
    },
    ...(process.env.ALLOWED_INVENTORY_TYPES?.programs
        ? {
              DOMAIN_PROGRAM_INTERNAL_MANAGEMENT: {
                  path: '/internal/programs',
                  text: 'Program Internal Management',
                  icon: <Icon iconName="calendar-available" size="lg" />,
                  bucket: USERS_AND_REPORTS,
              },
              DOMAIN_PROGRAM_MANAGEMENT: {
                  path: '/internal/programs/${location_id}',
                  text: 'Program Management',
                  icon: <Icon iconName="view-module" size="lg" />,
                  bucket: INVENTORY_MANAGEMENT,
              },
              DOMAIN_PROGRAM_REGISTRATION_MANAGEMENT: {
                  path: '/internal/programs/${location_id}/registrations/all-registrations',
                  text: 'Registration Management',
                  icon: <Icon iconName="clipboard" size="lg" />,
                  bucket: OPERATIONS,
              },
          }
        : {}),
};

const swapEncode = (obj) => {
    const ret = {};
    Object.keys(obj).forEach((key) => {
        ret[obj[key]] = key;
    });

    return ret;
};

export const encodeLocTypeUrlKeyMap = {
    Root: 'root',
    Campground: 'camping',
    Permit: 'permits',
    'Ticket Facility': 'tickets',
    'Vehicle Permit': 'vehiclepermit',
    'Activity Pass': 'activitypass',
    'Tree Permit': 'treepermit',
    'Timed Entry': 'timed-entry',
    'Venue Reservations': 'venue',
};

export const decodeLocTypeUrlKeyMap = {
    ...swapEncode(encodeLocTypeUrlKeyMap),
    permit: 'Permit',
    ticket: 'Ticket Facility',
    recarea: 'Rec Area',
    vehiclepermit: 'Vehicle Permit',
    activitypass: 'Activity Pass',
    treepermit: 'Tree Permit',
    'timed-entry': 'Timed Entry',
    venue: 'Venue Reservations',
};

export const encodeLocTypePath = (location) => {
    if (location.has_park_pass) {
        return 'parkpass';
    }

    const key = location.location_type;
    return encodeLocTypeUrlKeyMap[key] || key;
};

export function getLocationSalesUrl(tabLocation, locationId, salesChannel, locationType) {
    const url = template(get(tConst.tabLookup[tabLocation], 'url'));
    const destination = url({
        sales_type: tConst.salesUrlIdentifierLookup[salesChannel],
        location_id: locationId,
        location_type: encodeLocTypePath(locationType || tabLocation) || tabLocation,
    });
    return destination;
}

export const generateTilePath = (tile, location) => {
    const compiled = template(tile.path);
    if (
        isEqual(get(tile, 'salesChannel'), FIELD_SALES_SALES_CHANNEL) ||
        isEqual(get(tile, 'salesChannel'), CALL_CENTER_SALES_CHANNEL)
    ) {
        const { location_type, location_id } = location;
        let tabLocation = location_type;
        if (
            location.has_park_pass &&
            isEqual(get(tile, 'salesChannel'), FIELD_SALES_SALES_CHANNEL)
        ) {
            tabLocation = 'pass';
        }
        if (tabLocation === 'Activity Pass') {
            tabLocation = 'activitypass';
        }
        return getLocationSalesUrl(
            tabLocation,
            location_id,
            tile.salesChannel,
            location.location_type
        );
    }

    // Hardcoding it here to 'camping' for gateway because its location_type is 'Rec Area' which will result
    // into an error when it tries to redirect to that page
    if (isEqual(get(tile, 'path'), domainToTileMap.CAMPING_GATEWAY_MANAGEMENT.path)) {
        return compiled(
            assign({}, location, {
                location_type: 'camping',
            })
        );
    }

    if (
        isEqual(
            get(tile, 'path'),
            domainToTileMap.PARK_PASS_RESERVATION_MANAGEMENT.path
        ) &&
        location?.location_type === ACTIVITY_PASS
    ) {
        return domainToTileMap.ACTIVITY_PASS_RESERVATION_MANAGEMENT.path;
    }

    return compiled(
        assign({}, location, {
            location_type: encodeLocTypePath(location),
        })
    );
};

export const generateTileText = (tile, location) => {
    if (isEqual(domainToTileMap[PARK_PASS_CONTENT_MANAGEMENT], tile)) {
        switch (location?.location_type) {
            case ACTIVITY_PASS:
                return 'Activity Pass Management';
            case REC_AREA:
                return 'Site Pass Management';
            default:
                return tile?.text;
        }
    }

    return tile?.text;
};
