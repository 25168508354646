/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Text } from 'sarsaparilla';

interface RowCountTextProps {
    rowCount: number;
    pageSize: number;
    pageIndex: number;
    isEstimate?: boolean;
    dataLength: number;
}

export function RowCountText({
    rowCount,
    pageIndex,
    pageSize,
    isEstimate,
    dataLength,
}: RowCountTextProps) {
    const firstOnPage = pageIndex * pageSize + 1;
    let lastOnPage = Math.min(rowCount, (pageIndex + 1) * pageSize);
    let realRowCount = rowCount;
    let estimateIndicator = isEstimate ? '+' : '';

    if (isEstimate && dataLength && firstOnPage === rowCount) {
        lastOnPage = rowCount + dataLength - 1;
        realRowCount = lastOnPage;
        estimateIndicator = '';
    }

    return !rowCount ? (
        <Text size="sm">0 results</Text>
    ) : rowCount === 1 ? (
        <Text size="sm">1 result</Text>
    ) : (
        <Text size="sm">
            {`${firstOnPage} - ${lastOnPage} results of ${realRowCount}${estimateIndicator}`}
        </Text>
    );
}
