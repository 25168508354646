/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

export const initialState = {
    managedConcessionaires: [],
    concessionairePermits: [],
    loadingManagedConcessionairesError: false,
    loadingConcessionairePermitsError: false,
    savingManagedConcessionairesError: false,
    savingConcessionairePermitsError: false,
};

const manageConcessionaires = (state = initialState, action) => {
    switch (action.type) {
        // LOAD
        case types.RELOAD_MANAGE_CONCESSIONAIRE_ERRORS:
            return {
                ...state,
                loadingManagedConcessionairesError: false,
                loadingConcessionairePermitsError: false,
                savingManagedConcessionairesError: false,
                savingConcessionairePermitsError: false,
            };
        case types.LOADING_MANAGED_CONCESSIONAIRES_SUCCESSFUL:
            return {
                ...state,
                managedConcessionaires: action.concessionaires || [],
                loadingManagedConcessionairesError: false,
            };
        case types.LOADING_MANAGED_CONCESSIONAIRES_FAILED:
            return {
                ...state,
                managedConcessionaires: [],
                loadingManagedConcessionairesError: action.error,
            };
        case types.LOADING_CONCESSIONAIRE_PERMITS_SUCCESSFUL:
            return {
                ...state,
                concessionairePermits: action.permits,
                loadingConcessionairePermitsError: false,
            };
        case types.LOADING_CONCESSIONAIRE_PERMITS_FAILED:
            return {
                ...state,
                concessionairePermits: [],
                loadingConcessionairePermitsError: action.error,
            };

        // SAVE
        case types.SAVING_CONCESSIONAIRE_SUCCESSFUL: {
            const indexToUpdateConc = state.managedConcessionaires
                .map((conc) => conc.concessionaire_id)
                .indexOf(action.concessionaire.concessionaire_id);

            let concs;
            if (indexToUpdateConc > -1) {
                concs = [
                    ...state.managedConcessionaires.slice(0, indexToUpdateConc),
                    ...[action.concessionaire],
                    ...state.managedConcessionaires.slice(indexToUpdateConc + 1),
                ];
            } else {
                concs = [...[action.concessionaire], ...state.managedConcessionaires];
            }
            return {
                ...state,
                managedConcessionaires: concs,
                savingManagedConcessionairesError: false,
            };
        }
        case types.SAVING_CONCESSIONAIRE_PERMIT_SUCCESSFUL: {
            const indexToUpdatePermit = state.concessionairePermits
                .map((permit) => permit.permit_id)
                .indexOf(action.permit.permit_id);

            let permits;
            if (indexToUpdatePermit > -1) {
                permits = [
                    ...state.concessionairePermits.slice(0, indexToUpdatePermit),
                    ...[action.permit],
                    ...state.concessionairePermits.slice(indexToUpdatePermit + 1),
                ];
            } else {
                permits = [...[action.permit], ...state.concessionairePermits];
            }
            return {
                ...state,
                concessionairePermits: permits,
                savingConcessionairePermitsError: false,
            };
        }
        case types.SAVING_CONCESSIONAIRE_FAILED:
            return { ...state, savingManagedConcessionairesError: true };

        case types.SAVING_CONCESSIONAIRE_PERMIT_FAILED:
            return { ...state, savingConcessionairePermitsError: true };

        case types.LOGGED_OUT:
            return initialState;

        // DEFAULT
        default:
            return state;
    }
};

export default manageConcessionaires;
