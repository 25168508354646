/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { isEmpty } from 'lodash';
import moment from 'moment';
import { Dispatch } from 'react';
import { Action } from 'redux';
import { type HistoryV4 } from 'shared-ui';
import * as errors from '../constants/errors';
import * as globals from '../constants/globals';
import * as types from '../constants/types';
import { makeCompatNavigate } from '../utilities/compatNavigateUtil';
import { errorForExceedMaxAttestingFM } from '../utilities/errorMessages';
import { getAccountWithToken } from '../utilities/internalSecurityUtil';
import {
    getLocationDisplayName,
    locationIsUnder,
    locationsAreEqual,
} from '../utilities/locations';
import {
    displayStringForRoleType,
    findRole,
    highestRoleAtLocation,
    optionForExistingRole,
    roleDropdownOptionsForLocation,
    simplifyRolesLocationObject,
    userCanEditUser,
} from '../utilities/roles';
import { validateUser, validateUserPersonalInfo } from '../utilities/validation';
import { getDomainsForLocation } from './fetchChildLocations';
import { doneLoading, startLoading } from './loading';
import { makeAuthorizedRequest } from './login';
import { addNotesForNewUser, fetchNotes } from './notes';
import { AvailableOidcProviders } from './oidc';

export const assignLocation = (location: types.Location): types.LocationAction => {
    return {
        location,
        type: types.ASSIGN_LOCATION,
    };
};

export const deleteLocation = (location: types.Location): types.LocationAction => {
    return {
        location,
        type: types.DELETE_LOCATION,
    };
};

export const cancelAssigningLocations = (history: HistoryV4): types.LocationAction => {
    history.goBack();

    return {
        type: types.CANCEL_ASSIGNING_LOCATIONS,
    };
};

export const assignConcessionaire = (
    concessionaire: types.Concessionaire
): types.ConcessionaireAction => {
    return {
        concessionaire,
        type: types.ASSIGN_CONCESSIONAIRE,
    };
};

export const deleteConcessionaire = (
    concessionaire: types.Concessionaire
): types.ConcessionaireAction => {
    return {
        concessionaire,
        type: types.DELETE_CONCESSIONAIRE,
    };
};

export const cancelAssigningConcessionaires = (
    history: HistoryV4
): types.ConcessionaireAction => {
    history.goBack();

    return {
        type: types.CANCEL_ASSIGNING_CONCESSIONAIRES,
    };
};

export const roleAssigned = (role: types.Role): types.RoleAction => {
    return {
        role,
        type: types.ROLE_ASSIGNED,
    };
};

export const saveFailed = (error: string | Error): types.ErrorAction => {
    return {
        error,
        type: types.SAVE_FAILED,
    };
};

export const loginDotGovUnlinked = (): Action => {
    return { type: types.LOGIN_GOV_UNLINKED };
};

export const continueFromAssigningLocations = () => {
    return (
        dispatch: Dispatch<types.AssigningLocationsAction | types.ErrorAction>,
        getState: Function
    ): void => {
        const state = getState();

        if (state.updateUser.assignedLocations.length === 0) {
            dispatch(saveFailed(errors.MUST_ASSIGN_ONE_LOCATION));
        } else {
            const loggedInUserRoles = state.login.account.user.roles;
            const isEditingSelf =
                state.login.account.user.user_id ===
                state.updateUser.accountToSave.user_id;
            const roleAssignmentOptions: { [key: string]: types.RoleAssignmentOption } =
                {};
            const assignedRoleForLocationId: { [key: string]: types.AssignedRole } = {};

            for (const assignedLocation of state.updateUser.assignedLocations) {
                const isConcessionaireLocationAssignment =
                    assignedLocation.hierarchyChildren &&
                    assignedLocation.hierarchyChildren?.length > 0;
                const unwrappedAssignedLocation = isConcessionaireLocationAssignment
                    ? assignedLocation.hierarchyChildren
                    : [assignedLocation];
                const lastOptions = [];
                let i = 0;
                for (const ual of unwrappedAssignedLocation) {
                    // This is less than ideal, but simplest solution without rewriting a lot of stuff
                    const managingRolesForLocation = [];
                    for (const managerRole of loggedInUserRoles) {
                        if (
                            locationIsUnder(ual, managerRole.location) ||
                            locationsAreEqual(managerRole.location, ual)
                        ) {
                            managingRolesForLocation.push(managerRole);
                        }
                    }

                    if (managingRolesForLocation.length === 0) {
                        const highestExistingRole = highestRoleAtLocation(
                            state.updateUser.accountToSave.roles,
                            ual
                        );
                        const existingRole = findRole(
                            state.updateUser.accountToSave.roles,
                            ual
                        );

                        const previousRoleType = highestExistingRole.role_type;
                        const previousOption = {
                            value: previousRoleType,
                            label: displayStringForRoleType(previousRoleType),
                            order: 0,
                        };
                        roleAssignmentOptions[ual.location_id] = {
                            dropDownOptions: [previousOption],
                            initiallySelectedOption: previousOption,
                        };
                        assignedRoleForLocationId[ual.location_id] = {
                            role_type: previousRoleType,
                            location: ual,
                            can_attest_to_peers: !!existingRole?.can_attest_to_peers,
                        };
                        continue;
                    }

                    const dropDownOptions = roleDropdownOptionsForLocation(
                        loggedInUserRoles,
                        ual,
                        isEditingSelf
                    );
                    let initialOption = null;
                    const previouslySelectedRole =
                        state.updateUser.assignedRoleForLocationId[ual.location_id];
                    if (previouslySelectedRole) {
                        for (const option of dropDownOptions) {
                            if (option.value === previouslySelectedRole.role_type) {
                                initialOption = option;
                            }
                        }
                    } else if (!state.updateUser.creating) {
                        initialOption = optionForExistingRole(
                            dropDownOptions,
                            ual,
                            state.updateUser.accountToSave.roles,
                            isEditingSelf
                        );
                    }

                    roleAssignmentOptions[ual.location_id] = {
                        dropDownOptions,
                        initiallySelectedOption: initialOption,
                    };

                    if (initialOption && !isConcessionaireLocationAssignment) {
                        const existingRole = findRole(
                            state.updateUser.accountToSave.roles,
                            ual
                        );

                        assignedRoleForLocationId[ual.location_id] = {
                            role_type: (initialOption as types.DropdownOption)?.value,
                            location: ual,
                            can_attest_to_peers: !!existingRole?.can_attest_to_peers,
                        };
                    }

                    if (i + 1 === unwrappedAssignedLocation.length)
                        lastOptions.push(...dropDownOptions);
                    i += 1;
                }

                if (isConcessionaireLocationAssignment) {
                    roleAssignmentOptions[assignedLocation.location_id] = {
                        dropDownOptions: lastOptions,
                        initiallySelectedOption: null,
                    };
                }
            }

            dispatch({
                type: types.CONTINUE_FROM_ASSIGNING_LOCATIONS,
                roleAssignmentOptions,
                assignedRoleForLocationId,
            });
        }
    };
};

export const accountSaved = (user: types.User): types.UserAction => {
    return {
        user,
        type: types.ACCOUNT_SAVED,
    };
};

export const emailChangeCancelled = (): Action => {
    return { type: types.EMAIL_CHANGE_CANCELLED };
};

export const cancelUpdate = (history: HistoryV4): Action => {
    const navigate = makeCompatNavigate(history);
    navigate('/internal/account/hub');

    return {
        type: types.CANCEL_UPDATE,
    };
};

export const creatingUser = (): Action => {
    return {
        type: types.CREATING_USER,
    };
};

export const editUserRoles = (history: HistoryV4) => {
    return (dispatch: Dispatch<Action>, getState: Function): void => {
        const state = getState();
        const currentRoles = state.updateUser.accountToSave.roles.sort(
            (a: types.Role, b: types.Role) => {
                const nameA = getLocationDisplayName(a.location);
                const nameB = getLocationDisplayName(b.location);

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            }
        );

        // Set existing assigned roles to the state
        for (const role of currentRoles) {
            dispatch(assignLocation(role.location));
        }

        const navigate = makeCompatNavigate(history);
        navigate('/internal/account/edit-locations');
    };
};

export const updateConcAssignment = (history: HistoryV4): Action => {
    const navigate = makeCompatNavigate(history);
    navigate('/internal/account/edit-concessionaires');

    return {
        type: types.CREATING_CONCESSIONAIRE_USER,
    };
};

export const getUserFailed = (error: string): types.ErrorAction => {
    return {
        error,
        type: types.GET_USER_FAILED,
    };
};

export const getUser = async (
    userId: string,
    dispatch: Dispatch<Action>
): Promise<types.User | boolean> => {
    try {
        dispatch({ type: types.GETTING_USER });

        const url = `${globals.API_URL}/user/${userId}`;

        const response = await makeAuthorizedRequest(url, 'GET', dispatch);
        return response.user;
    } catch {
        dispatch(getUserFailed(errors.GET_USER_FAILED));
        return false;
    }
};

const getConcessionaireSuccess = (
    concessionaire: types.Concessionaire
): types.ConcessionaireAction => {
    return {
        concessionaire,
        type: types.GET_CONCESSIONAIRE_SUCCESS,
    };
};

const getConcessionaireFailed = (error: string): types.ErrorAction => {
    return {
        error,
        type: types.GET_CONCESSIONAIRE_FAILED,
    };
};

export const getConcessionaire = (concessionaireId: string) => {
    return async (dispatch: Dispatch<Action>): Promise<void> => {
        try {
            const url = `${globals.API_URL}/concessionaire/${concessionaireId}`;

            const response = await makeAuthorizedRequest(url, 'GET', dispatch);
            dispatch(getConcessionaireSuccess(response.concessionaire));
        } catch {
            dispatch(getConcessionaireFailed(errors.GET_CONCESSIONAIRE_FAILED));
        }
    };
};

const getAssignedConcessionairesSuccess = (
    concessionaires: types.Concessionaires
): types.ConcessionairesAction => {
    return {
        concessionaires,
        type: types.GET_ASSIGNED_CONCESSIONAIRES_SUCCESS,
    };
};

const getAssignedConcessionairesFailed = (error: Error): types.ErrorAction => {
    return {
        error,
        type: types.GET_ASSIGNED_CONCESSIONAIRES_FAILED,
    };
};

const getAssignedConcessionaire = (id: string) => {
    return async (dispatch: Dispatch<Action>): Promise<types.Concessionaire> => {
        const url = `${globals.API_URL}/v2/concessionaire/${id}`;
        const response = await makeAuthorizedRequest(url, 'GET', dispatch);

        return response.concessionaire;
    };
};

export const getAssignedConcessionaires = (ids: string[]) => {
    return async (dispatch: Dispatch<Action>): Promise<void> => {
        try {
            const fetches = ids.map(
                async (id) =>
                    new Promise((resolve) => {
                        getAssignedConcessionaire(id)(dispatch).then((conc) => {
                            resolve(conc);
                        });
                    })
            );

            const concessionaires = (await Promise.all(fetches)) as types.Concessionaires;

            dispatch(getAssignedConcessionairesSuccess(concessionaires));
        } catch (error) {
            dispatch(getAssignedConcessionairesFailed(error as Error));
        }
    };
};

export const editMyProfile = (history: HistoryV4) => {
    return async (dispatch: Dispatch<any>, getState: Function) => {
        dispatch(startLoading());

        const state = getState();
        const userFromServer = (await getUser(
            state.login.user.user_id,
            dispatch
        )) as types.User;

        if (userFromServer?.is_concessionaire) {
            if (userFromServer?.concessionaire_ids) {
                dispatch(getAssignedConcessionaires(userFromServer?.concessionaire_ids));
            } else if (userFromServer?.concessionaire_id) {
                // OLD KILL //
                dispatch(getConcessionaire(userFromServer?.concessionaire_id));
            } // OLD //
        }

        if (userFromServer && userFromServer.user_id === state.login.user.user_id) {
            dispatch({
                accountToSave: userFromServer,
                type: types.EDITING_USER,
                readOnly: true,
            });
        } else if (
            userFromServer &&
            userFromServer.user_id !== state.login.user.user_id
        ) {
            // The request must have been man
            const navigate = makeCompatNavigate(history);
            navigate('/internal/account/donotmanipulatetherequestplease');
        }

        dispatch(fetchNotes(state.login.user.user_id));
        dispatch(doneLoading());
    };
};

export const resetUser = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(startLoading());
        dispatch({
            type: types.RESET_UPDATE_USER,
        });
        dispatch(doneLoading());
    };
};

export const editingUser = (userIdToEdit: string) => {
    return async (dispatch: Dispatch<any>, getState: Function) => {
        dispatch(startLoading());

        const userFromServer = (await getUser(userIdToEdit, dispatch)) as types.User;

        if (userFromServer.is_concessionaire) {
            if (userFromServer.concessionaire_ids) {
                dispatch(getAssignedConcessionaires(userFromServer.concessionaire_ids));
            } else if (userFromServer.concessionaire_id) {
                // OLD KILL //
                dispatch(getConcessionaire(userFromServer.concessionaire_id));
            } // OLD //
        }

        if (userFromServer) {
            const state = getState();
            const readOnly = !userCanEditUser(state.login.user, userFromServer);

            dispatch({
                accountToSave: userFromServer,
                type: types.EDITING_USER,
                readOnly,
            });
        }

        dispatch(fetchNotes(userIdToEdit));
        dispatch(doneLoading());
    };
};

const doesUserExist = async (
    email: string,
    dispatch: Dispatch<Action>
): Promise<boolean> => {
    const url = `${globals.API_URL}/user/exists?email=${encodeURIComponent(email)}`;
    const response = await makeAuthorizedRequest(url, 'GET', dispatch);

    return response.exists;
};

export const continueFromCreatingUser = (createdUser: types.User) => {
    return async (dispatch: Dispatch<any>, getState: Function): Promise<boolean> => {
        let continueFromCreating = true;
        dispatch(startLoading());

        try {
            validateUser(createdUser);

            if (createdUser.locked && getState().notes.notesForCreate.length === 0) {
                dispatch(saveFailed(errors.LOCKED_USER_REQUIRES_NOTE));
                continueFromCreating = false;
            }

            const userExists = await doesUserExist(createdUser.email, dispatch);

            if (userExists) {
                dispatch(saveFailed(errors.USER_WITH_EMAIL_ALREADY_EXISTS));
                continueFromCreating = false;
            }
        } catch (error) {
            dispatch(saveFailed(error as Error));
            continueFromCreating = false;
        }

        dispatch(doneLoading());

        if (continueFromCreating) {
            dispatch({
                type: types.CONTINUE_FROM_CREATING_USER,
                createdUser,
            });
        }
        return continueFromCreating;
    };
};

export const requestEmailChange = (userID: string, newEmail: string) => {
    return async (dispatch: Dispatch<Action>): Promise<void> => {
        dispatch(startLoading());
        try {
            if (isEmpty(userID) || isEmpty(newEmail)) {
                throw new Error(`New email can't be empty.`);
            }

            const jsonString = JSON.stringify({ new_email: newEmail });
            const url = `${globals.API_URL}/user/${userID}/email-change`;
            const responseJson = await makeAuthorizedRequest(
                url,
                'PATCH',
                dispatch,
                jsonString
            );

            dispatch(accountSaved(responseJson));
        } catch (error) {
            dispatch(saveFailed(error as Error));
        }
        dispatch(doneLoading());
    };
};

export const updateEffectiveDates = (ued: types.UserEffectiveDate) => {
    return async (dispatch: Dispatch<Action>): Promise<void> => {
        dispatch(startLoading());
        try {
            if (
                ued.enforce_effective_dates &&
                (isEmpty(ued.effective_start_at) || isEmpty(ued.effective_end_at))
            ) {
                throw new Error(
                    `Effective dates can't be empty when enforce effective dates is selected.`
                );
            }

            const jsonString = JSON.stringify(ued);
            const url = `${globals.API_URL}/user/${ued.user_id}/effective`;
            const responseUser = await makeAuthorizedRequest(
                url,
                'PATCH',
                dispatch,
                jsonString
            );

            dispatch(accountSaved(responseUser));
        } catch (error) {
            dispatch(saveFailed(error as Error));
        }
        dispatch(doneLoading());
    };
};

export const updatePersonalInfo = (
    upi: types.UserPersonalInfo,
    shouldReceiveSms: boolean
) => {
    return async (dispatch: Dispatch<Action>, getState: Function): Promise<void> => {
        dispatch(startLoading());
        try {
            validateUserPersonalInfo(upi, shouldReceiveSms);
            const s = getState();

            const jsonString = JSON.stringify(upi);
            const url = `${globals.API_URL}/user/${upi.user_id}/profile`;
            const responseJson = await makeAuthorizedRequest(
                url,
                'PATCH',
                dispatch,
                jsonString
            );

            if (s.login.user.user_id === upi.user_id) {
                const accountFromLocalStorage = getAccountWithToken();
                const accountUpdate = {
                    ...accountFromLocalStorage,
                    user: responseJson,
                };
                localStorage.setItem(
                    globals.TOKEN_LOCAL_STORAGE_KEY,
                    JSON.stringify(accountUpdate)
                );
            }

            dispatch(accountSaved(responseJson));
        } catch (error) {
            dispatch(saveFailed(error as Error));
        }
        dispatch(doneLoading());
    };
};

export const updateMyProfile = (userToSave: types.User) => {
    return async (dispatch: Dispatch<Action>): Promise<void> => {
        dispatch(startLoading());
        try {
            validateUser(userToSave);

            const payload = {
                first_name: userToSave.first_name,
                last_name: userToSave.last_name,
                phone_numbers: userToSave.phone_numbers,
                should_receive_sms: userToSave.should_receive_sms,
            };

            const url = `${globals.API_URL}/user/my_profile`;
            const responseJson = await makeAuthorizedRequest(
                url,
                'PATCH',
                dispatch,
                JSON.stringify(payload)
            );

            const responseUser = {
                ...responseJson.user,
                locked: userToSave.locked,
            };

            dispatch(accountSaved(responseUser));
        } catch (error) {
            dispatch(saveFailed(error as Error));
        }
        dispatch(doneLoading());
    };
};

const ensureRolesAssignedForAllLocations = (state: types.State): void => {
    for (const assignedLocation of state.updateUser.assignedLocations) {
        const assignedRole =
            state.updateUser.assignedRoleForLocationId[assignedLocation.location_id];

        if (!assignedRole || !assignedRole.role_type) {
            throw new Error(errors.MUST_ASSIGN_ROLE_AT_EACH_LOCATION);
        }
    }
};

export const createUser = (history: HistoryV4) => {
    return async (dispatch: Dispatch<any>, getState: Function) => {
        dispatch(startLoading());
        try {
            const state = getState();

            ensureRolesAssignedForAllLocations(state);

            const userToSave = { ...state.updateUser.accountToSave };
            let rolesByLocation: { [key: string]: types.RoleForLocation } = {};
            if (userToSave.is_concessionaire) {
                for (const assignedRole of Object.values(
                    state.updateUser.assignedRoleForLocationId
                )) {
                    const role = assignedRole as types.Role;
                    if (role.location?.hierarchyChildren) {
                        // add each hierarchy child location to the roles map
                        for (const leafNode of role.location.hierarchyChildren) {
                            rolesByLocation[leafNode.location_id] = {
                                role_type: role.role_type,
                                location: leafNode,
                            };
                        }
                    } else {
                        // add this leaf node location to the map
                        rolesByLocation[role.location.location_id] = role;
                    }
                }
            } else {
                // new user is not a concessionaire, no need to alter the roles by location map
                rolesByLocation = state.updateUser.assignedRoleForLocationId;
            }

            userToSave.roles = Object.values(rolesByLocation);

            const jsonString = JSON.stringify(userToSave);
            const responseJson = await makeAuthorizedRequest(
                `${globals.API_URL}/user`,
                'POST',
                dispatch,
                jsonString
            );

            dispatch(
                addNotesForNewUser(state.notes.notesForCreate, responseJson.user.user_id)
            );

            const navigate = makeCompatNavigate(history);
            navigate(`/internal/account/edit-user/${responseJson.user.user_id}`);
            dispatch(accountSaved(responseJson.user));
        } catch (error) {
            if ((error as Error)?.message?.includes('EXCEED_UPPER_LIMIT_VALUE')) {
                dispatch(saveFailed(errorForExceedMaxAttestingFM(error as Error)));
            } else {
                dispatch(saveFailed(error as Error));
            }
        }
        dispatch(doneLoading());
    };
};

// OLD KILL //
const getBestPermitLocationByDates = (
    first: types.Permit,
    second: types.Permit
): types.Permit => {
    let a = 0;
    let b = 0;
    const today = moment().utc();

    const start1 = moment.utc(first.permit_start_date);
    const ended1 = moment.utc(first.permit_end_date);
    const valid1 = start1.isBefore(today) && ended1.isAfter(today);

    const start2 = moment.utc(second.permit_start_date);
    const ended2 = moment.utc(second.permit_end_date);
    const valid2 = start2.isBefore(today) && ended2.isAfter(today);

    if (!valid1 && !valid2) {
        // Neither are valid, best Guess
        if (ended1.isBefore(today) && ended2.isBefore(today)) {
            a = today.diff(ended1, 'minutes');
            b = today.diff(ended2, 'minutes');
        } else if (start1.isAfter(today) && start2.isAfter(today)) {
            a = today.diff(start1, 'minutes');
            b = today.diff(start2, 'minutes');
        }
        return a < b ? { ...first, valid: false } : { ...second, valid: false };
    }
    if (valid1 && !valid2) {
        // First is valid, prioritize first
        return { ...first, valid: true };
    }
    if (!valid1 && valid2) {
        // Second is valid, prioritize second
        return { ...second, valid: true };
    }

    // Both valid permits (shouldn't happen), compare most recent start, then furthest out end
    a = today.diff(start1, 'minutes');
    b = today.diff(start2, 'minutes');
    if (a === b) {
        a = today.diff(ended1, 'minutes') * -1;
        b = today.diff(ended2, 'minutes') * -1;
    }
    if (a === b) {
        return { ...second, valid: true };
    }
    return a > b ? { ...first, valid: true } : { ...second, valid: true };
};
// OLD //

export const upsertConcessionaire = (history: HistoryV4) => {
    return async (dispatch: Dispatch<any>, getState: Function) => {
        const state = getState();
        dispatch(startLoading());

        // OLD SWAP 3 //
        // const rolledBack = true;
        // OLD //

        // NEW SWAP 3 //
        const rolledBack = false;
        // NEW //

        try {
            if (state.updateUser.assignedConcessionaires.length === 0) {
                dispatch(saveFailed(errors.MUST_ASSIGN_ONE_CONCESSIONAIRE));
            } else if (
                rolledBack &&
                state.updateUser.assignedConcessionaires.length > 1
            ) {
                dispatch(saveFailed(errors.MAY_ASSIGN_ONLY_ONE_CONCESSIONAIRE));
            } else if (rolledBack) {
                // OLD KILL //
                const assignedPermitForLocationId: { [key: string]: types.Permit } = {};
                const saveConcessionaire = state.updateUser.assignedConcessionaires[0];

                saveConcessionaire.permits.forEach((c: types.Permit) => {
                    if (assignedPermitForLocationId[c.location_id]) {
                        assignedPermitForLocationId[c.location_id] =
                            getBestPermitLocationByDates(
                                assignedPermitForLocationId[c.location_id],
                                c
                            );
                    } else {
                        const today = moment().utc();
                        const start = moment.utc(c.permit_start_date);
                        const ended = moment.utc(c.permit_end_date);
                        const valid = start.isBefore(today) && ended.isAfter(today);

                        if (valid) {
                            assignedPermitForLocationId[c.location_id] = { ...c, valid };
                        }
                    }
                });

                const roles = [];
                const usePermits = Object.values(assignedPermitForLocationId);
                for (const permit of usePermits as types.Permit[]) {
                    const location = {
                        location_id: permit.location_id,
                        location_name: permit.location_name,
                        location_path: permit.location_path,
                        location_state_code: permit.location_state_code,
                        location_type: permit.location_type,
                    };
                    const role = {
                        location,
                        role_type: 'ROLE_FACILITY_MANAGER',
                        concessionaire_id: saveConcessionaire.concessionaire_id,
                        permit_id: permit.permit_id,
                        permit_valid: permit.valid,
                    };
                    roles.push(role);
                }

                const userToSave = state.updateUser.accountToSave;
                userToSave.roles = roles;
                userToSave.concessionaire_id = saveConcessionaire.concessionaire_id;

                const jsonString = JSON.stringify(userToSave);
                const responseJson = await makeAuthorizedRequest(
                    `${globals.API_URL}/user`,
                    'POST',
                    dispatch,
                    jsonString
                );

                dispatch(
                    addNotesForNewUser(
                        state.notes.notesForCreate,
                        responseJson.user.user_id
                    )
                );
                dispatch(doneLoading());

                const navigate = makeCompatNavigate(history);
                navigate(`/internal/account/edit-user/${responseJson.user.user_id}`);
                dispatch(accountSaved(responseJson.user));
                // OLD //
            } else {
                const userToSave = state.updateUser.accountToSave;
                const concessionaireIds = state.updateUser.assignedConcessionaires.map(
                    (conc: types.Concessionaire) => conc.concessionaire_id
                );

                userToSave.concessionaire_ids = concessionaireIds;
                userToSave.inherits_concessionaire_roles =
                    !state.login.user.is_concessionaire;
                userToSave.roles = simplifyRolesLocationObject(userToSave.roles);

                let url = `${globals.API_URL}/user`;
                let method = 'POST';
                if (userToSave.user_id) {
                    url += `/${userToSave.user_id}`;
                    method = 'PUT';
                }

                const jsonString = JSON.stringify(userToSave);
                const responseJson = await makeAuthorizedRequest(
                    url,
                    method,
                    dispatch,
                    jsonString
                );

                dispatch(
                    addNotesForNewUser(
                        state.notes.notesForCreate,
                        responseJson.user.user_id
                    )
                );
                dispatch(doneLoading());

                const navigate = makeCompatNavigate(history);
                navigate(`/internal/account/edit-user/${responseJson.user.user_id}`);
                dispatch(accountSaved(responseJson.user));
            }
        } catch (error) {
            dispatch(saveFailed(error as Error));
        }

        dispatch(doneLoading());
    };
};

export const deleteUserSuccessful = (
    user: types.User,
    append: boolean
): types.DeleteUserAction => {
    return {
        type: types.USER_DELETED,
        deletedUser: user,
        append,
    };
};

export const deleteRoleSuccessful = (
    role: types.Role,
    user: types.User
): types.RoleAction => {
    return {
        type: types.ROLE_DELETED,
        role,
        user,
    };
};

export const deleteRoleFailed = (error: Error): types.ErrorAction => {
    return {
        type: types.ROLE_DELETE_FAILED,
        error,
    };
};

const deleteUserWithNote = async (
    user: types.User,
    note: string,
    dispatch: Dispatch<Action>,
    append: boolean
): Promise<void> => {
    const addNoteURL = `${globals.API_URL}/user/${user.user_id}/notes`;
    await makeAuthorizedRequest(addNoteURL, 'POST', dispatch, note);

    const deleteURL = `${globals.API_URL}/user/${user.user_id}`;
    await makeAuthorizedRequest(deleteURL, 'DELETE', dispatch);

    dispatch(deleteUserSuccessful(user, append));
};

export const deleteUserFailed = (error: Error): types.ErrorAction => {
    return {
        type: types.USER_DELETE_FAILED,
        error,
    };
};

export const deleteUsers = (users: types.User[], noteText: string) => {
    return async (dispatch: Dispatch<Action>, getState: Function): Promise<void> => {
        dispatch(startLoading('Deleting...'));

        try {
            const state = getState();
            const createdByUserId = state.login.user.user_id;

            const deletionNote = JSON.stringify({
                text: noteText,
                reason: 'DELETING',
                created_at: new Date(),
                created_by: createdByUserId,
            });

            const deletePromises = [];

            for (const user of users) {
                const promise = deleteUserWithNote(
                    user,
                    deletionNote,
                    dispatch,
                    users.length > 1
                );
                deletePromises.push(promise);
            }

            await Promise.all(deletePromises);
        } catch (error) {
            dispatch(deleteUserFailed(error as Error));
        }

        dispatch(doneLoading());
    };
};

const makeDeleteRoleRequest = async (
    userIdToUpdate: string,
    role: types.Role,
    dispatch: Dispatch<Action>
): Promise<any> => {
    const url = `${globals.API_URL}/user/${userIdToUpdate}/role`;
    const body = JSON.stringify(role);
    return makeAuthorizedRequest(url, 'DELETE', dispatch, body);
};

export const deleteRole = (role: types.Role) => {
    return async (dispatch: Dispatch<Action>, getState: Function): Promise<void> => {
        dispatch(startLoading());
        try {
            const state = getState();
            const userIdToUpdate = state.updateUser.accountToSave.user_id;

            const response = await makeDeleteRoleRequest(userIdToUpdate, role, dispatch);
            dispatch(deleteRoleSuccessful(role, response.user));
        } catch (error) {
            dispatch(deleteRoleFailed(error as Error));
        }
        dispatch(doneLoading());
    };
};

export const settingDefaultRoleSuccessful = (
    updatedUser: types.User
): types.UserAction => {
    return {
        type: types.SETTING_DEFAULT_ROLE_SUCCESSFUL,
        updatedUser,
    };
};

export const setDefaultRole = (role: types.Role) => {
    return async (dispatch: Dispatch<Action>, getState: Function): Promise<void> => {
        dispatch(startLoading());

        try {
            const state = getState();
            const userId = state.login.user.user_id;

            const url = `${globals.API_URL}/user/${userId}/role/${role.location.location_id}/default`;
            const updateData = JSON.stringify(role);

            const response = await makeAuthorizedRequest(url, 'PUT', updateData);
            dispatch(settingDefaultRoleSuccessful(response.user));
        } catch (error) {
            dispatch(saveFailed(error as Error));
        }

        dispatch(doneLoading());
    };
};

const updatingRolesSuccessful = (updatedUser: types.User): types.UserAction => {
    return {
        type: types.UPDATING_ROLES_SUCCESSFUL,
        user: updatedUser,
    };
};

export const updateRoles = (history: HistoryV4) => {
    return async (dispatch: Dispatch<any>, getState: Function): Promise<void> => {
        dispatch(startLoading());
        try {
            const state = getState();

            ensureRolesAssignedForAllLocations(state);
            const userIdToUpdate = state.updateUser.accountToSave.user_id;

            const currentRoles = state.updateUser.accountToSave.roles;
            const updatedAssignedRolesForLocationIdMap =
                state.updateUser.assignedRoleForLocationId;

            const unchangedRolesToLocationIDMap: { [key: string]: types.Role } = {};
            const updatedRolesToLocationIDMap: { [key: string]: types.Role } = {};
            let updatedNumberOfRoles = currentRoles.length;
            let updatedSelectedRole = null;

            const rolesToDelete = [];
            const rolesToUpdate = [];
            const rolesToAdd = [];

            // Iterate the existing roles find roles to update or delete
            for (const currentRole of currentRoles) {
                const updatedRole =
                    updatedAssignedRolesForLocationIdMap[
                        currentRole.location.location_id
                    ];

                // If the role was removed, it should be deleted
                if (!updatedRole) {
                    rolesToDelete.push(currentRole);
                    updatedNumberOfRoles -= 1;
                } else if (
                    updatedRole.role_type !== currentRole.role_type ||
                    updatedRole.can_attest_to_peers !== currentRole.can_attest_to_peers
                ) {
                    rolesToUpdate.push(updatedRole);
                    updatedRolesToLocationIDMap[updatedRole.location.location_id] =
                        updatedRole;

                    updatedNumberOfRoles += 1;
                } else {
                    unchangedRolesToLocationIDMap[currentRole.location.location_id] =
                        currentRole;
                }
            }

            // Iterate the updatedRoles and find all there were changed
            for (const updatedAssignedRole of Object.values(
                updatedAssignedRolesForLocationIdMap
            )) {
                // Need to add if the role after updates has not already been identified as being unchanged or changed or deleted
                const shouldAdd =
                    !unchangedRolesToLocationIDMap[
                        (updatedAssignedRole as types.Role).location.location_id
                    ] &&
                    !updatedRolesToLocationIDMap[
                        (updatedAssignedRole as types.Role).location.location_id
                    ];

                if (shouldAdd) {
                    rolesToAdd.push(updatedAssignedRole);
                    updatedNumberOfRoles += 1;
                }
            }

            if (updatedNumberOfRoles <= 0) {
                throw new Error(errors.MUST_ASSIGN_ROLE_AT_EACH_LOCATION);
            }

            const rolesUpdates: {
                roles_upsert: types.SimplifiedRole[];
                roles_delete: types.SimplifiedRole[];
            } = {
                roles_upsert: [],
                roles_delete: [],
            };

            const simplifyRole = (r: types.Role) => {
                return {
                    role_type: r.role_type,
                    can_attest_to_peers: r.can_attest_to_peers,
                    location: {
                        location_id: r.location?.location_id,
                        location_type: r.location?.location_type,
                    },
                    location_id: r.location_id,
                    location_type: r.location_type,
                };
            };

            const compareLoc = state.selectedLocationRole.role.location;
            for (const roleToUpdate of rolesToUpdate) {
                rolesUpdates.roles_upsert.push(simplifyRole(roleToUpdate));
                if (
                    compareLoc.location_id === roleToUpdate.location.location_id &&
                    compareLoc.location_type === roleToUpdate.location.location_type
                ) {
                    updatedSelectedRole = roleToUpdate;
                }
            }

            for (const roleToAdd of rolesToAdd) {
                rolesUpdates.roles_upsert.push(simplifyRole(roleToAdd as types.Role));
            }

            for (const roleToDelete of rolesToDelete) {
                rolesUpdates.roles_delete.push(simplifyRole(roleToDelete));
            }

            if (
                rolesUpdates.roles_delete.length === 0 &&
                rolesUpdates.roles_upsert.length === 0
            ) {
                throw new Error(errors.NO_ROLES_TO_UPDATE);
            }

            const url = `${globals.API_URL}/user/${userIdToUpdate}/roles`;
            const updateData = JSON.stringify(rolesUpdates);

            const response = await makeAuthorizedRequest(
                url,
                'PUT',
                dispatch,
                updateData
            );

            let navUrl = `/internal/account/edit-user/${state.updateUser.accountToSave.user_id}`;
            if (state.login.user.user_id === userIdToUpdate) {
                navUrl = '/internal/account/my-profile';
                const accountFromLocalStorage = getAccountWithToken();
                const accountUpdate = { ...accountFromLocalStorage, user: response.user };
                localStorage.setItem(
                    globals.TOKEN_LOCAL_STORAGE_KEY,
                    JSON.stringify(accountUpdate)
                );

                if (updatedSelectedRole) {
                    dispatch(
                        getDomainsForLocation(updatedSelectedRole.location, history)
                    );
                }
            }

            const navigate = makeCompatNavigate(history);
            navigate(navUrl);
            dispatch(updatingRolesSuccessful(response.user));
        } catch (error) {
            if ((error as Error)?.message?.includes('EXCEED_UPPER_LIMIT_VALUE')) {
                dispatch(saveFailed(errorForExceedMaxAttestingFM(error as Error)));
            } else {
                dispatch(saveFailed(error as Error));
            }
        }
        dispatch(doneLoading());
    };
};

const requestConfirmStatus = (error?: Error, ttl?: string): types.ErrorAction => {
    return {
        type: types.REQUEST_CONFIRM_STATUS,
        ttl,
        error,
    };
};

export const resendConfirmation = (user: types.User) => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(startLoading());
        try {
            const url = `${globals.API_URL}/resend-confirm-email`;
            const body = JSON.stringify({ user_id: user.user_id });

            await makeAuthorizedRequest(url, 'POST', dispatch, body);

            dispatch(requestConfirmStatus(undefined, 'success'));
        } catch (json) {
            if ((json as types.TTL).time_to_live) {
                dispatch(
                    requestConfirmStatus(
                        (json as types.TTL).error,
                        (json as types.TTL).time_to_live
                    )
                );
            } else {
                dispatch(requestConfirmStatus(json as Error));
            }
        }
        dispatch(doneLoading());
    };
};

export const resetConfirmStatus = (): Action => {
    return {
        type: types.RESET_CONFIRM_STATUS,
    };
};

export const resetResetStatus = (): Action => {
    return {
        type: types.RESET_RESET_STATUS,
    };
};

export const userMFAEnrollmentStatus = (user: types.User): string => {
    const dateFormat = (d: string) => moment(d).format('MM/DD/YYYY');

    if (user?.mfa_data?.bypass_at) {
        return `Allowed bypassed (${dateFormat(user?.mfa_data?.bypass_at)})`;
    }

    if (user?.mfa_data?.opt_out_at) {
        return `Opted Out (${dateFormat(user?.mfa_data?.opt_out_at)})`;
    }

    if (user?.mfa_data?.verified_at) {
        return `Enrolled (${dateFormat(user?.mfa_data?.verified_at)})`;
    }

    return 'Not Enrolled';
};

export function setMFABypass(userID: string) {
    return async (
        dispatch: Dispatch<types.MfaAction | Action>,
        getState: Function
    ): Promise<Error | null> => {
        dispatch(startLoading());
        let resp: Error | null;
        try {
            const url = `${globals.API_URL}/mfa/bypass/${userID}`;
            await makeAuthorizedRequest(url, 'PATCH', dispatch, null);
            resp = null;

            const state = getState();
            const accountToSave = { ...state.updateUser.accountToSave };
            accountToSave.mfa_data = {
                bypass_at: moment().format(),
                opt_out_at: null,
                verified_at: null,
            };
            dispatch({
                accountToSave,
                type: types.EDITING_USER,
                readOnly: true,
            });
        } catch (e) {
            resp = e as Error;
        }
        dispatch(doneLoading());
        return resp;
    };
}

export const resetMFA = (userID: string) => {
    return async (
        dispatch: Dispatch<types.MfaAction | Action>,
        getState: Function
    ): Promise<void> => {
        dispatch(startLoading());
        try {
            await makeAuthorizedRequest(
                `${globals.API_URL}/mfa/${userID}`,
                'DELETE',
                dispatch,
                null
            );
            const state = getState();
            const accountToSave = { ...state.updateUser.accountToSave };
            accountToSave.mfa_data = {
                bypass_at: null,
                opt_out_at: null,
                verified_at: null,
            };
            dispatch({
                accountToSave,
                readOnly: true,
                type: types.EDITING_USER,
            });
        } catch (error) {
            dispatch(saveFailed(error as Error));
        }
        dispatch(doneLoading());
    };
};

export function forceLogout(userID: string) {
    return async (
        dispatch: Dispatch<types.MfaAction | Action>
    ): Promise<Error | null> => {
        dispatch(startLoading());
        let resp: Error | null = null;
        try {
            const url = `${globals.API_URL}/user/${userID}/logout`;
            await makeAuthorizedRequest(url, 'PUT', dispatch, null);
        } catch (e) {
            resp = e as Error;
        }
        dispatch(doneLoading());
        return resp;
    };
}

export function forceResetPassword(userID: string) {
    return async (
        dispatch: Dispatch<types.MfaAction | Action>
    ): Promise<Error | null> => {
        dispatch(startLoading());
        let resp: Error | null = null;
        try {
            const url = `${globals.API_URL}/user/${userID}/reset-password`;
            await makeAuthorizedRequest(url, 'PUT', dispatch, null);
        } catch (e) {
            resp = e as Error;
        }
        dispatch(doneLoading());
        return resp;
    };
}

export function unlinkLoginDotGov() {
    return async (dispatch: Dispatch<Action>): Promise<Error | null> => {
        dispatch(startLoading());
        let resp: Error | null = null;
        try {
            const url = `${globals.API_URL}/oidc`;
            const body = JSON.stringify({
                provider: AvailableOidcProviders.loginDotGov,
            });

            await makeAuthorizedRequest(url, 'DELETE', dispatch, body);
        } catch (e) {
            resp = e as Error;
        }
        dispatch(doneLoading());
        return resp;
    };
}

export function unlinkLoginDotGovTarget(userID: string) {
    return async (dispatch: Dispatch<Action>): Promise<Error | null> => {
        dispatch(startLoading());
        let resp: Error | null = null;
        try {
            const url = `${globals.API_URL}/oidc/${userID}`;
            const body = JSON.stringify({
                provider: AvailableOidcProviders.loginDotGov,
            });

            await makeAuthorizedRequest(url, 'DELETE', dispatch, body);
        } catch (e) {
            resp = e as Error;
        }
        dispatch(doneLoading());
        return resp;
    };
}
