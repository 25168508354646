/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, useFlags, Alert } from 'sarsaparilla';
import { errorForResendConfirmationEmail } from '../../utilities/errorMessages';

const propTypes = {
    accountToSave: PropTypes.shape({
        locked: PropTypes.bool,
        is_email_confirmed: PropTypes.bool,
        user_id: PropTypes.string,
        enforce_effective_dates: PropTypes.bool,
        effective_start_at: PropTypes.string,
        effective_end_at: PropTypes.string,
    }),
    creating: PropTypes.bool,
    requestConfirmResponse: PropTypes.object,
    resendConfirmation: PropTypes.func.isRequired,
    pendingConfirmation: PropTypes.bool,
};

export default function ResendConfirmEmail({
    accountToSave,
    creating,
    requestConfirmResponse,
    resendConfirmation,
    pendingConfirmation,
}) {
    const [showAlert, setShowAlert] = useState(false);
    const { enableIaEffectiveDates } = useFlags();

    let resendMsg =
        'If a user did not receive a confirmation email upon account creation, or has since lost that email, you may use the "Resend Confirmation" button to send another.';

    const isError = requestConfirmResponse && requestConfirmResponse.error;
    const isSuccess = !isError && requestConfirmResponse;

    if (showAlert) {
        if (isError) {
            resendMsg = errorForResendConfirmationEmail(
                requestConfirmResponse.error,
                requestConfirmResponse.ttl
            );
        } else {
            resendMsg = 'Confirmation email successfully sent.';
        }
    }

    const alertType = isError ? 'error' : isSuccess ? 'success' : undefined;

    const enforceEffectiveDates =
        enableIaEffectiveDates && accountToSave?.enforce_effective_dates;
    const accountInactive = moment().isBefore(accountToSave?.effective_start_at);
    const accountExpired = moment().isAfter(accountToSave?.effective_end_at);
    const isDisabled = enforceEffectiveDates && (accountInactive || accountExpired);

    useEffect(() => {
        if (isSuccess) {
            setShowAlert(true);
        }
    }, [isSuccess]);

    if (
        (!accountToSave || creating || accountToSave.is_email_confirmed) &&
        !pendingConfirmation
    ) {
        return null;
    }

    return (
        <div className="update-user-options">
            <div className="update-user-options-controls">
                <Button
                    className="update-user-options-button"
                    appearance="tertiary"
                    onClick={() => resendConfirmation(accountToSave)}
                    disabled={isDisabled}
                >
                    Resend Confirmation
                </Button>

                {showAlert ? (
                    <Alert
                        showCloseButton
                        shouldFocusOnMount
                        type={alertType}
                        className="update-user-options-message"
                        onCloseButtonClick={() => setShowAlert(false)}
                    >
                        {resendMsg}
                    </Alert>
                ) : (
                    <div className="update-user-options-message">{resendMsg}</div>
                )}
            </div>
        </div>
    );
}

ResendConfirmEmail.propTypes = propTypes;
