/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

export const initialState = {
    managedCooperators: [],
    cooperatorEmployees: [],
    loadingManagedCooperatorsError: false,
    loadingCooperatorEmployeesError: false,
    savingManagedCooperatorsError: false,
    savingCooperatorEmployeesError: false,
    deletingManagedCooperatorsError: false,
    deletingCooperatorEmployeesError: false,
};

const manageCooperators = (state = initialState, action) => {
    switch (action.type) {
        // LOAD
        case types.RELOAD_MANAGE_COOPERATOR_ERRORS:
            return {
                ...state,
                loadingManagedCooperatorsError: false,
                loadingCooperatorEmployeesError: false,
                savingManagedCooperatorsError: false,
                savingCooperatorEmployeesError: false,
                deletingManagedCooperatorsError: false,
                deletingCooperatorEmployeesError: false,
            };
        case types.LOADING_MANAGED_COOPERATORS_SUCCESSFUL:
            return {
                ...state,
                managedCooperators: action.cooperators,
                loadingManagedCooperatorsError: false,
            };
        case types.LOADING_MANAGED_COOPERATORS_FAILED:
            return {
                ...state,
                managedCooperators: [],
                loadingManagedCooperatorsError: true,
            };
        case types.LOADING_COOPERATOR_EMPLOYEES_SUCCESSFUL:
            return {
                ...state,
                cooperatorEmployees: action.employees,
                loadingCooperatorEmployeesError: false,
            };
        case types.LOADING_COOPERATOR_EMPLOYEES_FAILED:
            return {
                ...state,
                cooperatorEmployees: [],
                loadingCooperatorEmployeesError: true,
            };

        // SAVE
        case types.SAVING_COOPERATOR_SUCCESSFUL: {
            const indexToUpdateCoop = state.managedCooperators
                .map((coop) => coop.cooperator_id)
                .indexOf(action.cooperator.cooperator_id);

            let coops;
            if (indexToUpdateCoop > -1) {
                coops = [
                    ...state.managedCooperators.slice(0, indexToUpdateCoop),
                    ...[action.cooperator],
                    ...state.managedCooperators.slice(indexToUpdateCoop + 1),
                ];
            } else {
                coops = [...[action.cooperator], ...state.managedCooperators];
            }
            return { ...state, managedCooperators: coops };
        }
        case types.SAVING_MANAGED_COOPERATORS_SUCCESSFUL:
            return { ...state, savingManagedCooperatorsError: false };
        case types.SAVING_MANAGED_COOPERATORS_FAILED:
            return { ...state, savingManagedCooperatorsError: true };

        case types.SAVING_EMPLOYEE_SUCCESSFUL: {
            const indexToUpdateEmp = state.cooperatorEmployees
                .map((emp) => emp.user_id)
                .indexOf(action.employee.user_id);

            let emps;
            if (indexToUpdateEmp > -1) {
                emps = [
                    ...state.cooperatorEmployees.slice(0, indexToUpdateEmp),
                    ...[action.employee],
                    ...state.cooperatorEmployees.slice(indexToUpdateEmp + 1),
                ];
            } else {
                emps = [...[action.employee], ...state.cooperatorEmployees];
            }
            return { ...state, cooperatorEmployees: emps };
        }
        case types.SAVING_COOPERATOR_EMPLOYEES_SUCCESSFUL:
            return { ...state, savingCooperatorEmployeesError: false };
        case types.SAVING_COOPERATOR_EMPLOYEES_FAILED:
            return { ...state, savingCooperatorEmployeesError: action.error };

        // DELETE
        case types.DELETING_COOPERATOR_SUCCESSFUL: {
            const indexToDeleteCoop = state.managedCooperators.indexOf(action.cooperator);
            return {
                ...state,
                managedCooperators: [
                    ...state.managedCooperators.slice(0, indexToDeleteCoop),
                    ...state.managedCooperators.slice(indexToDeleteCoop + 1),
                ],
            };
        }
        case types.DELETING_MANAGED_COOPERATORS_SUCCESSFUL:
            return { ...state, deletingManagedCooperatorsError: false };
        case types.DELETING_MANAGED_COOPERATORS_FAILED:
            return { ...state, deletingManagedCooperatorsError: true };

        case types.DELETING_EMPLOYEE_SUCCESSFUL: {
            const indexToDeleteEmp = state.cooperatorEmployees.indexOf(action.employee);
            return {
                ...state,
                cooperatorEmployees: [
                    ...state.cooperatorEmployees.slice(0, indexToDeleteEmp),
                    ...state.cooperatorEmployees.slice(indexToDeleteEmp + 1),
                ],
            };
        }
        case types.DELETING_COOPERATOR_EMPLOYEES_SUCCESSFUL:
            return { ...state, deletingCooperatorEmployeesError: false };
        case types.DELETING_COOPERATOR_EMPLOYEES_FAILED:
            return { ...state, deletingCooperatorEmployeesError: true };

        // DEFAULT
        default:
            return state;
    }
};

export default manageCooperators;
