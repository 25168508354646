/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';
import { CompatRouter } from 'react-router-dom-v5-compat';

import { QueryRouter, InternalLaunchDarklyProvider } from 'sarsaparilla';

import App from './App';
import { store } from './store';
import './index.scss';

const persistor = persistStore(store);

const root = createRoot(document.getElementById('recApp'));

root.render(
    <Provider store={store}>
        <InternalLaunchDarklyProvider>
            <PersistGate persistor={persistor} loading={<div />}>
                <QueryRouter>
                    <CompatRouter>
                        <App />
                    </CompatRouter>
                </QueryRouter>
            </PersistGate>
        </InternalLaunchDarklyProvider>
    </Provider>
);

// cSpell:ignore persistor
