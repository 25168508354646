/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureFlag, TYPES } from 'sarsaparilla';
import SiteWrapper from './SiteWrapper';
import MyProfile from '../components/MyProfile/MyProfile';
import MyProfilePanel from '../components/MyProfile/MyProfilePanel';
import PageHeader from '../components/PageHeader';
import MfaEnrollmentWizardModal from '../components/MfaEnrollmentWizardModal';
import { editMyProfile } from '../actions/updateUser';

type MyProfilePageProps = {
    history: TYPES.HISTORY;
    location: TYPES.LOCATION;
    match: TYPES.MATCH;
};

function MyProfilePage({ history, location, match }: MyProfilePageProps) {
    const dispatch = useDispatch();
    const [isMfaModalOpen, setIsMfaModalOpen] = useState<boolean>(false);

    const toggleIsMfaModalOpen = () => {
        setIsMfaModalOpen(!isMfaModalOpen);
    };

    const LegacyMyProfile = (
        <MyProfile
            history={history}
            location={location}
            match={match}
            toggleIsMfaModalOpen={toggleIsMfaModalOpen}
        />
    );

    const MyProfileV2 = (
        <MyProfilePanel
            history={history}
            location={location}
            match={match}
            toggleIsMfaModalOpen={toggleIsMfaModalOpen}
            dispatch={dispatch}
        />
    );

    useEffect(() => {
        editMyProfile(history);
    }, [history]);

    return (
        <SiteWrapper>
            <div className="page-title">
                <PageHeader title="My Profile" />
            </div>
            <div id="UserManagement" className="page-content wrapper">
                <FeatureFlag flag="iaEditUserV2" fallback={LegacyMyProfile}>
                    {MyProfileV2}
                </FeatureFlag>
                <MfaEnrollmentWizardModal
                    canSkip={false}
                    hideStartOverButton
                    isOpen={isMfaModalOpen}
                    onClose={toggleIsMfaModalOpen}
                />
            </div>
        </SiteWrapper>
    );
}

export default MyProfilePage;
