/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { concessionaireHierarchyTypes } from '../constants/types';
import * as globals from '../constants/globals';
import { makeAuthorizedRequest } from './login';
import { concessionaireHierarchySelector } from '../reducers/concessionaireHierarchy';

function hierarchyStart(data) {
    return { type: concessionaireHierarchyTypes.START, data };
}

function hierarchyAdd(data) {
    return { type: concessionaireHierarchyTypes.ADD, data };
}

function hierarchyError(error) {
    return { type: concessionaireHierarchyTypes.ERROR, error };
}

function hierarchyEnd() {
    return { type: concessionaireHierarchyTypes.END };
}

async function fetchLocation(locationId, dispatch) {
    const apiUrl = globals.API_INVENTORY_URL;
    const endpoint = `${apiUrl}/location/${encodeURIComponent(locationId.location_type)}/${encodeURIComponent(
        locationId.location_id
    )}`;
    const locationResult = await makeAuthorizedRequest(endpoint, 'GET', dispatch);
    return locationResult.location;
}

function fillHierarchyLocation(locationId, children) {
    return async (dispatch) => {
        try {
            const location = await fetchLocation(locationId, dispatch);
            location.id = location.location_id;
            location.parentId = location.location_parent_id;
            location.isHierarchyFill = true;
            location.hierarchyChildren = children;
            dispatch(hierarchyAdd(location));
        } catch (err) {
            dispatch(hierarchyError(err));
        }
    };
}

export function initHierarchy({ initDataSource }) {
    return async (dispatch, getState) => {
        const { loading, loaded } = concessionaireHierarchySelector(getState());

        if (loading || loaded) {
            return;
        }
        const copyDataSource = initDataSource.map((location) => ({
            ...location,
            id: location.location_id,
            parentId: location.location_parent_id,
        }));
        dispatch(hierarchyStart(copyDataSource));

        const parentsToFetchMap = {};
        copyDataSource.forEach((location) => {
            const locationIds = location.location_path;
            locationIds.forEach((locationId) => {
                if (locationId.location_id === location.location_id) {
                    // Skip fetch for this location, already have it.
                    return;
                }
                if (!parentsToFetchMap[locationId.location_id]) {
                    parentsToFetchMap[locationId.location_id] = {
                        id: locationId,
                        children: [location],
                    };
                } else {
                    parentsToFetchMap[locationId.location_id].children.push(location);
                }
            });
        });
        const parentFetches = Object.keys(parentsToFetchMap).map((key) => {
            const { id, children } = parentsToFetchMap[key];
            return dispatch(fillHierarchyLocation(id, children));
        });
        await Promise.all(parentFetches);
        dispatch(hierarchyEnd());
    };
}
