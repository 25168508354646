/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as globals from '../constants/globals';
import * as roles from '../utilities/roles';
import { makeAuthorizedRequest } from './login';

export const getUsersByAgencyCanAttestPeers = (agencyID) => async (dispatch) => {
    const getUsersByAgencyAttestation = () =>
        `${globals.API_URL}/attestations/users/${agencyID}`;
    try {
        return await makeAuthorizedRequest(
            getUsersByAgencyAttestation(),
            'GET',
            dispatch
        );
    } catch (e) {
        if (e.message === 'USER_ACTION_FORBIDDEN')
            throw new Error('Not authorized to view this page');
        throw e;
    }
};

const postAttestationEndpoint = () => `${globals.API_URL}/attestations`;

export const attestUsers = (location, attestedUsers) => async (dispatch) => {
    try {
        const payload = {
            location: {
                location_id: location.location_id,
                location_type: location.location_type,
            },
            role_type: roles.FACILITY_MANAGER,
            attested_user_ids: attestedUsers.map((user) => user.user_id),
            only_staff_management: true,
        };
        return await makeAuthorizedRequest(
            postAttestationEndpoint(),
            'POST',
            dispatch,
            payload
        );
    } catch (e) {
        if (e.response.status === 404) return [];
        throw e.response.statusText;
    }
};
