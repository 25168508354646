/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';
import { parseUrlForLocation } from '../utilities/locations';
import { fetchAlerts } from './login';
import {
    domainToTileMap,
    generateTilePath,
    encodeLocTypePath,
} from '../utilities/launchTiles';
import { makeCompatNavigate } from '../utilities/compatNavigateUtil';

export const setSelectedLocationRole = (role, domainsByLocationId, history) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_SELECTED_LOCATION,
            role,
        });

        const location = role ? role.location : null;
        if (location && domainsByLocationId) {
            const oldLoc = parseUrlForLocation();
            const oldPathForMap = window.location.pathname
                .replace(/\/permit\//, '/permits/')
                .replace(/\/ticket\//, '/tickets/'); // TODO: align all routes to use generic location_type and don't hard code this for any outliers
            let newPath = oldPathForMap;
            let navTo;

            if (oldLoc) {
                Object.keys(oldLoc).forEach((key) => {
                    newPath = newPath.replace(
                        encodeLocTypePath(oldLoc[key]),
                        `\${${key}}`
                    );
                });

                domainsByLocationId[
                    `${location.location_id}_${location.location_type}`
                ].domains.forEach((domain) => {
                    const tile = domainToTileMap[domain];
                    if (tile && tile.path === newPath) {
                        navTo = generateTilePath(tile, location);
                    }
                });

                const isEqual =
                    oldLoc.location_type === location.location_type &&
                    oldLoc.location_id === location.location_id;
                if (navTo && navTo !== window.location.pathname) {
                    const navigate = makeCompatNavigate(history);
                    navigate(navTo);
                } else if (!navTo && !isEqual) {
                    window.location.assign('/internal/account/hub');
                }
            }
        }

        dispatch(fetchAlerts(location));
    };
};
