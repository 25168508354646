/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * ***********************************************************
 * TEMPORARY - THIS WILL BE IN THE NEXT RELEASE OF SARSA
 * ***********************************************************
 */

import './VerificationCodeField.scss';

import * as React from 'react';
import cx from 'classnames';

import { InputError } from 'sarsaparilla';

export type ValidationState = {
    isInvalid: boolean;
    errorText: string | null | undefined;
    inputId: string;
    id: string;
    label: string;
};

interface VerificationCodeFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    /** Label is required for accessibility, but is not visible */
    label: string;
    /** Disabled the input */
    isDisabled?: boolean;
    /** Optional class added to outer container */
    className?: string;
    /** Number of characters in the code. Default is 6 */
    length?: number;
    /** Regex used to ensure invalid characters are not typed */
    validChars?: string;
    /** Callback fired when input changes. An event is NOT returned; only the value as a string */
    onChange?: (value: string) => void;
    /** Callback fired when input is complete. */
    onComplete?: (value: string, validation: ValidationState) => void;
    /** Focuses the input on mount */
    autoFocus?: boolean;
    /** If the input is required. Unlike most inputs, defaults to true */
    isRequired?: boolean;
    /** Adds invalid styling and aria attributes */
    isInvalid?: boolean;
    /** Displays error message and adds invalid styling and aria attributes */
    errorMessage?: string;
}

export interface VerificationCodeFieldRef {
    validate: () => ValidationState | null;
    getValue: () => string;
}

function VerificationCodeFieldInner(
    props: VerificationCodeFieldProps,
    forwardedRef: React.ForwardedRef<VerificationCodeFieldRef>
) {
    const {
        label,
        isDisabled,
        className,
        length = 6,
        value,
        defaultValue,
        validChars = '0-9',
        onChange: onChangeProp,
        onComplete,
        autoFocus,
        id: idProp,
        isRequired = true,
        isInvalid: isInvalidProp,
        errorMessage,
        ...rest
    } = props;

    const inputRef = React.useRef<HTMLInputElement>(null);
    const computedId = React.useId();
    const id = idProp ?? computedId;
    const [localValue, setLocalValue] = React.useState<string>(
        String(value ?? defaultValue ?? '')
    );
    const [isActive, setActive] = React.useState(false);
    const [validationState, setValidationState] = React.useState<ValidationState>({
        isInvalid: isInvalidProp || Boolean(errorMessage) || false,
        errorText: errorMessage ?? '',
        inputId: id,
        id,
        label,
    });

    const charWidth = 56;
    const gapWidth = 8;

    React.useLayoutEffect(() => {
        if (autoFocus) {
            inputRef.current?.focus();
        }
    }, [autoFocus]);

    React.useEffect(() => {
        if (isDisabled) {
            setActive(false);
        }
    }, [isDisabled]);

    const getValue = () => {
        return String(value ?? localValue);
    };

    const validateValue = (newValue?: string) => {
        const currentValue = newValue ?? getValue();
        const isWrongLength = currentValue.length !== length;
        const hasInvalidChars = RegExp(`[^${validChars}]`).test(currentValue);
        const isEmpty = !currentValue;

        const errorText =
            isRequired && isEmpty
                ? `${label} is required`
                : isWrongLength
                  ? `Code must be ${length} digits long`
                  : hasInvalidChars
                    ? `Code has invalid characters`
                    : undefined;

        const validation: ValidationState = {
            id,
            inputId: id,
            isInvalid: isWrongLength || hasInvalidChars,
            errorText,
            label,
        };

        setValidationState(validation);
        return validation;
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
            // prevent changing cursor position
            event.preventDefault();
        }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newInputVal = event.target.value.replace(/\s/g, '');

        if (RegExp(`^[${validChars}]{0,${length}}$`).test(newInputVal)) {
            setLocalValue(newInputVal);
            onChangeProp?.(newInputVal);

            if (newInputVal.length === length && onComplete) {
                // Using setTimeout to force the setLocalValue state change to finish
                // before we call for validation and onComplete. Otherwise it might
                // trigger an error state because the last onChange event didn't finish
                window.setTimeout(() => {
                    const validation = validateValue(newInputVal);
                    onComplete(newInputVal, validation);
                }, 0);
            }
        }
    };

    const isCharacterInactive = (i: number) => getValue().length < i;

    const isCharacterSelected = (i: number) => {
        const currentValue = getValue();
        return (
            (currentValue.length === i ||
                (currentValue.length === i + 1 && length === i + 1)) &&
            isActive
        );
    };

    // This allows us to expose the internal validate function to the parent component via a ref
    React.useImperativeHandle(forwardedRef, () => {
        return {
            validate: () => {
                const validation = validateValue();
                return validation;
            },
            getValue: () => {
                return getValue();
            },
        };
    });

    const errorInputId = `${id}-error`;
    const isInvalid =
        isInvalidProp || validationState?.isInvalid || Boolean(errorMessage);

    return (
        <div
            data-component="VerificationCodeField"
            data-testid="VerificationCodeField"
            className={className}
            style={{
                width: `${charWidth * length + gapWidth * (length - 1)}px`,
            }}
        >
            <div className="input-wrap">
                <input
                    ref={inputRef}
                    id={id}
                    aria-label={label}
                    aria-required={isRequired}
                    aria-describedby={errorInputId}
                    aria-invalid={isInvalid}
                    spellCheck={false}
                    autoComplete="off"
                    className="input"
                    disabled={isDisabled}
                    onKeyDown={onKeyDown}
                    value={getValue()}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    onSelect={(e) => {
                        const val = e.currentTarget.value;
                        e.currentTarget.setSelectionRange(val.length, val.length);
                    }}
                    onFocus={() => {
                        setActive(true);
                    }}
                    onBlur={() => {
                        setActive(false);
                    }}
                    type="text"
                    maxLength={length}
                    {...rest}
                />
                {[...Array(length)].map((_, index) => {
                    return (
                        <div
                            key={index}
                            role="presentation"
                            onClick={() => {
                                inputRef.current?.focus();
                            }}
                            style={{ width: `${charWidth}px` }}
                            className={cx('char', {
                                focus: isCharacterSelected(index),
                                inactive: isCharacterInactive(index),
                                disabled: isDisabled,
                                invalid: isInvalid,
                            })}
                        >
                            {getValue()[index] ? getValue()[index] : null}
                        </div>
                    );
                })}
            </div>
            <InputError id={errorInputId}>
                {errorMessage ?? validationState?.errorText}
            </InputError>
        </div>
    );
}

export const VerificationCodeField = React.forwardRef(VerificationCodeFieldInner);
