/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Button, Heading, Text } from 'sarsaparilla';

type MfaEnrollmentIntroductionProps = {
    canSkip: boolean;
    hideStartOverButton?: boolean;
    isLoading: boolean;
    onOptOut: () => void;
    nextStep: () => void;
    startOver: () => void;
};

function MfaEnrollmentIntroduction({
    canSkip,
    hideStartOverButton,
    isLoading,
    onOptOut,
    nextStep,
    startOver,
}: MfaEnrollmentIntroductionProps) {
    return (
        <>
            <Heading
                headingLevel={1}
                appearance="h4"
                headingAlign="center"
                className="mb-3"
            >
                Set Up Multi-Factor Authentication
            </Heading>

            <Text size="md" className="mb-3" align="left">
                Multi-factor authentication will be available for use starting March 1st,
                2025 to protect your {process.env.SITE_NAME} account with an extra layer
                of security, based on federal mandate.
            </Text>

            <Text size="md" className="mb-3" align="left">
                <b>Step 1.</b> To get started, download and install your agency-mandated
                authentication application for your phone, tablet, or desktop. When you
                have completed the download, click Next to proceed to the remaining steps.
            </Text>

            {canSkip && (
                <div className="mb-3 center">
                    <Button
                        appearance="link"
                        onClick={onOptOut}
                        isDisabled={isLoading}
                        gaTrackingId="261677614215"
                    >
                        Skip for now
                    </Button>
                </div>
            )}
            <div className="mb-3 center">
                <Button
                    onClick={nextStep}
                    isLoading={isLoading}
                    gaTrackingId="261677614215"
                    size="lg"
                >
                    Next
                </Button>
            </div>

            {!hideStartOverButton && (
                <Text tag="div" className="center mt-5">
                    <Button
                        href="/internal/account/login"
                        appearance="link"
                        onClick={startOver}
                        rel="noreferrer"
                        gaTrackingId="261677614215"
                    >
                        Start over
                    </Button>
                    <span>&nbsp;</span>
                    <span>and return to login page</span>
                </Text>
            )}
        </>
    );
}

export default MfaEnrollmentIntroduction;
