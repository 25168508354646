/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, FlexRow, FormattedDateTime, Icon, TextArea } from 'sarsaparilla';

import { storeNoteForCreate, addNoteForExistingUser } from '../actions/notes';

const propTypes = {
    creating: PropTypes.any,
    notes: PropTypes.array,
    notesForCreate: PropTypes.array,
    readOnly: PropTypes.bool,
    addNoteForCreate: PropTypes.func,
    addNoteForUpdate: PropTypes.func,
};

export class UserNotes extends Component {
    static propTypes = propTypes;

    constructor(props) {
        super(props);

        this.state = {
            noteInput: '',
            showCount: 3,
        };
    }

    handleNoteInputChange(e) {
        this.setState({
            noteInput: e.target.value,
        });
    }

    addNote() {
        if (this.state.noteInput.replace(/\s/g, '').length) {
            const defaultReason = 'GENERAL';

            if (this.props.creating) {
                this.props.addNoteForCreate(this.state.noteInput, defaultReason);
            } else {
                this.props.addNoteForUpdate(this.state.noteInput, defaultReason);
            }

            this.setState({
                noteInput: '',
            });
        }
    }

    cancelNote() {
        this.setState({
            noteInput: '',
        });
    }

    noteForm() {
        if (this.props.readOnly) {
            return '';
        }

        return (
            <div>
                <TextArea
                    id="basic"
                    isDisabled={this.props.readOnly}
                    label="Notes"
                    aria-label="Notes"
                    placeholder="Add notes here..."
                    className="add-user-note-input mb-1"
                    onChange={this.handleNoteInputChange.bind(this)}
                    value={this.state.noteInput}
                />
                <FlexRow justifyContent="space-between" className="mx-0">
                    <Button
                        className="add-user-note-button"
                        appearance="tertiary"
                        iconBeforeElement={<Icon iconName="add-circle-outline" />}
                        onClick={this.addNote.bind(this)}
                    >
                        Add Note
                    </Button>
                    <Button
                        className="cancel-user-note-button"
                        appearance="link"
                        onClick={this.cancelNote.bind(this)}
                    >
                        Cancel
                    </Button>
                </FlexRow>
            </div>
        );
    }

    render() {
        const notes = this.props.creating ? this.props.notesForCreate : this.props.notes;
        if (this.props.readOnly && notes.length === 0) {
            return <div />;
        }

        let lis = null;
        if (notes) {
            lis = notes.map((note, index) => {
                const key = `note-list-item-${index}`;

                return (
                    <li className="note-list-item" key={key}>
                        <div>
                            <span className="note-date">
                                <FormattedDateTime
                                    date={new Date(note.note_created_at)}
                                    format={'LL'}
                                />
                            </span>
                            <span className="note-created-by">
                                Added by {note.created_by_name}
                            </span>
                        </div>
                        <div className="note-text">{note.text}</div>
                    </li>
                );
            });
        }

        return (
            <div className="user-notes-wrapper inner-wrapper">
                {this.noteForm()}
                <div className="user-notes">
                    <ul>
                        {lis &&
                            lis
                                .slice(0, Math.min(this.state.showCount, lis.length))
                                .map((li) => li)}
                    </ul>
                </div>
                <div className="view-more-notes">
                    <button
                        type="button"
                        disabled={this.state.showCount >= lis.length}
                        className="view-more-notes-button rec-button-tertiary"
                        onClick={() =>
                            this.setState((prevState) => {
                                return {
                                    ...prevState,
                                    showCount: prevState.showCount + 3,
                                };
                            })
                        }
                    >
                        Load More
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notes: state.notes.notes,
        userId: state.updateUser.accountToSave
            ? state.updateUser.accountToSave.user_id
            : '',
        creating: state.updateUser.creating,
        notesForCreate: state.notes.notesForCreate,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addNoteForCreate: (text, reason) => dispatch(storeNoteForCreate(text, reason)),
        addNoteForUpdate: (text, reason) =>
            dispatch(addNoteForExistingUser(text, reason)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNotes);
