/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sarsaparilla';

const propTypes = {
    roles: PropTypes.arrayOf(
        PropTypes.shape({
            location: PropTypes.shape({
                location_name: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired
    ).isRequired,
    onEdit: PropTypes.func.isRequired,
};

export default function LocationDisplayByRoles(props) {
    const locations = props.roles.map(({ location: { location_name } }) => location_name);

    const maxLocationsToDisplay = 5;
    const displayMoreButton = locations.length > maxLocationsToDisplay;
    const locationsToDisplay = displayMoreButton
        ? locations.slice(1, maxLocationsToDisplay)
        : locations.slice(1);
    return (
        <div className="user-table-locations-column">
            <div className="user-table-location-column-main-display">
                <span className="user-table-location-name">{locations[0]}</span>
                {locations.length > 1 ? (
                    <span className="loc-count" data-count={`+${locations.length - 1}`} />
                ) : null}
            </div>
            {locations.length > 1 && (
                <div className="more-locations-link mt-1">
                    <Button
                        appearance="link"
                        className="hover-locations-more button-as-anchor"
                        onClick={props.onEdit}
                    >
                        View more locations
                    </Button>
                </div>
            )}
            {locations.length > 1 && (
                <div className="hover-locations-view">
                    <ul>
                        {locationsToDisplay.map((location, index) => {
                            return <li key={`hover-location-${index}`}>{location}</li>;
                        })}
                    </ul>
                    {displayMoreButton ? (
                        <Button
                            appearance="link"
                            size="sm"
                            aria-label="view more locations"
                            className="hover-locations-more button-as-anchor"
                            onClick={props.onEdit}
                        >
                            View more
                        </Button>
                    ) : null}
                </div>
            )}
        </div>
    );
}

LocationDisplayByRoles.propTypes = propTypes;
