/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    Concessionaire,
    Cooperator,
    Permit,
    User,
    UserPersonalInfo,
} from '../constants/types';

type Criteria = {
    length?: boolean;
    lower?: boolean;
    upper?: boolean;
    number?: boolean;
    symbol?: boolean;
};

const emptyStringOrFalsy = (field: string): boolean => {
    return !field || field === '';
};

const containsString = (field: string, string: string): boolean => {
    return field.indexOf(string) !== -1;
};

export const validateUserPersonalInfo = (
    user: UserPersonalInfo,
    shouldReceiveSms: boolean
): void => {
    const validationErrors: string[] = [];

    if (emptyStringOrFalsy(user.first_name)) {
        validationErrors.push('First Name is invalid.');
    }

    if (emptyStringOrFalsy(user.last_name)) {
        validationErrors.push('Last Name is invalid.');
    }

    for (const phoneNumber of user.phone_numbers) {
        // We are using a masked input field for phone numbers, so the '_' is only character that would imply invalid number
        // Back end validates that the first number is not 0, TODO: should replace with a validation call to server
        if (containsString(phoneNumber.phone, '_') || phoneNumber.phone.startsWith('0')) {
            validationErrors.push('Phone number is invalid.');
            break;
        }
    }

    if (
        shouldReceiveSms &&
        user.phone_numbers.filter((phone) => phone.type === 'cell').length === 0
    ) {
        validationErrors.push(
            `Must provide a ${user.phone_numbers.length ? 'MOBILE' : 'mobile'} phone number when "Receive SMS" is selected.`
        );
    }

    if (validationErrors.length > 0) {
        const error: any = new Error(
            'User for update is invalid, see validationErrors prop for details'
        );
        error.validationErrors = validationErrors;

        throw error;
    }
};

export const validateEmail = (email: string): void => {
    const validationErrors: string[] = [];

    const re: RegExp = new RegExp('^[^\\s@]+@[^\\s@.]+\\.[^\\s@]+$');
    if (emptyStringOrFalsy(email) || email.length < 5 || !re.test(email)) {
        validationErrors.push('Email is invalid.');
    }

    if (validationErrors.length > 0) {
        const error: any = new Error(
            'User for update is invalid, see validationErrors prop for details'
        );
        error.validationErrors = validationErrors;

        throw error;
    }
};

export const validateUser = (user: User): void => {
    validateUserPersonalInfo(
        {
            user_id: user.user_id,
            first_name: user.first_name,
            last_name: user.last_name,
            phone_numbers: user.phone_numbers,
        },
        user.should_receive_sms || false
    );
    validateEmail(user.email);
};

export const passwordCriteriaCheck = (check: string): Criteria => {
    const criteria: Criteria = {};
    const specialChars: string[] = [
        '!',
        '"',
        '#',
        '%',
        '&',
        "'",
        '(',
        ')',
        '*',
        ',',
        '-',
        '.',
        '/',
        ':',
        ';',
        '?',
        '@',
        '[',
        '\\',
        ']',
        '_',
        '{',
        '}',
        '¡',
        '§',
        '«',
        '¶',
        '·',
        '»',
        '¿',
        '$',
        '+',
        '<',
        '=',
        '>',
        '^',
        '`',
        '|',
        '~',
        '¢',
        '£',
        '¤',
        '¥',
        '¦',
        '¨',
        '©',
        '¬',
        '®',
        '¯',
        '°',
        '±',
        '´',
        '¸',
        '×',
        '÷',
    ];

    criteria.length = check.length >= 12;
    criteria.lower = RegExp('[a-z]').test(check);
    criteria.upper = RegExp('[A-Z]').test(check);
    criteria.number = RegExp('[0-9]').test(check);
    criteria.symbol = RegExp(`[${specialChars.join('')}]`).test(check);

    return criteria;
};

export const validateCooperator = (cooperator: Cooperator): void => {
    const validationErrors: string[] = [];

    if (emptyStringOrFalsy(cooperator.cooperator_name)) {
        validationErrors.push('Cooperator name may not be empty.');
    }

    // only validate if not empty
    if (
        !emptyStringOrFalsy(cooperator.phone_number) &&
        (containsString(cooperator.phone_number, '_') ||
            cooperator.phone_number.startsWith('0'))
    ) {
        validationErrors.push('Phone number is invalid.');
    }

    // same
    const re: RegExp = new RegExp('^[^\\s@]+@[^\\s@.]+\\.[^\\s@]+$');
    if (
        !emptyStringOrFalsy(cooperator.email_address) &&
        (cooperator.email_address.length < 5 || !re.test(cooperator.email_address))
    ) {
        validationErrors.push('Email is invalid.');
    }

    if (validationErrors.length > 0) {
        const error: any = new Error(
            'Cooperator for update is invalid, see validationErrors prop for details.'
        );
        error.validationErrors = validationErrors;

        throw error;
    }
};

export const validateConcessionaire = (concessionaire: Concessionaire): void => {
    const validationErrors: string[] = [];
    const re: RegExp = new RegExp('^[^\\s@]+@[^\\s@.]+\\.[^\\s@]+$');

    if (emptyStringOrFalsy(concessionaire.concessionaire_name)) {
        validationErrors.push('Concessionaire name may not be empty.');
    }

    // only validate if not empty
    if (
        !emptyStringOrFalsy(concessionaire.primary_contact_phone) &&
        (containsString(concessionaire.primary_contact_phone, '_') ||
            concessionaire.primary_contact_phone.startsWith('0'))
    ) {
        validationErrors.push('Primary Contact Phone Number is invalid.');
    }

    // same
    if (
        !emptyStringOrFalsy(concessionaire.secondary_contact_phone) &&
        (containsString(concessionaire.secondary_contact_phone, '_') ||
            concessionaire.secondary_contact_phone.startsWith('0'))
    ) {
        validationErrors.push('Secondary Contact Phone Number is invalid.');
    }

    // same
    if (
        !emptyStringOrFalsy(concessionaire.primary_contact_email) &&
        (concessionaire.primary_contact_email.length < 5 ||
            !re.test(concessionaire.primary_contact_email))
    ) {
        validationErrors.push('Primary Contact Email is invalid.');
    }

    // same
    if (
        !emptyStringOrFalsy(concessionaire.secondary_contact_email) &&
        (concessionaire.secondary_contact_email.length < 5 ||
            !re.test(concessionaire.secondary_contact_email))
    ) {
        validationErrors.push('Secondary Contact Email is invalid.');
    }

    if (validationErrors.length > 0) {
        const error: any = new Error(
            'Concessionaire for creation or update is invalid, see validationErrors prop for details.'
        );
        error.validationErrors = validationErrors;

        throw error;
    }
};

export const validatePermit = (permit: Permit): void => {
    const validationErrors: string[] = [];

    if (emptyStringOrFalsy(permit.concessionaire_id)) {
        validationErrors.push('Must have associated concessionaire id.');
    }

    if (emptyStringOrFalsy(permit.location_id)) {
        validationErrors.push('Must have a location associated with a new Permit.');
    }

    if (
        emptyStringOrFalsy(permit.permit_start_date) ||
        emptyStringOrFalsy(permit.permit_end_date)
    ) {
        validationErrors.push('Must provide dates when editing a Permit.');
    }

    if (
        permit.permit_end_date &&
        permit.permit_start_date &&
        permit.permit_end_date < permit.permit_start_date
    ) {
        validationErrors.push("A permit's end date may not come before it's start.");
    }

    if (validationErrors.length > 0) {
        const error: any = new Error(
            'Cooperator for update is invalid, see validationErrors prop for details.'
        );
        error.validationErrors = validationErrors;

        throw error;
    }
};
