/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { jwtDecode } from 'jwt-decode';
import { TOKEN_LOCAL_STORAGE_KEY, TOKEN_COOKIE_NAME } from '../constants/globals';

const defaultAccountResult = {};

export const isLoggedIn = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY) !== null;
};

export const getAccountWithToken = () => {
    // eslint-disable-next-line no-undef
    const accountWithTokenJson = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);

    if (accountWithTokenJson) {
        return JSON.parse(accountWithTokenJson);
    }

    return defaultAccountResult;
};

export const getAccount = () => {
    const accountWithToken = getAccountWithToken();

    if (accountWithToken !== defaultAccountResult) {
        return accountWithToken.user;
    }

    return defaultAccountResult;
};

export const getAuthHeader = () => {
    const accountWithToken = getAccountWithToken();

    if (accountWithToken !== defaultAccountResult) {
        return `Bearer ${accountWithToken.access_token}`;
    }
    return '';
};

const setAuthCookie = (token) => {
    const domainMatch = document.domain.match(/[^.]*\.[^.]*$/);
    const expDate = new Date();
    expDate.setTime(expDate.getTime() + jwtDecode(token).exp / 1000);

    const cookie = {
        [TOKEN_COOKIE_NAME]: escape(token),
        expires: expDate.toUTCString(),
        path: '/',
        domain: domainMatch ? domainMatch[0] : document.domain,
    };

    const cookieStr = Object.keys(cookie)
        .map((key) => {
            return `${key}=${cookie[key]};`;
        })
        .join('')
        .concat('secure');

    document.cookie = cookieStr;
};

export const setAuthStorage = (responseObj) => {
    localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(responseObj));
    setAuthCookie(responseObj.access_token);
};

const getAudienceClaimType = (token) => {
    const decoded = jwtDecode(token);
    const audience = decoded.aud;
    return Array.isArray(audience) ? audience.pop() : audience;
};

export const claimIsInternal = (token) => {
    return getAudienceClaimType(token) === 'internal';
};

export const claimIsSingleFactor = (token) => {
    return getAudienceClaimType(token) === 'singlefactor';
};

export const claimIsResetPasswordMFA = (token) => {
    if (token) {
        return getAudienceClaimType(token) === 'ResetPasswordMFAToken';
    }
    return false;
};

export const claimIsEmailPassword = (token) => {
    return getAudienceClaimType(token) === 'ResetEmailToken';
};
