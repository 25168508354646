/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    ModalActions,
    StyledModal,
    Text,
    TextArea,
} from 'sarsaparilla';
import { errorForUpdateUser } from '../utilities/errorMessages';
import ActionErrorDisplay from './ActionErrorDisplay';
import * as errors from '../constants/errors';

export default class UserStatusToggle extends React.Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        confirm: PropTypes.func,
        cancel: PropTypes.func,
        selectedUser: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            showErrorMessage: false,
            notesInputValue: '',
        };
    }

    handleNotesChange(e) {
        this.setState({
            notesInputValue: e.target.value,
        });
    }

    onConfirm() {
        let submitFlag = true;
        if (
            this.props.selectedUser.locked === false &&
            this.state.notesInputValue.length < 5
        ) {
            this.setState({
                showErrorMessage: true,
            });
            submitFlag = false;
        }

        if (submitFlag) {
            this.setState({
                showErrorMessage: false,
                notesInputValue: '',
            });

            this.props.confirm(this.state.notesInputValue);
        }
    }

    getActionName() {
        return this.props.selectedUser?.locked === false ? 'Lock' : 'Unlock';
    }

    modalBody() {
        const actionName = this.getActionName();

        return (
            <div className="delete-user-warning-modal-body">
                {this.props.selectedUser?.locked === false && (
                    <Text className="mb-2">
                        If you lock a user, that person won’t be able to log in and will
                        not receive any {`${process.env.SITE_NAME}`}-generated emails
                        until the user is unlocked.{' '}
                        <a href={process.env['SN_KB_EXT_URL']}>Learn More</a>.
                    </Text>
                )}
                <div>{`Would you like to ${actionName.toLowerCase()} the following user?`}</div>
                {this.userInfo()}
                {this.state.showErrorMessage && (
                    <div>
                        <ActionErrorDisplay
                            error={errors.LOCKED_USER_REQUIRES_NOTE}
                            errorStringMapping={errorForUpdateUser}
                        />
                    </div>
                )}
                {this.props.selectedUser?.locked === false && (
                    <div>
                        <TextArea
                            placeholder="Type reason here ..."
                            id="basic"
                            label="Notes"
                            isRequired
                            value={this.state.notesInputValue}
                            onChange={this.handleNotesChange.bind(this)}
                        />
                        <Text size="sm" align="left">
                            <abbr className="rec-label-required-indicator">
                                <span aria-hidden="true">*</span>
                                <span className="rec-sr-only"> (Required) </span>
                            </abbr>
                            &nbsp; At least 5 characters.
                        </Text>
                    </div>
                )}
            </div>
        );
    }

    userInfo() {
        return (
            <ul key={this.props.selectedUser?.user_id}>
                <li>
                    <b>First Name:</b> {this.props.selectedUser?.first_name}
                </li>
                <li>
                    <b>Last Name:</b> {this.props.selectedUser?.last_name}
                </li>
                <li>
                    <b>Email:</b> {this.props.selectedUser?.email}
                </li>
            </ul>
        );
    }

    render() {
        const title = this.getActionName();
        return (
            <StyledModal
                size="md"
                isOpen={this.props.isOpen}
                onRequestClose={this.props.cancel}
                heading={`${title} User`}
                shouldActionsStickInMobile
            >
                {this.modalBody()}
                <ModalActions>
                    <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                        <Button appearance="tertiary" onClick={this.props.cancel}>
                            Cancel
                        </Button>
                        <Button onClick={this.onConfirm.bind(this)}>Confirm</Button>
                    </ButtonGroup>
                </ModalActions>
            </StyledModal>
        );
    }
}
