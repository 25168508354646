/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FeatureFlag, TYPES } from 'sarsaparilla';
import SiteWrapper from './SiteWrapper';
import UserManagementHeader from './UserManagementHeader';
import UpdateUser from '../components/UpdateUser/UpdateUser';
import UpdateUserPanel from '../components/UpdateUser/UpdateUserPanel';

type UpdateUserPageProps = {
    history: TYPES.HISTORY;
    location: TYPES.LOCATION;
    match: TYPES.MATCH;
};

type State = {
    userManagement: {
        selectedTabIndex: number;
    };
};

function UpdateUserPage({ history, location, match }: UpdateUserPageProps) {
    const dispatch = useDispatch();
    const editing = useSelector(
        (state: State) => state.userManagement.selectedTabIndex === 2
    );

    const UserForm = (
        <UpdateUser
            dispatch={dispatch}
            editing={editing}
            history={history}
            location={location}
            match={match}
        />
    );
    const UserPanel = <UpdateUserPanel location={location} match={match} />;

    return (
        <SiteWrapper>
            <div className="page-title">
                <UserManagementHeader editing={editing} />
            </div>
            <div id="UserManagement" className="page-content wrapper">
                {editing ? (
                    <FeatureFlag flag="iaEditUserV2" fallback={UserForm}>
                        {UserPanel}
                    </FeatureFlag>
                ) : (
                    UserForm
                )}
            </div>
        </SiteWrapper>
    );
}

export default UpdateUserPage;
