/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { isEmpty, isEqual, isNil } from 'lodash';
import qs from 'query-string';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { globals } from 'ui-internal-account/index';
import { confirmEmailChange } from '../actions/confirmEmail';
import Loading from './Loading';
import { makeCompatNavigate } from '../utilities/compatNavigateUtil';
import { navigateLogin, resetSession } from '../actions/login';
import useLoggedInUser from '../hooks/useLoggedInUser';

type ConfirmEmailChangePageActions = {
    confirmEmailChangeAction: (token: string) => void;
    resetSessionAction: () => void;
};

type ConfirmEmailChangePageState = {
    confirmEmail: {
        validated: boolean | null;
        successForChange: boolean | null;
        error: Error | string | null;
        successFor: string | null;
    };
};

type ConfirmEmailChangePageProps = ConfirmEmailChangePageActions &
    ConfirmEmailChangePageState;

function ConfirmEmailChangePage({
    confirmEmail: confirmEmailProp,
    confirmEmailChangeAction,
    resetSessionAction,
}: ConfirmEmailChangePageProps) {
    const history = useHistory();
    const navigate = makeCompatNavigate(history);
    const user = useLoggedInUser();

    React.useEffect(() => {
        if (!user?.confirmEmailChangeVerified) {
            //need to log in first
            resetSessionAction();
            const queryParams = qs.parse(history.location.search);
            localStorage.removeItem(globals.CONFIRM_EMAIL_CHANGE_TOKEN);
            localStorage.setItem(globals.CONFIRM_EMAIL_CHANGE_TOKEN, queryParams.token);

            navigateLogin('/internal/account/confirm-email-change');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    React.useEffect(() => {
        //didn't dispatch the confirm email action yet, dispatching...
        if (
            isEqual(user?.confirmEmailChangeVerified, true) &&
            confirmEmailProp &&
            isNil(confirmEmailProp?.error) &&
            !confirmEmailProp?.successForChange
        ) {
            if (!isNil(user?.email)) {
                const confirmEmailChangeToken = localStorage.getItem(
                    globals.CONFIRM_EMAIL_CHANGE_TOKEN
                );
                if (!isEmpty(confirmEmailChangeToken)) {
                    localStorage.removeItem(globals.CONFIRM_EMAIL_CHANGE_TOKEN);
                    confirmEmailChangeAction(confirmEmailChangeToken as string);
                }
            }
        }
    }, [confirmEmailProp, user, confirmEmailChangeAction, resetSessionAction, history]);

    React.useEffect(() => {
        //logged-in and change successful
        if (
            confirmEmailProp &&
            isEqual(user?.confirmEmailChangeVerified, true) &&
            isNil(confirmEmailProp?.error) &&
            isEqual(confirmEmailProp?.successForChange, true)
        ) {
            navigate('/internal/account/hub');
        }
    }, [user, confirmEmailProp, resetSessionAction, navigate]);

    return <Loading />;
}

const mapStateToProps = (state: ConfirmEmailChangePageState) => {
    return {
        confirmEmail: state.confirmEmail,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            confirmEmailChangeAction: confirmEmailChange,
            resetSessionAction: resetSession,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailChangePage);
