/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon, TYPES, Alert } from 'sarsaparilla';
import {
    errorForEnablingPasswordReset,
    errorForResendConfirmationEmail,
} from '../../utilities/errorMessages';

const propTypes = {
    history: TYPES.HISTORY,
    employee: PropTypes.any,
    saveEmployees: PropTypes.func,
    errorText: PropTypes.string,
    employeeActionStatus: PropTypes.shape({
        requestConfirmResponse: PropTypes.object,
        userIdEnabledPasswordReset: PropTypes.any,
        errorEnablingPasswordReset: PropTypes.any,
    }),

    resetPassword: PropTypes.func,
    resendConfirmation: PropTypes.func,
};

export default class EditEmployee extends React.Component {
    static propTypes = propTypes;

    constructor(props) {
        super(props);

        this.state = {
            employee: {
                first_name: '',
                last_name: '',
                email: '',
                ...this.props.employee,
            },
        };
        this.renderInputBlock = this.renderInputBlock.bind(this);
    }

    navCancel() {
        this.props.history.push('/internal/account/cooperator-details');
    }

    navAdd() {
        this.props.saveEmployees([this.state.employee], true);
    }

    toggleInfo(key) {
        this.setState((prevState) => {
            return { ...prevState, [key]: !prevState[key] };
        });
    }

    renderInputBlock(label, field, required) {
        const id = `Mod_${field}`;
        const css = classnames({ 'cooperator-mod-input-block': true, required });

        return (
            <div className={css}>
                <label htmlFor={id}>{label}</label>
                <input
                    id={id}
                    value={this.state.employee[field]}
                    onChange={(e) => {
                        this.setState((prevState) => {
                            return {
                                ...prevState,
                                employee: Object.assign(prevState.employee, {
                                    [field]: e.target.value,
                                }),
                            };
                        });
                    }}
                />
            </div>
        );
    }

    renderResendConfirmationButton() {
        if (
            !this.props.employee ||
            !this.props.employee.user_id ||
            this.props.employee.is_email_confirmed
        ) {
            return null;
        }

        let buttonDescription = (
            <div className="animate-text-holder">
                <div>
                    If a user did not receive a confirmation email upon account creation,
                    or has since
                </div>
                <div>
                    lost that email, you may use the &quot;Resend Confirmation&quot;
                    button to send another.
                </div>
            </div>
        );

        const isError =
            this.props.employeeActionStatus.requestConfirmResponse &&
            this.props.employeeActionStatus.requestConfirmResponse.error;
        const isSuccess =
            !isError && this.props.employeeActionStatus.requestConfirmResponse;

        if (isError) {
            buttonDescription = errorForResendConfirmationEmail(
                this.props.employeeActionStatus.requestConfirmResponse.error,
                this.props.employeeActionStatus.requestConfirmResponse.ttl
            );
        } else if (isSuccess) {
            buttonDescription = 'Confirmation email successfully sent.';
        }

        const key = 'resendInfo';
        const text = 'Resend Confirmation';

        return this.renderActionButton(
            key,
            text,
            buttonDescription,
            isSuccess,
            isError,
            (emp) => this.props.resendConfirmation(emp)
        );
    }

    renderResetPasswordButton() {
        if (
            !this.props.employee ||
            !this.props.employee.user_id ||
            this.props.employee.locked ||
            !this.props.employee.is_email_confirmed
        ) {
            return null;
        }

        let buttonDescription = (
            <div className="animate-text-holder">
                <div>
                    If a user is having trouble resetting a password and logging in, use
                    the &quot;Reset
                </div>
                <div>
                    Password&quot; button to send a reset password email to that user
                    enabling login.
                </div>
            </div>
        );

        const isError =
            this.props.employeeActionStatus.errorEnablingPasswordReset !== null;
        const isSuccess =
            this.props.employeeActionStatus.userIdEnabledPasswordReset &&
            this.props.employeeActionStatus.userIdEnabledPasswordReset ===
                this.props.employee.user_id;

        const key = 'resetInfo';
        const text = 'Reset Password';

        if (isError) {
            buttonDescription = errorForEnablingPasswordReset(
                this.props.employeeActionStatus.errorEnablingPasswordReset
            );
        } else if (isSuccess) {
            buttonDescription = 'Reset Password email successfully sent.';
        }

        return this.renderActionButton(
            key,
            text,
            buttonDescription,
            isSuccess,
            isError,
            (emp) => this.props.resetPassword(emp)
        );
    }

    renderActionButton(key, text, buttonDescription, isSuccess, isError, doClick) {
        let alertType;

        if (isSuccess && !isError) {
            alertType = 'success';
        }

        if (isError) {
            alertType = 'error';
        }

        const classes = classnames({
            'update-user-options-message': true,
            expanded: this.state[key] || isError || isSuccess,
            alerted: isError || isSuccess,
        });

        return (
            <div className="update-user-options">
                <div className="update-user-options-controls">
                    <button
                        type="button"
                        className="update-user-options-button rec-button-tertiary update-user-reset-button"
                        onClick={() => {
                            doClick(this.props.employee);
                        }}
                    >
                        {text}
                    </button>
                    <div className="update-user-slide">
                        <Alert type={alertType} className={classes}>
                            {buttonDescription}
                        </Alert>
                        <button
                            type="button"
                            className="update-user-info-toggle"
                            onClick={() => this.toggleInfo(key)}
                        >
                            <div>
                                {!this.state[key] && !isSuccess && !isError && (
                                    <Icon iconName="info" />
                                )}
                                {!!this.state[key] && !isSuccess && !isError && (
                                    <Icon iconName="chevron-left" />
                                )}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="cooperator-details-wrapper">
                <div className="cooperators-header">
                    <h2 className="cooperator-component-title h5">
                        <span className="header-main-title">
                            {this.props.employee && this.props.employee.user_id
                                ? 'Edit'
                                : 'Add'}{' '}
                            Employee
                        </span>
                        {this.props.employee && this.props.employee.first_name && (
                            <span>
                                <span className="header-break" />
                                <span className="header-sub-title">{`${this.props.employee.first_name} ${this.props.employee.last_name}`}</span>
                            </span>
                        )}
                    </h2>
                </div>

                {this.props.errorText && (
                    <Alert shouldFocusOnMount type="error" className="cooperators-error">
                        {this.props.errorText}
                    </Alert>
                )}

                <div className="cooperators-body">
                    <div className="cooperator-mod-inputs">
                        {this.renderInputBlock('First Name', 'first_name')}
                        {this.renderInputBlock('Last Name', 'last_name')}
                        {this.renderInputBlock('Email Address', 'email', true)}
                    </div>
                </div>
                <div className="cooperators-actions">
                    {this.renderResendConfirmationButton()}
                    {this.renderResetPasswordButton()}
                </div>
                <div className="cooperators-footer ia-footer-submission">
                    <div />
                    <div>
                        <button
                            type="button"
                            className="cancel-employee rec-button-tertiary"
                            onClick={this.navCancel.bind(this)}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="submit-employee rec-button-primary"
                            onClick={this.navAdd.bind(this)}
                        >
                            {this.state.employee && this.state.employee.user_id
                                ? 'Update'
                                : 'Add'}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
