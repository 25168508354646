/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    dataSource: {
        data: [],
        loaded: false,
        id: '',
    },
    error: null,
    loading: false,
};

const fetchEntrancesForLocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOCATION_ENTRANCE_REQUEST_SUCCESS:
            return {
                ...state,
                dataSource: {
                    data: action.data,
                    id: action.id,
                    loaded: true,
                },
                loading: false,
                error: null,
            };
        case types.LOCATION_ENTRANCE_REQUEST_ERROR:
            return {
                ...state,
                dataSource: {
                    data: action.data,
                    id: action.id,
                    loaded: true,
                },
                error: action.error,
                loading: false,
            };
        case types.START_LOCATION_ENTRANCE_REQUEST:
            return { ...state, id: action.id, loading: true };
        default:
            return state;
    }
};

export default fetchEntrancesForLocationReducer;
