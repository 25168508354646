/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import axios from 'axios';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { TYPES } from 'sarsaparilla';
import { InternalSecurityUtils } from '../../index';
import { loadedManagedCooperators, saveCooperators } from '../actions/manageCooperators';
import { setCooperator } from '../actions/manageCooperatorsSet';
import CooperatorAddressConfirmationModal from '../components/cooperator/CooperatorAddressConfirmationModal';
import EditCooperator from '../components/cooperator/EditCooperator';
import PageHeader from '../components/PageHeader';
import * as errors from '../constants/errors';
import {
    errorForCooperators,
    errorForSavingCooperatorEmployees,
} from '../utilities/errorMessages';
import { ATR, BAH_ADMIN, FACILITY_MANAGER, PMO, SUPER_USER } from '../utilities/roles';
import SiteWrapper from './SiteWrapper';

export class EditCooperatorContainer extends React.Component {
    static propTypes = {
        history: TYPES.HISTORY,
        location: PropTypes.object,
        loggedInUser: PropTypes.any,
        cooperator: PropTypes.any,
        employee: PropTypes.any,
        savingManagedCooperatorsError: PropTypes.bool,
        savingCooperatorEmployeesError: PropTypes.bool,
        saveCooperators: PropTypes.func,
        updateCooperators: PropTypes.func,
        cooperators: PropTypes.array,
        updateCooperator: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            addresses: [],
            cooperator: { ...this.props.cooperator },
            selectedAddressIndex: 0,
            isModalOpened: false,
            error: null,
        };

        this.handleConfirmAddress = this.handleConfirmAddress.bind(this);
        this.handleValidateAddress = this.handleValidateAddress.bind(this);
        this.handleAddressValidated = this.handleAddressValidated.bind(this);
        this.toggleIsModalOpened = this.toggleIsModalOpened.bind(this);
        this.updateCooperatorsList = this.updateCooperatorsList.bind(this);
    }

    handleConfirmAddress() {
        if (this.state.cooperator.cooperator_id) {
            this.handleAddressValidated();
        } else {
            this.setState((prevState) => {
                const newState = {
                    ...prevState,
                    cooperator: {
                        ...prevState.cooperator,
                        address_validated: true,
                    },
                };

                return newState;
            });
        }
        this.toggleIsModalOpened();
        this.setState((prevState) => ({
            cooperator: {
                ...prevState.cooperator,
                address: prevState.addresses[prevState.selectedAddressIndex],
            },
        }));
    }

    handleAddressValidated() {
        const url = `${process.env.API}/internal/account/cooperator/${this.state.cooperator.cooperator_id}/address-validated`;

        axios
            .patch(
                url,
                {},
                { headers: { Authorization: InternalSecurityUtils.getAuthHeader() } }
            )
            .then(() => {
                this.setState(
                    (prevState) => {
                        const newState = {
                            ...prevState,
                            cooperator: {
                                ...prevState.cooperator,
                                address_validated: true,
                            },
                        };

                        return newState;
                    },
                    () => {
                        this.updateCooperatorsList(this.state.cooperator);
                        this.props.updateCooperator(this.state.cooperator);
                    }
                );
            })
            .catch((e) => {
                this.setState({ error: e.response.data.error });
                setTimeout(() => {
                    this.setState({ error: null });
                }, 5000);
            });
    }

    handleValidateAddress(cooperator) {
        this.clear();
        const url = `${process.env.API}/internal/account/suggest-address`;
        const body = {
            address: {
                address1: get(cooperator, 'address.address1') ?? '',
                address2: get(cooperator, 'address.address2') ?? '',
                city: get(cooperator, 'address.city') ?? '',
                state: get(cooperator, 'address.state') ?? '',
                zip_code: get(cooperator, 'address.zip_code') ?? '',
            },
        };
        axios
            .post(url, body, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: InternalSecurityUtils.getAuthHeader(),
                },
            })
            .then((data) => {
                this.setState({
                    addresses: [body.address, data.data?.Address],
                });

                this.toggleIsModalOpened();
            })
            .catch((e) => {
                this.setState({ error: e.response.data.error });
                setTimeout(() => {
                    this.setState({ error: null });
                }, 5000);
            });
    }

    saveCooperators = (cooperators, add) => {
        const coops = cooperators || [this.state.cooperator];
        this.props.saveCooperators(coops, add, this.props.history);
        this.clear();
    };

    updateCooperatorsList(cooperator) {
        const cooperators = [];

        this.props.cooperators?.forEach((coop) => {
            if (coop.cooperator_id === cooperator.cooperator_id) {
                cooperators.push(cooperator);
            } else {
                cooperators.push(coop);
            }
        });

        this.props.updateCooperators(cooperators);
    }

    clear() {
        this.setState({
            selectedAddressIndex: 0,
            error: null,
        });
    }

    toggleIsModalOpened() {
        this.setState((prevState) => {
            return { ...prevState, isModalOpened: !prevState.isModalOpened };
        });
    }

    render() {
        const { history, loggedInUser } = this.props;

        let error = '';
        let navBackUrl = null;
        const crumbs = [];
        const tabs = [
            {
                title: 'Cooperators',
            },
        ];

        error = this.props.savingCooperatorEmployeesError
            ? errorForSavingCooperatorEmployees(this.props.savingCooperatorEmployeesError)
            : error;

        error = this.props.savingManagedCooperatorsError
            ? errorForCooperators(errors.SAVE_COOPERATORS_FAILED)
            : error;
        error = this.state.error ? this.state.error : error;

        navBackUrl = '/internal/account/manage-cooperators';
        crumbs.push({
            label: 'Cooperators',
            onClick: () => history.push('/internal/account/manage-cooperators'),
        });

        if (this.props.cooperator.cooperator_id) {
            navBackUrl = '/internal/account/cooperator-details';
            crumbs.push({
                label: 'Cooperator Details',
                onClick: () => history.push('/internal/account/cooperator-details'),
            });
        }

        if (
            this.props.cooperator &&
            this.props.location.pathname.includes('cooperator-edit')
        ) {
            crumbs.push({
                label: this.props.cooperator.cooperator_id
                    ? 'Edit Cooperator'
                    : 'Add Cooperator',
            });
        } else if (
            this.props.cooperator &&
            this.props.cooperator.cooperator_id &&
            this.props.employee
        ) {
            crumbs.push({
                label: this.props.employee.user_id ? 'Edit Employee' : 'Add Employee',
            });
        }

        const isManager =
            loggedInUser &&
            loggedInUser.roles.some((role) => {
                return (
                    role.role_type === FACILITY_MANAGER ||
                    role.role_type === ATR ||
                    role.role_type === PMO ||
                    role.role_type === SUPER_USER ||
                    role.role_type === BAH_ADMIN
                );
            });

        return (
            <SiteWrapper>
                <div className="page-title">
                    <PageHeader
                        title="Cooperator Management"
                        tabs={tabs}
                        breadcrumbs={crumbs}
                        navBack={navBackUrl}
                        selectedTabIndex={0}
                    />
                </div>
                <div id="CooperatorManagement" className="page-content wrapper">
                    <EditCooperator
                        history={history}
                        cooperator={this.state.cooperator}
                        key={this.state.cooperator?.cooperator_id}
                        errorText={error}
                        isManager={isManager}
                        saveCooperators={this.saveCooperators}
                        isAddressValidated={this.state.cooperator?.address_validated}
                        onValidateAddress={this.handleValidateAddress}
                    />
                </div>
                <CooperatorAddressConfirmationModal
                    isModalOpened={this.state.isModalOpened}
                    toggleIsModalOpened={this.toggleIsModalOpened}
                    addresses={this.state.addresses}
                    selectedAddressIndex={this.state.selectedAddressIndex}
                    setSelectedAddressIndex={(e) =>
                        this.setState({
                            selectedAddressIndex: Number(e.target.value),
                        })
                    }
                    onConfirmAddress={this.handleConfirmAddress}
                />
            </SiteWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.login.user,
        cooperator: state.manageCooperatorsSet.managedCooperator,
        cooperators: state.manageCooperators.managedCooperators,
        employee: state.manageCooperatorsSet.cooperatorEmployee,
        savingManagedCooperatorsError:
            state.manageCooperators.savingManagedCooperatorsError,
        savingCooperatorEmployeesError:
            state.manageCooperators.savingCooperatorEmployeesError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveCooperators: (cooperators, add, history) =>
            dispatch(saveCooperators(cooperators, add, history)),
        updateCooperators: (cooperators) =>
            dispatch(loadedManagedCooperators(cooperators)),
        updateCooperator: (cooperator) => dispatch(setCooperator(cooperator)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCooperatorContainer);
