/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TYPES } from 'sarsaparilla';

import ConcessionaireDetails from '../components/concessionaire/ConcessionaireDetails';
import PageHeader from '../components/PageHeader';
import SiteWrapper from './SiteWrapper';

import { savePermit, loadConcessionairePermits } from '../actions/manageConcessionaires';
import { setPermit } from '../actions/manageConcessionairesSet';

export class ConcessionaireDetailsContainer extends React.Component {
    static propTypes = {
        history: TYPES.HISTORY,
        dispatch: PropTypes.func,
        concessionaire: PropTypes.any,
        permit: PropTypes.any,
        permits: PropTypes.array,
        loadingConcessionairePermitsError: PropTypes.any,
        setPermit: PropTypes.func,
        savePermit: PropTypes.func,
    };

    componentDidMount() {
        this.props.dispatch(loadConcessionairePermits());
    }

    setPermit = (permit) => {
        this.props.setPermit(permit);
    };

    savePermit = (permit, add) => {
        const perm = permit || this.props.permit;
        this.props.savePermit(perm, add, this.props.history);
    };

    render() {
        const {
            history,
            concessionaire,
            permit,
            permits,
            loadingConcessionairePermitsError,
        } = this.props;

        const crumbs = [];
        const tabs = [
            {
                title: 'Concessionaires',
            },
        ];

        const navBackUrl = '/internal/account/manage-concessionaires';
        crumbs.push({
            label: 'Concessionaires',
            onClick: () => history.push('/internal/account/manage-concessionaires'),
        });
        crumbs.push({
            label: 'Concessionaire Details',
        });

        //Wrapper div needed to prevent weird css bug caused by sitewrapper. Thanks again Charlie.
        return (
            <SiteWrapper>
                <div>
                    <div className="page-title">
                        <PageHeader
                            title="Concessionaire Management"
                            tabs={tabs}
                            breadcrumbs={crumbs}
                            navBack={navBackUrl}
                            selectedTabIndex={0}
                        />
                    </div>
                    <div id="ConcessionaireManagement" className="page-content wrapper">
                        <ConcessionaireDetails
                            history={history}
                            gridData={permits}
                            concessionaire={concessionaire}
                            permit={permit}
                            setPermit={this.setPermit}
                            savePermit={this.savePermit}
                            error={loadingConcessionairePermitsError}
                        />
                    </div>
                </div>
            </SiteWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        concessionaire: state.manageConcessionairesSet.managedConcessionaire,
        permit: state.manageConcessionairesSet.concessionairePermit,
        permits: state.manageConcessionaires.concessionairePermits,
        loadingConcessionairePermitsError:
            state.manageConcessionaires.loadingConcessionairePermitsError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        setPermit: (permit) => dispatch(setPermit(permit)),
        savePermit: (permit, add, history) => dispatch(savePermit(permit, add, history)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConcessionaireDetailsContainer);
