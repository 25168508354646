/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { useSelector } from 'react-redux';
import { User } from '../constants/types';

type State = {
    login: {
        user: User;
    };
};

export default function useIsLoggedInUserAConcessionaire(): boolean {
    return useSelector((state: State) => state.login?.user?.is_concessionaire);
}
