/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, HelmetWrapperInternal, TextField } from 'sarsaparilla';
import { errors } from 'ui-internal-account';
import { passwordResetMFAValidate } from '../actions/resetPassword';
import { MfaValidateForm } from './login/MfaValidateForm';
import { errorForResetPassword } from '../utilities/errorMessages';
import { claimIsResetPasswordMFA } from '../utilities/internalSecurityUtil';
import { passwordCriteriaCheck } from '../utilities/validation';
import Loading from '../containers/Loading';

type PasswordFormActions = {
    reset: (
        token: string,
        password: string,
        confirmPassword: string,
        history: any
    ) => void;
    logOut: () => void;
};

type PasswordFormState = {
    token: string;
    resetPassword?: {
        validated?: boolean;
        mfaValidated?: boolean;
        resetTokenMFA?: string;
        error?: any;
    };
};

type PasswordFormProps = PasswordFormActions & PasswordFormState;

function PasswordForm({
    token,
    resetPassword: resetPasswordProp,
    reset,
    logOut,
}: PasswordFormProps) {
    const history = useHistory();
    const dispatch = useDispatch();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newError, setNewError] = useState(false);
    const [confirmError, setConfirmError] = useState(false);
    const [criteria, setCriteria] = useState(passwordCriteriaCheck(''));
    const [confirmMatch, setConfirmMatch] = useState(false);

    const getCriteriaClass = (criteriaClass: boolean | undefined, errored: boolean) => {
        return classnames({
            'criteria-check': true,
            'criteria-passed': criteriaClass,
            'criteria-failed': errored && !criteria,
        });
    };

    const doCriteriaCheck = useCallback(() => {
        setCriteria(passwordCriteriaCheck(password));
        setConfirmMatch(password === confirmPassword);
        setNewError(false);
        setConfirmError(false);
    }, [password, confirmPassword]);

    const validateCriteria = () => {
        let valid: boolean = password.length > 0;
        Object.keys(criteria).forEach((key) => {
            valid = valid && (criteria as any)[key];
        });
        return valid;
    };

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const pass = validateCriteria();
        const match = confirmMatch;
        if (pass && match) {
            reset(token, password, confirmPassword, history);
        } else {
            setNewError(!pass);
            setConfirmError(!match);
        }
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
    };

    const isTokenValidated = () => {
        return resetPasswordProp?.validated === true;
    };
    const isMFAValidated = () => {
        return resetPasswordProp?.mfaValidated === true;
    };

    const shouldPromptMFA = () => {
        return (
            claimIsResetPasswordMFA(resetPasswordProp?.resetTokenMFA) &&
            isTokenValidated() &&
            !isMFAValidated()
        );
    };

    const shouldRenderPasswordForm = () => {
        if (resetPasswordProp?.error) {
            if (resetPasswordProp?.error === errors.CANNOT_REUSE_PASSWORD) {
                return true;
            }
            return false;
        }

        if (shouldPromptMFA()) {
            return false;
        }

        return resetPasswordProp?.validated;
    };

    const doLogOut = () => {
        logOut();
        history.push('/internal/account/login');
    };

    const error = () => {
        if (resetPasswordProp && resetPasswordProp?.error) {
            return (
                <div className="rec-notification-error" role="alert">
                    <div className="rec-notification-title">
                        {errorForResetPassword(resetPasswordProp.error)}
                    </div>
                </div>
            );
        }

        return null;
    };

    const passwordForm = () => {
        const disableSubmit = !confirmMatch || !validateCriteria();

        return (
            <form id="ResetPassword" onSubmit={submit}>
                <HelmetWrapperInternal title="Set Your Password" />
                <h1 className="h3 set-password-header">Set Your Password</h1>

                {error()}
                <div>
                    <TextField
                        label="New Password"
                        isLabelVisible={false}
                        type="password"
                        id="password"
                        className={classnames({
                            'change-password-input': true,
                            'rec-error': newError,
                        })}
                        onChange={handlePasswordChange}
                        value={password}
                        placeholder="New Password"
                        enableShowPassword
                    />
                    <div className="change-password-criteria for-new-password">
                        <div className="criteria-check criteria-label">
                            Password must contain:
                        </div>
                        <div className="criteria-inner-wrap">
                            <div className={getCriteriaClass(criteria.length, newError)}>
                                12+ characters
                            </div>
                            <div className={getCriteriaClass(criteria.lower, newError)}>
                                Lower case
                            </div>
                            <div className={getCriteriaClass(criteria.upper, newError)}>
                                Upper case
                            </div>
                            <div className={getCriteriaClass(criteria.number, newError)}>
                                Number
                            </div>
                            <div className={getCriteriaClass(criteria.symbol, newError)}>
                                Symbol
                            </div>
                        </div>
                    </div>
                    <TextField
                        type="password"
                        label="New Password"
                        isLabelVisible={false}
                        id="confirm-password"
                        className={classnames({
                            'change-password-input': true,
                            'rec-error': confirmError,
                        })}
                        onChange={handleConfirmPasswordChange}
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        enableShowPassword
                    />
                    <div className="change-password-criteria for-confirm-password">
                        <div
                            className={classnames({
                                required: true,
                                hidden: confirmMatch || !confirmError,
                            })}
                        >
                            <span className="change-password-error-asterisk">*</span>
                            Passwords do not match
                        </div>
                    </div>
                </div>
                <button
                    type="submit"
                    className="rec-button-primary"
                    id="reset"
                    disabled={disableSubmit}
                >
                    Save Password
                </button>
            </form>
        );
    };

    const mfaForm = () => {
        const validateMFA = (code: string) => {
            // @ts-ignore ... the actions file is not TS yet
            dispatch(passwordResetMFAValidate(resetPasswordProp?.resetTokenMFA, code, 0));
        };

        return (
            <MfaValidateForm
                submitText={'Validate'}
                submitFn={validateMFA}
                error={resetPasswordProp?.error}
            />
        );
    };

    const formlessPage = () => {
        return (
            <div>
                {error()}

                <p>
                    <Button appearance="link" id="back-to-login" onClick={doLogOut}>
                        Back to Login Page.
                    </Button>
                </p>
            </div>
        );
    };

    useEffect(() => {
        doCriteriaCheck();
    }, [password, confirmPassword, doCriteriaCheck]);

    return (
        <div className="logged-out-container">
            <Loading />
            {shouldPromptMFA() && mfaForm()}
            {shouldRenderPasswordForm() ? passwordForm() : formlessPage()}
        </div>
    );
}

export default connect()(PasswordForm);
