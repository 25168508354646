/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PageHeader from 'ui-internal-account/src/components/PageHeader';
import {
    Button,
    ButtonGroup,
    FlexRow,
    FlexCol,
    ModalActions,
    StyledModal,
    Text,
    useToast,
    Alert,
} from 'sarsaparilla';
import { ReactTableWithLayout } from 'shared-ui';
import moment from 'moment/moment';
import SiteWrapper from './SiteWrapper';
import {
    getUsersByAgencyCanAttestPeers,
    attestUsers,
} from '../actions/attestationManagement';
import TextHighlight from '../components/tableUtil/TextHighlight';
import LocationDisplayByRoles from '../components/tableUtil/LocationDisplayByRoles';
import LocationPicker from '../components/LocationPicker';
import { displayStringForRoleType } from '../utilities/roles';
import { getLocationDisplayName } from '../utilities/locations';

const propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    loggedInUserId: PropTypes.string,
};

export function AttestationManagement(props) {
    const showToast = useToast();
    const [usersAttestation, setUsersAttestation] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState({});
    const [isModalOpened, setIsModalOpened] = useState(false);

    const changeLocation = async (location) => {
        try {
            setSelectedLocation(location);
            const data = await getUsersByAgencyCanAttestPeers(location.location_id)();
            setUsersAttestation(data);
        } catch (e) {
            setErrorMessage(e.message);
        }
    };

    useEffect(() => {
        (async () => {
            await changeLocation(props.location);
        })();
    }, [props.location]);

    const isUserInList = (users, user_id) => {
        for (let index = 0; index < users.length; index += 1) {
            const user = users[index];
            if (user.user_id === user_id) {
                return true;
            }
        }

        return false;
    };

    const handleAttestUsers = async () => {
        setIsModalOpened(false);
        try {
            await attestUsers(selectedLocation, usersAttestation)();
            showToast(`Attestation completed for ${usersAttestation.length} user(s).`, {
                type: 'success',
            });
        } catch (e) {
            setErrorMessage(e.message);
        }
    };

    const editUser = (user) => {
        const editRoute = `/internal/account/edit-user/${user.user_id}`;
        props.history.push(editRoute);
    };

    const columns = () => {
        const renderFirstName = (row) => {
            return (
                <Button onClick={() => editUser(row.original)} appearance="link">
                    <TextHighlight searchTerm={''} body={row.original.first_name} />
                </Button>
            );
        };

        const renderLastName = (row) => {
            return (
                <Button onClick={() => editUser(row.original)} appearance="link">
                    <TextHighlight searchTerm={''} body={row.original.last_name} />
                </Button>
            );
        };

        const renderEmail = (row) => {
            return <TextHighlight searchTerm={''} body={row.original.email} />;
        };

        const renderLocations = (row) => {
            return (
                <LocationDisplayByRoles
                    roles={row.original.roles}
                    onEdit={() => editUser(row.original)}
                />
            );
        };

        const renderColumnActions = (row) => {
            return (
                <ButtonGroup isStretchedToFit>
                    <Button
                        className="edit-user-button"
                        appearance="link"
                        onClick={() => editUser(row.original)}
                    >
                        View User
                    </Button>
                </ButtonGroup>
            );
        };

        return [
            {
                Header: 'First Name',
                accessor: 'first_name',
                sortable: true,
                Cell: ({ row }) => renderFirstName(row),
                minWidth: 70,
            },
            {
                Header: 'Last Name',
                accessor: 'last_name',
                sortable: true,
                Cell: ({ row }) => renderLastName(row),
                minWidth: 70,
            },
            {
                Header: 'Email',
                accessor: 'email',
                sortable: false,
                Cell: ({ row }) => renderEmail(row),
                minWidth: 130,
            },
            {
                Header: 'Locations',
                id: 'location',
                sortable: true,
                accessor: (row) => {
                    return row.roles
                        .map((role) => role.location.location_name)
                        .join(', ');
                },
                Cell: ({ row }) => renderLocations(row),
                minWidth: 100,
            },
            {
                Header: 'Role',
                id: 'role',
                sortable: true,
                accessor: (row) => {
                    return row.roles && row.roles.length ? row.roles[0].role_type : '';
                },
                Cell: ({ row }) =>
                    displayStringForRoleType(row.original.roles[0].role_type),
                minWidth: 70,
            },
            {
                Header: 'Attestation Date',
                sortable: false,
                minWidth: 120,
                maxWidth: 180,
                Cell: ({ row }) => {
                    return row.original.last_attested_at
                        ? moment(row.original.last_attested_at).format('MMM D, YYYY')
                        : '';
                },
            },
            {
                Header: 'Status',
                accessor: 'locked',
                minWidth: 40,
                maxWidth: 100,
                sortable: true,
                Cell: ({ row }) => (row.original.locked ? 'Locked' : 'Unlocked'),
            },
            {
                Header: 'Actions',
                minWidth: 60,
                maxWidth: 160,
                sortable: false,
                Cell: ({ row }) => renderColumnActions(row),
            },
        ];
    };

    const renderActions = () => {
        return (
            <FlexRow className="attestation-management-table-header-wrap">
                <FlexCol sm={12} md={6}>
                    <FlexRow alignItems="center">
                        <FlexCol xs={12} sm={1} className="attestation-location-label">
                            <div>Location</div>
                        </FlexCol>
                        <FlexCol xs={12} sm={8}>
                            <LocationPicker
                                id="attestation-management-locations"
                                selectedLocation={selectedLocation}
                                locationSelect={changeLocation}
                            />
                        </FlexCol>
                    </FlexRow>
                </FlexCol>
                <FlexCol sm={12} md={6}>
                    <FlexRow justifyContent="end">
                        <Button
                            id="manageAttestationAttestToUsersButton"
                            onClick={() => setIsModalOpened(true)}
                            appearance="secondary"
                            disabled={
                                !usersAttestation.length ||
                                isUserInList(usersAttestation, props.loggedInUserId)
                            }
                        >
                            Attest To Users
                        </Button>
                    </FlexRow>
                </FlexCol>
            </FlexRow>
        );
    };

    const renderTable = () => {
        const classes = classNames({
            'attestation-management': true,
            throttle: true,
        });

        return (
            <div className={classes}>
                {errorMessage && (
                    <Alert shouldFocusOnMount type="error">
                        {errorMessage}
                    </Alert>
                )}
                <div className="attestation-management-table-wrap">
                    {renderActions()}
                    <ReactTableWithLayout
                        noDataText={`No users found`}
                        columns={columns()}
                        defaultSorted={[{ id: 'last_name', desc: false }]}
                        data={usersAttestation}
                        isSortable
                    />
                </div>
            </div>
        );
    };

    return (
        <SiteWrapper>
            <StyledModal
                size="md"
                isOpen={isModalOpened}
                heading="Confirmation"
                onRequestClose={() => setIsModalOpened(false)}
            >
                <Text>
                    By proceeding with this attestation, you are confirming that these{' '}
                    {usersAttestation.length} user(s) all have the role Facility Manager
                    at {getLocationDisplayName(selectedLocation)}.
                </Text>
                <ModalActions>
                    <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                        <Button
                            appearance="tertiary"
                            onClick={() => setIsModalOpened(false)}
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleAttestUsers}>Ok</Button>
                    </ButtonGroup>
                </ModalActions>
            </StyledModal>
            <div className="page-title">
                <PageHeader title="Attestation Management" />
            </div>
            <div id="UserManagement" className="page-content wrapper">
                <div>{renderTable()}</div>
            </div>
        </SiteWrapper>
    );
}

AttestationManagement.propTypes = propTypes;

const mapStateToProps = (state) => {
    return {
        location: state.selectedLocationRole.role.location,
        loggedInUserId: state.login.user.user_id,
    };
};

export default connect(mapStateToProps)(AttestationManagement);
