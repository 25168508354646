/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import type { AgencyLocationType } from '../../../types/agencyLocationsType';

export const AGENCY_LOCATIONS: AgencyLocationType[] = [
    {
        // TODO: THIS IS BAD!!1! Who did this? Oh well, it's here, may as well use it...
        location_name: 'USGS',
        location_id: '200000',
    },
    {
        location_name: 'NPS',
        location_id: '70904',
    },
    {
        location_name: 'MAPA - Mendocino Area Parks',
        location_id: '120750',
    },
    {
        location_name: 'USFS',
        location_id: '70903',
    },
    {
        location_name: 'BLM',
        location_id: '70901',
    },
    {
        location_name: 'FWS',
        location_id: '70906',
    },
    {
        location_name: 'NCMO',
        location_id: '70910',
    },
    {
        location_name: 'USACE',
        location_id: '70902',
    },
    {
        location_name: 'NARA',
        location_id: '70908',
    },
    {
        location_name: 'BOR',
        location_id: '70905',
    },
    {
        location_name: 'NPS-Central Interagency Pass',
        location_id: 'EEB06A90-548A-4C48-AC65-4C006005C933',
    },
    {
        location_name: 'Presidio Trust',
        location_id: 'AG1013',
    },
    {
        location_name: 'CNIC',
        location_id: 'AG40013',
    },
];

// cSpell:ignore CNIC, MAPA, NCMO, USACE, USFS
