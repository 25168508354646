/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Role, User } from '../constants/types';
import { BAH_ADMIN, CSR2, CSR_SUPERVISOR, PMO, SUPER_USER } from './roles';

export function isUserCsrSupervisor(u: User | null): boolean {
    return (
        u?.roles?.some((role: Role) => {
            return [CSR_SUPERVISOR].includes(role.role_type);
        }) || false
    );
}

export function canUserControlMFA(u: User | null): boolean {
    return (
        u?.roles.some((role: any) =>
            [SUPER_USER, BAH_ADMIN, PMO, CSR2, CSR_SUPERVISOR].includes(role.role_type)
        ) || false
    );
}

export function canUserForceResetPassword(u: User | null): boolean {
    return (
        u?.roles.some((role: any) =>
            [CSR2, CSR_SUPERVISOR, BAH_ADMIN, SUPER_USER].includes(role.role_type)
        ) || false
    );
}
export function canUserForceLogout(u: User | null): boolean {
    return (
        u?.roles.some((role: any) =>
            [CSR2, CSR_SUPERVISOR, BAH_ADMIN, SUPER_USER].includes(role.role_type)
        ) || false
    );
}
export function canUserDoMFABypass(u: User | null): boolean {
    return (
        u?.roles.some((role: any) =>
            [SUPER_USER, BAH_ADMIN, PMO].includes(role.role_type)
        ) || false
    );
}

export function isUserConcessionaire(user: User | null): boolean {
    return user !== null && user.is_concessionaire && !user.inherits_concessionaire_roles;
}

export function isUserConcessionaireManager(user: User | null): boolean {
    return user !== null && user.is_concessionaire && user.inherits_concessionaire_roles;
}

export function countDifferences(obj1: any, obj2: any) {
    let differences = 0;
    const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
    keys.forEach((key) => {
        const val1 = obj1[key];
        const val2 = obj2[key];
        if (Array.isArray(val1) && Array.isArray(val2)) {
            if (val1.length !== val2.length) {
                differences += 1;
            }
        } else if (val1 !== val2) {
            differences += 1;
        }
    });
    return differences;
}
