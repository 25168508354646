/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

type ToastProps = {
    children?: any;
    msg?: string;
    type?: string;
    isColored?: boolean;
    show: boolean;
    setShow: (value: boolean) => void;
};
export default function Toast({
    children,
    type = 'success',
    isColored = false,
    msg,
    show,
    setShow,
}: ToastProps) {
    if (!show || !children) {
        return null;
    }

    setTimeout(() => {
        setShow(false);
    }, 5000);

    return (
        <div className="sarsa-toast-container sarsa-toast-container-top-center">
            <div
                role="alert"
                className={`sarsa-toast sarsa-toast-${type} ${isColored ? `sarsa-toast-${type}-colored` : ''} item-top-center-enter-done`}
            >
                <span className={`sarsa-toast-icon sarsa-toast-icon-${type}`}>
                    {type === 'success' ? (
                        <svg
                            data-component="Icon"
                            className="sarsa-icon rec-icon-check-circle"
                            viewBox="0 0 24 24"
                            role="presentation"
                            focusable="false"
                            height="24"
                            width="24"
                        >
                            <g>
                                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.126-7.15l-2.766-2.765-.94.935 3.706 3.708 7.96-7.96-.936-.935-7.024 7.018z" />
                            </g>
                        </svg>
                    ) : (
                        <svg
                            data-component="Icon"
                            className="sarsa-icon rec-icon-info"
                            viewBox="0 0 24 24"
                            role="presentation"
                            focusable="false"
                            height="24"
                            width="24"
                        >
                            <g>
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                            </g>
                        </svg>
                    )}
                </span>
                {children && <>{children}</>}
                {msg && <p className="sarsa-text size-lg weight-semibold">{msg}</p>}
                <button
                    data-component="Button"
                    type="button"
                    className="sarsa-button sarsa-button-subtle sarsa-button-xs sarsa-toast-close"
                    onClick={() => setShow(false)}
                >
                    <span className="sarsa-button-inner-wrapper">
                        <span
                            aria-hidden="true"
                            className="sarsa-button-icon-content left-icon is-only-child"
                        >
                            <svg
                                data-component="Icon"
                                className="sarsa-icon rec-icon-close sm"
                                viewBox="0 0 24 24"
                                role="presentation"
                                focusable="false"
                                height="24"
                                width="24"
                            >
                                <g>
                                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                </g>
                            </svg>
                        </span>
                        <span className="sarsa-button-content">
                            <span className="rec-sr-only">close this notification</span>
                        </span>
                    </span>
                </button>
                <div
                    className={`sarsa-toast-progress sarsa-toast-progress-animated sarsa-toast-progress-${type}`}
                    style={{ animationDuration: '5s', animationPlayState: 'running' }}
                />
            </div>
        </div>
    );
}
