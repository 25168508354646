/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    AlertsNotification,
    SkipNavContent,
    SkipNavLink,
    ToastContainer,
} from '@fp/sarsaparilla';

import AutoLogoutWarning from './AutoLogoutWarning';
import Loading from './Loading';
import {
    logOut as logOutAction,
    navigateLogin as navigateLoginAction,
} from '../actions/login';
import { getDomainsForLocation as getDomainsForLocationAction } from '../actions/fetchChildLocations';
import HeaderRibbon from '../components/HeaderRibbon';
import FooterRibbon from '../components/FooterRibbon';
import ActivityPing from '../components/ActivityPing';
import { parseUrlForLocation } from '../utilities/locations';
import SiteWideAlert from '../../../ui-communication/src/components/SiteWideAlert';

interface Alert {
    location_id: string;
    id: string | number;
    alert_level: 'INFO' | 'WARNING' | 'ERROR';
    created_at: string;
    body: string;
}

interface DomainsForLocation {
    [key: string]: string;
}

interface LoginState {
    account?: {
        domains_by_location_id?: Record<string, string>;
    };
    user?: User;
}

interface SiteWrapperState {
    login: LoginState;
    alertsNotification: {
        alerts: Alert[];
        closedSiteWideIDs: Record<string, boolean>;
    };
    selectedLocationRole: {
        role: Role;
    };
    fetchChildLocations: {
        selected_domains_by_location_id: Record<string, string>;
    };
}

interface SiteWrapperProps {
    children: React.ReactNode;
    loggedInUser: User | undefined;
    alerts: Alert[];
    closedSiteWideIDs: Record<string, boolean>;
    selectedRole: Role;
    domainsForLocationId: DomainsForLocation;
    logOut: () => void;
    navigateLogin: () => void;
    getDomainsForLocation: (location: object, history: History) => void;
}

export function SiteWrapper({
    children,
    loggedInUser,
    alerts,
    closedSiteWideIDs,
    selectedRole,
    domainsForLocationId,
    logOut,
    navigateLogin,
    getDomainsForLocation,
}: SiteWrapperProps) {
    const history = useHistory();

    useEffect(() => {
        const location = parseUrlForLocation();
        if (location) {
            if (getDomainsForLocation) {
                getDomainsForLocation(location, history as unknown as History);
            }
        }
    }, [getDomainsForLocation, history]);

    const doLogOut = () => {
        logOut();
    };

    const renderHeaderRibbon = () => {
        let role = null;
        if (loggedInUser) {
            role = selectedRole || loggedInUser.roles[0];
        }

        let selectedLocation = null;
        if (selectedRole) {
            selectedLocation = selectedRole.location;
        } else if (loggedInUser && loggedInUser.roles.length === 1) {
            selectedLocation = loggedInUser.roles[0].location;
        }

        const doNav = window.location.pathname !== '/internal/account/hub';

        return (
            <HeaderRibbon
                history={history}
                user={loggedInUser}
                selectedRole={role}
                selectedLocation={selectedLocation}
                logout={doLogOut}
                navigateLogin={doNav ? navigateLogin : null}
                domainsForLocationId={domainsForLocationId}
                getDomainsForLocation={getDomainsForLocation}
            />
        );
    };

    const classes = classNames({
        'page-wrapper': true,
        wrapper: true,
    });

    const siteWideAlerts: Alert[] = [];
    const regularAlerts: Alert[] = [];

    alerts?.forEach((alert: Alert) => {
        if (alert.location_id !== '1') {
            regularAlerts.push({
                ...alert,
                alert_level: alert.alert_level,
                created_at: alert.created_at,
                body: alert.body,
            });
        } else {
            siteWideAlerts.push(alert);
        }
    });

    return (
        <div id="SiteWrapper" className={classes}>
            <SkipNavLink />
            {/* @ts-ignore */}
            <AutoLogoutWarning />
            <Loading />
            <ToastContainer />
            <ActivityPing />

            {renderHeaderRibbon()}

            <SiteWideAlert
                alerts={siteWideAlerts as never[]}
                closedSiteWideIDs={closedSiteWideIDs}
            />
            <AlertsNotification alerts={regularAlerts} />

            <SkipNavContent>
                <div className="site-body wrapper">{children}</div>
            </SkipNavContent>

            <FooterRibbon />
        </div>
    );
}

const mapStateToProps = (state: SiteWrapperState) => {
    const loginDomains = state.login?.account?.domains_by_location_id ?? {};

    return {
        loggedInUser: state.login?.user,
        alerts: state.alertsNotification?.alerts,
        closedSiteWideIDs: state.alertsNotification?.closedSiteWideIDs ?? {},
        selectedRole: state.selectedLocationRole?.role,
        roleSelection: state.selectedLocationRole?.role,
        domainsForLocationId:
            state.fetchChildLocations?.selected_domains_by_location_id ?? loginDomains,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        logOut: () => {
            dispatch(logOutAction());
        },
        navigateLogin: () => {
            navigateLoginAction('/internal/account/hub');
        },
        getDomainsForLocation: (location: object, history: History) =>
            dispatch(getDomainsForLocationAction(location, history)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteWrapper);
