/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

export const USGS_LOCATION_ID: string = '200000';

export const R1S_LOCATION_ID: string = '1';
export const ASSET: string = 'asset';
export const DEFAULT_ADDRESS_TYPE: string = 'Default';

export const ORGANIZATION: string = 'organization';
export const ROOT: string = 'Root';
export const AGENCY: string = 'Agency';
export const REGION: string = 'Region';
export const REGIONAL_DISTRICT: string = 'Regional District';
export const REC_AREA: string = 'Rec Area';
export const REC_AREA_DISTRICT: string = 'Rec Area District';
export const ACTIVITY_PASS: string = 'Activity Pass';

export const VENUE_RESERVATIONS: string = 'Venue Reservations';
export const VENUE: string = 'Venue';

export const VEHICLE_PERMIT: string = 'Vehicle Permit';
export const PERMIT: string = 'Permit';
export const TREE_PERMIT: string = 'Tree Permit';

export const TICKET_FACILITY: string = 'Ticket Facility';
export const TOUR: string = 'Tour';

export const TIMED_ENTRY: string = 'Timed Entry';
export const CAMPGROUND: string = 'Campground';
export const CAMPSITE: string = 'Campsite';

export const AllHighLvlLocationsArr: string[] = [ROOT, AGENCY, REGION, REGIONAL_DISTRICT];

export const AllLeafTypesArr: string[] = [
    VEHICLE_PERMIT,
    PERMIT,
    TICKET_FACILITY,
    TIMED_ENTRY,
    CAMPGROUND,
    ACTIVITY_PASS,
    VENUE_RESERVATIONS,
];
