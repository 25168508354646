/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { globals } from 'ui-internal-account/index';
import { loginFailed, navigateLogin } from 'ui-internal-account/src/actions/login';
import { HistoryV4, Location } from '../../../../shared-ui/types/HistoryV4';
import { AppDispatch } from '../../../dev/store';
import { authorizeCallback } from '../../actions/oidc';
import Loading from '../../containers/Loading';

type OidcCallbackProps = {
    history: HistoryV4;
    location: Location;
};

export default function OidcCallback(props: OidcCallbackProps) {
    const dispatch = useDispatch<AppDispatch>();
    const sp = new URLSearchParams(props.location.search);

    useEffect(() => {
        const error = sp.get('error') as string;
        if (!isEmpty(error)) {
            if (error !== 'access_denied') {
                dispatch(loginFailed(error));
            }

            navigateLogin();
            return;
        }

        const storageState = localStorage.getItem(globals.OIDC_STATE_STORAGE_KEY);
        const code = sp.get('code') as string;
        const state = sp.get('state') as string;

        if (storageState !== state) {
            dispatch(loginFailed('corrupt state returned from oidc login'));
            navigateLogin();
            return;
        }

        localStorage.removeItem(globals.OIDC_STATE_STORAGE_KEY);
        dispatch(authorizeCallback(code, state, props.history));
    });

    return <Loading />;
}
