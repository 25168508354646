/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Button, Heading, Text } from 'sarsaparilla';
import MfaQrCodePlaceholder from '../../img/mfa-qr-code-placeholder.png';

type MfaEnrollmentSetupScanProps = {
    hideStartOverButton?: boolean;
    mfaManualEntry: string;
    qrCode: string;
    nextStep: () => void;
    startOver: () => void;
};

function MfaEnrollmentSetupScan({
    hideStartOverButton,
    mfaManualEntry,
    qrCode,
    nextStep,
    startOver,
}: MfaEnrollmentSetupScanProps) {
    const imgSrc = qrCode ? `data:image/png;base64,${qrCode}` : MfaQrCodePlaceholder;
    return (
        <>
            <Heading
                headingLevel={1}
                appearance="h4"
                className="mb-3"
                headingAlign="center"
            >
                Set Up Multi-Factor Authentication
            </Heading>

            <Text size="md" className="mb-3">
                <strong>Step 2.</strong> To activate, use your agency mandated
                multi-factor authentication application from Step 1 to scan this QR code.
                You can also enter the 2-Factor Authentication(2FA) key shown below into
                your application manually.
            </Text>

            <div className="my-4 center">
                <img src={imgSrc} alt="QR Code" width={150} height={150} />
            </div>

            <Text size="md" align="center" fontWeight="semibold">
                2FA Key (Manual entry)
            </Text>
            <Text size="lg" align="center" className="tracking-wide">
                {mfaManualEntry ?? `0000000000000000000`}
            </Text>

            <div className="center mt-5">
                <Button onClick={nextStep} gaTrackingId="743418888606" size="lg">
                    Next
                </Button>
            </div>
            <Text size="md" className="mt-2 " align="center">
                Click Next to proceed to the last set up step.
            </Text>
            <Text size="md" align="center" className="mb-5">
                <strong>Warning:</strong> You cannot see this screen again after you click
                Next.
            </Text>

            {!hideStartOverButton && (
                <Text size="md" className="center mt-5">
                    <Button
                        appearance="link"
                        href="/internal/account/login"
                        onClick={startOver}
                        gaTrackingId="743418888606"
                    >
                        Start over
                    </Button>
                    <span>&nbsp;</span>
                    <span>and return to login page</span>
                </Text>
            )}
        </>
    );
}

export default MfaEnrollmentSetupScan;
