/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

type GetRowCountParams = {
    manualRowCount?: number;
    manualPageCount?: number;
    pageSize: number;
    dataLength: number;
};

export function getRowCount({
    manualRowCount,
    manualPageCount,
    pageSize,
    dataLength,
}: GetRowCountParams) {
    let rowCount = 0;
    let isRowCountEstimated = false;

    if (typeof manualRowCount === 'number') {
        rowCount = manualRowCount;
    } else if (typeof manualPageCount === 'number' && manualPageCount > 1) {
        // Fallback: if pagination is manual but there's no row count, we use the page count
        // and page size to get a minimum value that's later displayed like `81+`
        rowCount = pageSize * manualPageCount - pageSize + 1;
        isRowCountEstimated = true;
    } else {
        rowCount = dataLength;
    }

    return { rowCount, isRowCountEstimated };
}
