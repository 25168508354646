/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LabelValueRow from './LabelValueRow';

const formatTimes = (value) => {
    // TODO: replace moment here
    const dateFormat = 'MMM Do, YYYY';
    let valueInTime = 'never';
    let valueInTimeAgo = '';
    if (value) {
        const m = moment(value);
        valueInTime = m.format(dateFormat);
        valueInTimeAgo = `(${m.fromNow()})`;
    }
    return { valueInTime, valueInTimeAgo };
};

export default function LabelAndTimestampAgo({ label, timestamp }) {
    const { valueInTime, valueInTimeAgo } = formatTimes(timestamp);
    return <LabelValueRow label={label} value={`${valueInTime} ${valueInTimeAgo}`} />;
}
LabelAndTimestampAgo.propTypes = {
    label: PropTypes.string,
    timestamp: PropTypes.string,
};
