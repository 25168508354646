/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * Created by jkeene on 8/1/17.
 */
import * as types from '../constants/types';

const initialState = {
    token: null,
    user: null,
    error: null,
    validated: false,
    successFor: null,
    successForChange: null,
    successForCancel: null,
};

export default function confirmEmail(state = initialState, action) {
    switch (action.type) {
        case types.CONFIRM_EMAIL_SUCCESS:
            return {
                ...state,
                token: action.token,
                user: action.user,
                validated: true,
                error: null,
                successFor: action.user ? action.user.email : null,
            };
        case types.CONFIRM_EMAIL_FAILED:
            return {
                ...state,
                token: null,
                user: null,
                validated: false,
                error: action.error,
                successFor: null,
            };
        case types.CONFIRM_EMAIL_CHANGE_SUCCESS:
            return {
                ...state,
                validated: true,
                successForChange: true,
                error: null,
            };
        case types.CONFIRM_EMAIL_CHANGE_FAILED:
            return { ...state, successForChange: false };
        case types.CANCEL_EMAIL_CHANGE_SUCCESSFUL:
            return {
                ...state,
                successForCancel: true,
            };
        case types.CANCEL_EMAIL_CHANGE_FAILED:
            return { ...state, successForCancel: false };
        case types.CANCEL_EMAIL_CHANGE_RESET:
            return { ...state, successForCancel: null };
        case types.LOCATION_CHANGE:
            return {
                ...state,
                error: null,
                validated: false,
                successFor: null,
                successForChange: null,
                successForCancel: null,
            };
        case types.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
}
