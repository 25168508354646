/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { FlexCol, FlexRow } from 'sarsaparilla';

export default function LabelRow({ children }) {
    return (
        <FlexRow className={'mt-1'}>
            <FlexCol sm={8}>
                <b>{children}</b>
            </FlexCol>
        </FlexRow>
    );
}
LabelRow.propTypes = {
    children: PropTypes.any,
};
