/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { TYPES } from 'sarsaparilla';
import {
    deleteCooperators,
    loadManagedCooperators,
    saveCooperators,
} from '../actions/manageCooperators';
import { setCooperator } from '../actions/manageCooperatorsSet';
import ManageCooperators from '../components/cooperator/ManageCooperators';
import PageHeader from '../components/PageHeader';
import * as errors from '../constants/errors';
import { errorForCooperators } from '../utilities/errorMessages';
import SiteWrapper from './SiteWrapper';

export class ManageCooperatorsContainer extends React.Component {
    static propTypes = {
        history: TYPES.HISTORY,
        dispatch: PropTypes.func,
        cooperator: PropTypes.any,
        cooperators: PropTypes.array,
        loadingManagedCooperatorsError: PropTypes.bool,
        savingManagedCooperatorsError: PropTypes.bool,
        deletingManagedCooperatorsError: PropTypes.bool,
        setCooperator: PropTypes.func,
        saveCooperators: PropTypes.func,
        deleteCooperators: PropTypes.func,
    };

    componentDidMount() {
        this.props.dispatch(loadManagedCooperators());
    }

    setCooperator = (cooperator) => {
        this.props.setCooperator(cooperator);
    };

    saveCooperators = (cooperators, add) => {
        const coops = cooperators || [this.props.cooperator];
        this.props.saveCooperators(coops, add, this.props.history);
    };

    deleteCooperators = (cooperators) => {
        this.props.deleteCooperators(cooperators);
    };

    render() {
        const { history } = this.props;
        let error = '';
        const navBackUrl = null;
        const tabs = [
            {
                title: 'Cooperators',
            },
        ];

        error = this.props.loadingManagedCooperatorsError
            ? errorForCooperators(errors.GET_COOPERATORS_FAILED)
            : error;
        error = this.props.savingManagedCooperatorsError
            ? errorForCooperators(errors.SAVE_COOPERATORS_FAILED)
            : error;
        error = this.props.deletingManagedCooperatorsError
            ? errorForCooperators(errors.DELETE_COOPERATORS_FAILED)
            : error;

        return (
            <SiteWrapper>
                <div className="page-title">
                    <PageHeader
                        title="Cooperator Management"
                        tabs={tabs}
                        breadcrumbs={[]}
                        navBack={navBackUrl}
                        selectedTabIndex={0}
                    />
                </div>
                <div id="CooperatorManagement" className="page-content wrapper">
                    <ManageCooperators
                        history={history}
                        gridData={this.props.cooperators}
                        errorText={error}
                        setCooperator={this.setCooperator}
                        saveCooperators={this.saveCooperators}
                        deleteCooperators={this.deleteCooperators}
                    />
                </div>
            </SiteWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cooperator: state.manageCooperatorsSet.managedCooperator,
        cooperators: state.manageCooperators.managedCooperators,
        loadingManagedCooperatorsError:
            state.manageCooperators.loadingManagedCooperatorsError,
        savingManagedCooperatorsError:
            state.manageCooperators.savingManagedCooperatorsError,
        deletingManagedCooperatorsError:
            state.manageCooperators.deletingManagedCooperatorsError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        setCooperator: (cooperator) => dispatch(setCooperator(cooperator)),
        saveCooperators: (cooperators, add, history) =>
            dispatch(saveCooperators(cooperators, add, history)),
        deleteCooperators: (cooperators) => dispatch(deleteCooperators(cooperators)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCooperatorsContainer);
