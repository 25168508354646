/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { HistoryV4 } from 'shared-ui';

/**
 * Makes a hacky navigate() function to partially mimic the shape of React Router v6's navigate().
 *
 * Many legacy Redux actions receive the old history object as a prop and then use it for
 * navigation changes. Until we can get everybody on to the new internal app root, this
 * will allow legacy bits to work as they do now with a fallback for the new
 * implementations. The format will mimic the React Router v6 navigate function so that
 * it's easier to refactor in the future
 */

type Options = {
    replace?: boolean;
};

export function makeCompatNavigate(history?: HistoryV4) {
    if (history) {
        return (url: string, options?: Options) => {
            if (options?.replace) {
                history.replace(url);
            } else {
                history.push(url);
            }
        };
    }

    return (url: string, options?: Options) => {
        if (options?.replace) {
            window.location.replace(url);
        } else {
            window.location.assign(url);
        }
    };
}
