/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    error: null,
    location: null,
    machineApiKeys: [],
    machineApiPermissions: null,
    getMachineApiKeysError: false,
    generateMachineApiKeyError: false,
    disableMachineApiKeyError: false,
    getMachineApiPermissionsError: false,
    updateMachineApiPermissionsError: false,
};

const machineApi = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LOCATION_INFO:
            return {
                ...state,
                error: null,
                location: action.location,
                machineApiPermissions: null,
                getMachineApiKeysError: false,
                generateMachineApiKeyError: false,
                disableMachineApiKeyError: false,
                getMachineApiPermissionsError: false,
                updateMachineApiPermissionsError: false,
            };

        case types.GET_MACHINE_API_KEYS_SUCCESSFUL:
            return {
                ...state,
                machineApiKeys: action.machineApiKeys,
                getMachineApiKeysError: false,
                error: null,
            };

        case types.GET_MACHINE_API_KEYS_FAILED:
            return {
                ...state,
                getMachineApiKeysError: true,
                error: action.error,
            };

        case types.GENERATE_MACHINE_API_KEY_SUCCESSFUL:
            return {
                ...state,
                machineApiKeys: state.machineApiKeys?.concat(action.machineApiKeys),
                generateMachineApiKeyError: false,
                error: null,
            };

        case types.GENERATE_MACHINE_API_KEY_FAILED:
            return {
                ...state,
                generateMachineApiKeyError: true,
                error: action.error,
            };

        case types.DISABLE_MACHINE_API_KEY_SUCCESSFUL:
            return {
                ...state,
                disableMachineApiKeyError: false,
                error: null,
            };

        case types.DISABLE_MACHINE_API_KEY_FAILED:
            return {
                ...state,
                disableMachineApiKeyError: true,
                error: action.error,
            };

        case types.GET_MACHINE_API_PERMISSIONS_SUCCESSFUL:
            return {
                ...state,
                machineApiPermissions: action.machineApiPermissions,
                getMachineApiPermissionsError: false,
                error: null,
            };

        case types.GET_MACHINE_API_PERMISSIONS_FAILED:
            return {
                ...state,
                getMachineApiPermissionsError: true,
                error: action.error,
            };

        case types.UPDATE_MACHINE_API_PERMISSIONS_SUCCESSFUL:
            return {
                ...state,
                updateMachineApiPermissionsError: false,
                error: null,
            };

        case types.UPDATE_MACHINE_API_PERMISSIONS_FAILED:
            return {
                ...state,
                updateMachineApiPermissionsError: true,
                error: action.error,
            };

        case types.CLEAR_MACHINE_API_KEY_ERRORS:
            return {
                ...state,
                getMachineApiKeysError: false,
                generateMachineApiKeyError: false,
                disableMachineApiKeyError: false,
                getMachineApiPermissionsError: false,
                updateMachineApiPermissionsError: false,
                error: null,
            };
        case types.LOGGED_OUT:
            return initialState;

        default:
            return state;
    }
};

export default machineApi;
