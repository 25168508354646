/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { Button, Heading, Text, FormValidationAlert } from 'sarsaparilla';
import {
    VerificationCodeField,
    type VerificationCodeFieldRef,
    type ValidationState,
} from './VerificationCodeField';

type MfaEnrollmentVerificationProps = {
    isLoading: boolean;
    nextStep: (value: string) => void;
    startOver: () => void;
};

function MfaEnrollmentVerification({
    isLoading,
    nextStep,
    startOver,
}: MfaEnrollmentVerificationProps) {
    const inputRef = React.useRef<VerificationCodeFieldRef>(null);
    const [inputValidation, setInputValidation] = React.useState<ValidationState | null>(
        null
    );

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const validation = inputRef.current?.validate() ?? null;
        const value = inputRef.current?.getValue();
        setInputValidation(validation);

        if (value && !validation?.isInvalid) {
            nextStep(value);
        }
    };

    return (
        <>
            <Heading
                headingLevel={1}
                appearance="h4"
                className="mb-3"
                headingAlign="center"
            >
                Set Up Multi-Factor Authentication
            </Heading>

            <Text size="md" className="mb-3">
                <strong>Step 3.</strong> Enter the 6-digit verification code you see on
                your agency-mandated multi-factor authentication application.
            </Text>

            {inputValidation?.isInvalid && (
                <FormValidationAlert
                    // @ts-ignore This type issue will be fixed in the next sarsa release
                    errors={[inputValidation]}
                    className="mx-8"
                />
            )}

            <form className="center-vertical my-5" onSubmit={onSubmit} noValidate>
                <VerificationCodeField
                    ref={inputRef}
                    label="Verification Code"
                    className="mb-3"
                />

                <Button
                    type="submit"
                    size="lg"
                    isDisabled={isLoading}
                    gaTrackingId="463350020648"
                >
                    Validate Code
                </Button>
            </form>

            <Text size="md" className="mt-2">
                Complete process by clicking the above button. <b>Note</b>, you will be
                taken to another window to retrieve Backup Codes in the event you cannot
                access your agency-mandated multi-factor authentication application.
            </Text>

            <Text tag="div" className="center mt-5">
                <Button
                    href="/internal/account/login"
                    appearance="link"
                    onClick={startOver}
                    rel="noreferrer"
                    gaTrackingId="261677614215"
                >
                    Start over
                </Button>
                <span>&nbsp;</span>
                <span>and return to login page</span>
            </Text>
        </>
    );
}

export default MfaEnrollmentVerification;
