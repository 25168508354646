/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import {
    Alert,
    Button,
    ButtonGroup,
    FlexCol,
    FlexRow,
    FormattedDateTime,
    Icon,
    Select,
    SelectOption,
    Switch,
    TextFieldStateless,
    TYPES,
} from 'sarsaparilla';
import { ReactTableWithLayout } from 'shared-ui';
import { creatingUser, deleteUsers, editingUser } from '../actions/updateUser';
import { fetchUsers, fetchUsersClear } from '../actions/fetchUsers';
import {
    checkUserListThrottle as checkUserListThrottleAction,
    filterChanged,
    setInitialValue,
    setTabIndex as setTabIndexAction,
    setUpdateUser,
    updateUserStatus,
} from '../actions/userManagement';

import UserListThrottle from '../components/UserListThrottle';
import DeleteUserWarning from '../components/DeleteUserWarning';
import {
    errorForDeletingUser,
    errorForListingUsers,
    errorForUserAttestation,
} from '../utilities/errorMessages';
import {
    displayStringForRoleType,
    findHighestRoleType,
    roleDropdownOptionsForLocation,
    SYSTEM_USER,
} from '../utilities/roles';
import { setAttestationState } from '../actions/attestation';
import { attestPostErrorSelector } from '../reducers/attestation';
import SiteWrapper from './SiteWrapper';
import UserManagementHeader from './UserManagementHeader';
import TextHighlight from '../components/tableUtil/TextHighlight';
import {
    EMAIL_FIELD_TYPE,
    LAST_NAME_FIELD_TYPE,
    LOCATION_NAME_FIELD_TYPE,
} from '../constants/globals';
import UserStatusToggle from '../components/UserStatusToggle';

const propTypes = {
    history: TYPES.HISTORY,
    editing: PropTypes.bool,
    userResults: PropTypes.any,
    dataLoaded: PropTypes.bool,
    userRequiresThrottledSearch: PropTypes.any,
    fetchUsersError: PropTypes.any,
    deleteUserError: PropTypes.any,
    attestUsersError: PropTypes.any,
    deletedUser: PropTypes.object,
    deletedUsers: PropTypes.array,
    getUserList: PropTypes.func,
    deleteUsers: PropTypes.func,
    creatingUser: PropTypes.func,
    clearUserList: PropTypes.func,
    setAttestationState: PropTypes.func,
    managerRole: PropTypes.any,
    setTabIndex: PropTypes.func.isRequired,
    checkUserListThrottle: PropTypes.func.isRequired,
    selectedIds: PropTypes.object,
    loggedInUser: PropTypes.object,
    filters: PropTypes.object,
    filterChanged: PropTypes.func,
    searchValue: PropTypes.string,
    searchField: PropTypes.string,
    updateUserStatusErrorMessage: PropTypes.string,
    updateUserStatusSuccessful: PropTypes.bool,
    object: PropTypes.object,
    setInitialValue: PropTypes.func,
    setUpdateUser: PropTypes.func,
    updateUserStatus: PropTypes.func,
};

export class UserListBox extends React.Component {
    static propTypes = propTypes;

    filterResults = memoize((reviews, role, status, text, location) => {
        let filtered = reviews;

        if (role) {
            filtered = filtered.filter(({ roles }) => {
                if (roles.length < 1) {
                    return false;
                }

                for (const r of roles) {
                    if (
                        !!location?.location_id &&
                        r.location_id !== location.location_id
                    ) {
                        continue;
                    }

                    if (role === r.role_type) {
                        return true;
                    }
                }

                return false;
            });
        }

        if (status) {
            filtered = filtered.filter(({ locked }) => locked.toString() === status);
        }

        if (text) {
            filtered = filtered.filter(
                (row) =>
                    row.first_name.toLowerCase().includes(text.toLowerCase()) ||
                    row.last_name.toLowerCase().includes(text.toLowerCase()) ||
                    row.email.toLowerCase().includes(text.toLowerCase())
            );
        }

        return filtered;
    });

    constructor(props) {
        super(props);
        const filterReceived =
            !!props.searchValue ||
            !!props.filters?.statusFilter ||
            !!props.filters?.textFilter ||
            !!props.filters?.roleFilter;

        // initial state
        this.state = {
            modalIsOpen: false,
            statusToggleModalIsOpen: false,
            statusToggleUser: props.object,
            showSystemUserWarn: false,
            itemsToDelete: [],
            throttleString: '',
            showThrottle:
                filterReceived || props.userRequiresThrottledSearch || !props.dataLoaded,

            selectedIds: props.selectedIds,
            filters: {
                roleFilter: props.filters?.roleFilter || '',
                statusFilter: props.filters?.statusFilter || '',
                textFilter: props.filters?.textFilter || '',
            },
        };
    }

    componentDidMount() {
        const { setTabIndex, checkUserListThrottle } = this.props;
        setTabIndex(0);
        checkUserListThrottle();

        this.props.setInitialValue();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.userResults !== prevProps.userResults ||
            this.state.filters?.statusFilter !== prevState.filters?.statusFilter ||
            this.state.filters?.textFilter !== prevState.filters?.textFilter ||
            this.state.filters?.roleFilter !== prevState.filters?.roleFilter
        ) {
            this.UnselectUsersNotDisplayed();
        }
    }

    // eslint-disable-next-line react/sort-comp
    UnselectUsersNotDisplayed() {
        const { selectedIds } = this.state;
        const filteredResults = this.getFilteredResults();
        let changed = false;
        const next = { ...selectedIds };
        Object.keys(selectedIds).forEach((id) => {
            if (!filteredResults.some(({ user_id }) => user_id === id)) {
                delete next[id];
                changed = true;
            }
        });
        if (changed) {
            this.setState({ selectedIds: next });
        }
    }

    getRoleOptions() {
        const { managerRole, loggedInUser } = this.props;
        let location = managerRole.location;

        if (
            this.props.searchField === LOCATION_NAME_FIELD_TYPE &&
            this.state.selectedLocation
        ) {
            location = this.state.selectedLocation;
        }

        const options = roleDropdownOptionsForLocation(
            loggedInUser.roles,
            location,
            false
        );
        const sortedOptions = options.sort((a, b) => a.order < b.order);

        const roleOptions = sortedOptions.map((option) => (
            <SelectOption key={`role-option-${option.value}`} value={option.value}>
                {' '}
                {displayStringForRoleType(option.value)}
            </SelectOption>
        ));
        roleOptions.unshift(
            <SelectOption key="role-option-blank" value="">
                {' '}
                ---{' '}
            </SelectOption>
        );

        return roleOptions;
    }

    getStatusOptions() {
        return [
            <SelectOption key="status-option-blank" value="">
                {' '}
                ---{' '}
            </SelectOption>,
            <SelectOption key="status-option-locked" value="true">
                {' '}
                Locked{' '}
            </SelectOption>,
            <SelectOption key="status-option-active" value="false">
                {' '}
                Active{' '}
            </SelectOption>,
        ];
    }

    updateAttestState() {
        const { filters } = this.state;

        if (filters?.textFilter === '' && filters?.statusFilter === '') {
            this.props.setAttestationState({ role: filters?.roleFilter });
        } else {
            this.props.setAttestationState({ role: '' });
        }
        this.props.filterChanged({
            filters: {
                textFilter: filters?.textFilter,
                roleFilter: filters?.roleFilter,
                statusFilter: filters?.statusFilter,
            },
        });
    }

    clearFilters() {
        const filters = { textFilter: '', roleFilter: '', statusFilter: '' };
        this.props.filterChanged({ filters });
        this.setState({
            filters,
            selectedIds: {},
        });
    }

    handleTextFilterChange({ target: { value } }) {
        const { filters } = this.state;
        const newFilters = Object.assign(filters, { textFilter: value });
        this.setState({ filters: newFilters }, this.updateAttestState.bind(this));
    }

    handleTextFilterClear() {
        const { filters } = this.state;
        const newFilters = Object.assign(filters, { textFilter: '' });
        this.setState({ filters: newFilters }, this.updateAttestState.bind(this));
    }

    getFilteredResults() {
        const { userResults, searchField, searchValue } = this.props;
        const { filters } = this.state;
        const filteredResults = this.filterResults(
            userResults,
            filters?.roleFilter,
            filters?.statusFilter,
            filters?.textFilter,
            searchField === LOCATION_NAME_FIELD_TYPE ? searchValue : null
        );
        return filteredResults;
    }

    setRoleFilter({ target: { value } }) {
        const { filters } = this.state;
        const newFilters = Object.assign(filters, { roleFilter: value });
        this.setState({ filters: newFilters }, this.updateAttestState.bind(this));
    }

    setStatusFilter({ target: { value } }) {
        const { filters } = this.state;
        const newFilters = Object.assign(filters, { statusFilter: value });
        this.setState({ filters: newFilters }, this.updateAttestState.bind(this));
    }

    renderFiltersAndActions() {
        return (
            <FlexRow className="user-list-table-header-wrap">
                <FlexCol sm={12} md={4}>
                    <FlexRow alignItems="center">
                        <FlexCol xs={12} sm="variable">
                            <div>Filter by:</div>
                        </FlexCol>
                        <FlexCol xs={12} sm="auto">
                            <Select
                                label="Role Filter"
                                placeholder="Role"
                                value={this.state.filters?.roleFilter}
                                onChange={this.setRoleFilter.bind(this)}
                                isLabelVisible={false}
                                id="filter-role-select"
                            >
                                {this.getRoleOptions()}
                            </Select>
                        </FlexCol>
                        <FlexCol xs={12} sm="auto">
                            <Select
                                label="Status Filter"
                                placeholder="Status"
                                value={this.state.filters?.statusFilter}
                                onChange={this.setStatusFilter.bind(this)}
                                isLabelVisible={false}
                                id="filter-status-select"
                            >
                                {this.getStatusOptions()}
                            </Select>
                        </FlexCol>
                    </FlexRow>
                </FlexCol>
                <FlexCol sm={12} md={5} xl={6}>
                    <FlexRow alignItems="center">
                        <FlexCol xs={12} sm="variable">
                            <div>or</div>
                        </FlexCol>
                        <FlexCol xs={12} sm="auto">
                            <TextFieldStateless
                                label="Search Text"
                                isLabelVisible={false}
                                placeholder="Search by First Name, Last Name, or Email."
                                onChange={this.handleTextFilterChange.bind(this)}
                                handleClearButtonClick={this.handleTextFilterClear.bind(
                                    this
                                )}
                                hasClearButton
                                iconElement={<Icon iconName="search" />}
                                id="filter-text-search"
                            />
                        </FlexCol>
                    </FlexRow>
                </FlexCol>
                <FlexCol sm={12} md={3} xl={2}>
                    <ButtonGroup isStretchedToFit>
                        <Button
                            id="manageUserAddUserButton"
                            onClick={this.creatingUser}
                            appearance="primary"
                        >
                            Create User
                        </Button>
                    </ButtonGroup>
                </FlexCol>
            </FlexRow>
        );
    }

    getAttestationDate(user) {
        if (!user?.roles || user.roles.length < 1) {
            return '';
        }

        let attestedAt = user.last_attested_at;
        if (this.props.searchField === LOCATION_NAME_FIELD_TYPE) {
            attestedAt = user.roles[0].last_attested_at;
        }

        if (attestedAt) {
            return <FormattedDateTime date={new Date(attestedAt)} format={'LL'} />;
        }

        return '';
    }

    getTableColumns() {
        const renderFirstName = (row) => {
            return (
                <Button onClick={() => this.editUser(row.original)} appearance="link">
                    <TextHighlight
                        searchTerm={this.state.filters?.textFilter}
                        body={row.original.first_name}
                        disableCaseSensitivity
                    />
                </Button>
            );
        };

        const renderLastName = (row) => {
            return (
                <Button onClick={() => this.editUser(row.original)} appearance="link">
                    <TextHighlight
                        searchTerm={this.state.filters?.textFilter}
                        body={row.original.last_name}
                        disableCaseSensitivity
                    />
                </Button>
            );
        };

        const renderEmail = (row) => {
            return (
                <TextHighlight
                    searchTerm={this.state.filters?.textFilter}
                    body={row.original.email}
                    disableCaseSensitivity
                />
            );
        };

        const renderRoles = (row) => {
            const rolesMap = {};
            row.original.roles.forEach((role) => {
                if (!rolesMap[role.role_type]) {
                    rolesMap[role.role_type] = [];
                }
                rolesMap[role.role_type].push(role.location);
            });

            let showRole = this.state.filters?.roleFilter;
            if (!showRole) {
                showRole = findHighestRoleType(row.original.roles);
            }

            const locations = rolesMap[showRole]?.slice(0, 3) ?? [];
            const moreCount = row.original.roles.length - (locations?.length || 0);
            return (
                <div>
                    <div>
                        <b>{displayStringForRoleType(showRole)}</b>
                    </div>
                    <div>
                        {locations.map((location) => (
                            <p>{location.location_name}</p>
                        ))}
                        {moreCount > 0 && (
                            <Button
                                onClick={() => this.editUser(row.original)}
                                appearance="link"
                            >
                                +{moreCount} more
                            </Button>
                        )}
                    </div>
                </div>
            );
        };
        const renderStatus = (row) => {
            return (
                <Switch
                    size="sm"
                    label={row.original.locked ? 'Locked' : 'Active'}
                    className="lock-user-button"
                    isSelected={!row.original.locked}
                    onChange={() => this.openStatusToggleModal(row.original)}
                />
            );
        };

        const renderActions = (row) => {
            return (
                <ButtonGroup buttonMargin={1}>
                    <Button
                        className="edit-user-button"
                        appearance="tertiary"
                        size="xs"
                        onClick={() => this.editUser(row.original)}
                    >
                        Edit
                    </Button>
                    <Button
                        className="remove-user-button"
                        appearance="tertiary-danger"
                        size="xs"
                        onClick={() => this.openDeleteModal(row.original)}
                    >
                        Delete
                    </Button>
                </ButtonGroup>
            );
        };

        return [
            {
                Header: 'First Name',
                accessor: 'first_name',
                sortable: true,
                Cell: ({ row }) => renderFirstName(row),
                minWidth: 70,
            },
            {
                Header: 'Last Name',
                accessor: 'last_name',
                sortable: true,
                Cell: ({ row }) => renderLastName(row),
                minWidth: 70,
            },
            {
                Header: 'Email',
                accessor: 'email',
                sortable: false,

                Cell: ({ row }) => renderEmail(row),
                minWidth: 250,
            },
            {
                Header: 'Roles',
                id: 'roles',
                sortable: false,
                accessor: (row) => {
                    return row.roles && row.roles.length ? row.roles[0]?.role_type : '';
                },
                Cell: ({ row }) => renderRoles(row),
                minWidth: 70,
            },

            {
                Header: 'Attestation Date',
                sortable: true,
                accessor: (row) => {
                    return this.props.searchField === LOCATION_NAME_FIELD_TYPE
                        ? row.roles[0]?.last_attested_at
                        : row?.last_attested_at;
                },
                minWidth: 120,
                maxWidth: 180,
                Cell: ({ row }) => this.getAttestationDate(row.original),
            },
            {
                Header: 'Status',
                accessor: (row) => (row.locked ? 'Locked' : 'Unlocked'),
                minWidth: 60,
                maxWidth: 120,
                sortable: true,
                Cell: ({ row }) => renderStatus(row),
            },
            {
                Header: 'Actions',
                minWidth: 145,
                maxWidth: 145,
                sortable: false,
                Cell: ({ row }) => renderActions(row),
            },
        ];
    }

    getTableSorting() {
        switch (this.props.searchField) {
            case LAST_NAME_FIELD_TYPE:
                return [{ id: 'first_name', desc: false }];
            case EMAIL_FIELD_TYPE:
            case LOCATION_NAME_FIELD_TYPE:
                return [
                    { id: 'last_name', desc: false },
                    { id: 'first_name', desc: false },
                ];
            default:
                return [{ id: 'last_name', desc: false }];
        }
    }

    editUser = (user) => {
        const editRoute = `/internal/account/edit-user/${user.user_id}`;
        this.props.history.push(editRoute);
    };

    creatingUser = () => {
        this.props.history.push('/internal/account/create-user');
        this.props.creatingUser();
    };

    openDeleteModal(user) {
        let itemsToDelete = [user];
        if (user.roles.some((role) => role.role_type === SYSTEM_USER)) {
            itemsToDelete = [];
        }
        if (user) {
            this.setState({
                modalIsOpen: true,
                showSystemUserWarn: itemsToDelete.length === 0,
                itemsToDelete,
            });
        }
    }

    openStatusToggleModal(user) {
        this.setState({
            statusToggleModalIsOpen: true,
            statusToggleUser: user,
        });

        this.props.setInitialValue();
        this.props.setUpdateUser(user);
    }

    onConfirmStatusToggleModal(note) {
        this.props.updateUserStatus(this.state.statusToggleUser, note);

        this.setState((prevState) => {
            const tempUser = prevState.statusToggleUser;
            tempUser.locked = !prevState.statusToggleUser.locked;

            return {
                ...prevState,
                statusToggleModalIsOpen: false,
                statusToggleUser: tempUser,
            };
        });
    }

    closeDeleteModal() {
        this.setState({ modalIsOpen: false, showSystemUserWarn: false });
    }

    deleteUsers(users, note) {
        this.props.deleteUsers(users, note);
    }

    runThrottleSearch(params, throttleString) {
        if (params.location_id && params.location_type) {
            const locationSearchParams = {
                location_id: params.location_id,
                location_type: params.location_type,
            };
            this.props.getUserList([locationSearchParams]);

            this.props.setAttestationState({ location: params, role: '' });
            this.setState({
                selectedLocation: params,
            });
        } else {
            this.props.getUserList([params]);
        }

        if (throttleString !== this.state.throttleString) {
            this.clearFilters.bind(this)();
        }

        this.setState({
            throttleString,
        });
    }

    viewAllUsers = () => {
        this.setState({ showThrottle: false });
        this.props.getUserList();
    };

    clearThrottleSearch() {
        this.props.clearUserList();
        this.props.setAttestationState({ location: {}, role: '' });

        this.setState({
            throttleString: '',
        });
    }

    notificationUI() {
        if (this.props.attestUsersError) {
            return (
                <Alert shouldFocusOnMount type="error">
                    {errorForUserAttestation()}
                </Alert>
            );
        }
        if (this.props.deleteUserError) {
            return (
                <Alert shouldFocusOnMount type="error">
                    {errorForDeletingUser(this.props.deleteUserError)}
                </Alert>
            );
        }
        if (this.props.deletedUsers && this.props.deletedUsers.length > 1) {
            return this.props.deletedUsers.map((user) => {
                return (
                    <Alert
                        shouldFocusOnMount
                        type="success"
                        className="rec-multiple-notifications"
                    >
                        {`${user.email} was deleted successfully`}
                    </Alert>
                );
            });
        }
        if (this.props.deletedUser) {
            return (
                <Alert type="success">{`${this.props.deletedUser.email} was deleted successfully`}</Alert>
            );
        }

        if (this.props.updateUserStatusErrorMessage) {
            return (
                <Alert shouldFocusOnMount type="error">
                    {this.props.updateUserStatusErrorMessage}
                </Alert>
            );
        }
        if (this.props.updateUserStatusSuccessful) {
            return <Alert type="success">{`Update user status was successfully!`}</Alert>;
        }

        return null;
    }

    showThrottleFromViewAll() {
        this.props.clearUserList();
        this.setState({ showThrottle: true });
    }

    renderViewAllClearer() {
        return (
            <div className="user-list-throttle">
                <button
                    type="button"
                    className="throttle-clearer"
                    onClick={this.showThrottleFromViewAll.bind(this)}
                >
                    <div>{`You searched for - View All Users`}</div>
                    <Icon iconName="close-circle" size="md" />
                </button>
            </div>
        );
    }

    renderTable() {
        const classes = classNames({
            'user-list-box': true,
            throttle: true,
            'has-data': this.props.dataLoaded,
        });

        let errorMessage = '';
        if (this.props.fetchUsersError) {
            errorMessage = errorForListingUsers(this.props.fetchUsersError);
        }
        return (
            <div className={classes}>
                {this.state.showThrottle && (
                    <UserListThrottle
                        id="UserThrottleSearch"
                        hasRan={this.props.dataLoaded}
                        run={this.runThrottleSearch.bind(this)}
                        clear={this.clearThrottleSearch.bind(this)}
                    />
                )}
                {!this.props.userRequiresThrottledSearch &&
                    this.state.showThrottle &&
                    this.state.throttleString === '' && (
                        <div className="user-list-view-all-users-wrap">
                            <div className="user-list-view-all-users">
                                <span>Or</span>
                                <Button onClick={this.viewAllUsers} appearance="tertiary">
                                    View All Users
                                </Button>
                            </div>
                        </div>
                    )}
                {!this.props.userRequiresThrottledSearch &&
                    !this.state.showThrottle &&
                    this.renderViewAllClearer()}
                {errorMessage.length > 0 && (
                    <Alert hasAriaLive type="error" id="user-list-error" className="mx-4">
                        {errorMessage}
                    </Alert>
                )}
                {errorMessage.length === 0 &&
                    (this.state.throttleString.length > 0 ||
                        !!this.props.userResults.length) && (
                        <div className="user-list-table-wrap">
                            {this.renderFiltersAndActions()}
                            <ReactTableWithLayout
                                noDataText={`No users found${
                                    this.state.throttleString.length
                                        ? ` for [ ${this.state.throttleString} ]`
                                        : ''
                                }`}
                                columns={this.getTableColumns()}
                                defaultSorted={this.getTableSorting()}
                                aria-label="User Management"
                                data={this.filterResults(
                                    this.props.userResults,
                                    this.state.filters?.roleFilter,
                                    this.state.filters?.statusFilter,
                                    this.state.filters?.textFilter,
                                    this.props.searchField === LOCATION_NAME_FIELD_TYPE
                                        ? this.props.searchValue
                                        : null
                                )}
                                isStriped={false}
                            />
                        </div>
                    )}
                <DeleteUserWarning
                    isOpen={this.state.modalIsOpen}
                    showSystemUserWarn={this.state.showSystemUserWarn}
                    users={this.state.itemsToDelete}
                    cancel={() => {
                        this.closeDeleteModal();
                    }}
                    confirm={(note) => {
                        this.closeDeleteModal();
                        this.deleteUsers(this.state.itemsToDelete, note);
                    }}
                />
                <UserStatusToggle
                    isOpen={this.state.statusToggleModalIsOpen}
                    selectedUser={this.state.statusToggleUser}
                    cancel={() => {
                        this.setState({
                            statusToggleModalIsOpen: false,
                        });
                    }}
                    confirm={this.onConfirmStatusToggleModal.bind(this)}
                />
            </div>
        );
    }

    render() {
        return (
            <SiteWrapper>
                <div className="page-title">
                    <UserManagementHeader editing={this.props.editing} />
                </div>
                <div id="UserManagement" className="page-content wrapper">
                    <div>
                        {this.notificationUI()}
                        {this.renderTable()}
                    </div>
                </div>
            </SiteWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        editing: state.userManagement.selectedTabIndex === 2,
        dataLoaded: state.fetchUsers.userResultsLoaded,
        dataReload: state.fetchUsers.userResultsReload,
        userResults: state.fetchUsers.userResults,
        fetchUsersError: state.fetchUsers.error,
        deleteUserError: state.updateUser.deleteUserError,
        deletedUser: state.updateUser.deletedUser,
        deletedUsers: state.updateUser.deletedUsers,
        attestUsersError: attestPostErrorSelector(state),
        userRequiresThrottledSearch: state.userManagement.throttle,
        managerRole: state.selectedLocationRole.role,
        searchField: state.userManagement.searchField,
        searchValue: state.userManagement.searchValue,
        filters: state.userManagement.filters,
        updateUserStatusErrorMessage: state.userManagement.updateUserStatusErrorMessage,
        updateUserStatusSuccessful: state.userManagement.updateUserStatusSuccessful,
        selectedIds: state.userManagement.selectedIds,
        loggedInUser: state.login.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUpdateUser: (user) => dispatch(setUpdateUser(user)),
        setInitialValue: () => dispatch(setInitialValue()),
        updateUserStatus: (user, note) => dispatch(updateUserStatus(user, note)),
        setTabIndex: (n) => dispatch(setTabIndexAction(n)),
        checkUserListThrottle: () => dispatch(checkUserListThrottleAction()),
        creatingUser: () => dispatch(creatingUser()),
        editUser: (account) => dispatch(editingUser(account.user_id)),
        deleteUsers: (user, note) => dispatch(deleteUsers(user, note)),
        clearUserList: () => dispatch(fetchUsersClear()),
        getUserList: (paramArray) => dispatch(fetchUsers(paramArray)),
        setAttestationState: (payload) => dispatch(setAttestationState(payload)),
        filterChanged: (payload) => dispatch(filterChanged(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListBox);
