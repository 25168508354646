/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { parseAbsoluteToLocal } from '@internationalized/date';
import {
    Button,
    ButtonGroup,
    FlexCol,
    FlexRow,
    useFormatDateTime,
} from '@fp/sarsaparilla';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'ui-internal-account/dev/store';
import { enablePasswordReset } from 'ui-internal-account/src/actions/resetPassword';
import { User } from 'ui-internal-account/src/constants/types';

interface PasswordSectionProps {
    user: User;
    loggedInUser: User | null;
    setShowToast: (show: boolean) => void;
}

export default function PasswordSection(props: PasswordSectionProps) {
    const { user, loggedInUser, setShowToast } = props;
    const dispatch = useDispatch<AppDispatch>();
    const fmtDt = useFormatDateTime();

    let formattedDate = 'never';
    if (user?.password_set_at) {
        formattedDate = fmtDt(parseAbsoluteToLocal(user.password_set_at), {
            format: 'LL',
        });
    }

    let formattedLastLoggedIn = 'never';
    if (user?.last_logged_in_at) {
        formattedLastLoggedIn = fmtDt(parseAbsoluteToLocal(user.last_logged_in_at), {
            format: 'LL',
        });
    }

    return (
        <div>
            <FlexRow>
                <FlexCol sm={3}>
                    <b>Password last set:</b>
                </FlexCol>
                <FlexCol sm={3}>{formattedDate}</FlexCol>
            </FlexRow>
            <FlexRow>
                <FlexCol sm={3}>
                    <b>Last logged In:</b>
                </FlexCol>
                <FlexCol sm={3}>{formattedLastLoggedIn}</FlexCol>
            </FlexRow>

            {loggedInUser?.user_id !== user?.user_id && user?.is_email_confirmed && (
                <FlexRow className="space-left mt-3 mb-1">
                    <FlexRow className="space-left">
                        <ButtonGroup isStretchedToFit>
                            <Button
                                appearance="tertiary"
                                onClick={async () => {
                                    await dispatch(enablePasswordReset(user?.user_id));
                                    setShowToast(true);
                                }}
                            >
                                Send Password Reset Email
                            </Button>
                        </ButtonGroup>
                    </FlexRow>
                </FlexRow>
            )}
        </div>
    );
}
