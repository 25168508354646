/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    HelmetWrapperInternal,
    EmailField,
    Alert,
    Button,
    Heading,
    Text,
    FormValidationAlert,
} from 'sarsaparilla';

import { recoverPassword } from '../actions/recoverPassword';
import { errorForPasswordRecovery } from '../utilities/errorMessages';

const propTypes = {
    resetPassword: PropTypes.shape({
        email: PropTypes.string,
        error: PropTypes.any,
        success: PropTypes.any,
    }),
    recover: PropTypes.func,
};

export class RecoverPasswordPage extends React.Component {
    static propTypes = propTypes;

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailValidation: null,
        };

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.resetPassword && this.props.resetPassword.email) {
            this.setState({ email: this.props.resetPassword.email });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        // Type safety only - this would only be missing if something went horribly wrong in the component
        if (!this.inputRef.current) return;

        const emailValidation = this.inputRef.current.validate();
        this.setState({ emailValidation });

        if (!emailValidation.isInvalid) {
            this.props.recover(this.state.email);
        }
    };

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    };

    response() {
        if (this.props.resetPassword && this.props.resetPassword.error) {
            return (
                <Alert shouldFocusOnMount type="error" className="my-3">
                    {errorForPasswordRecovery()}
                </Alert>
            );
        }
        if (this.props.resetPassword && this.props.resetPassword.success) {
            return (
                <Alert shouldFocusOnMount type="success" className="my-3">
                    <p>
                        An email has been sent to your inbox. Please allow up to 60
                        seconds for the email to appear and follow the instructions
                        provided within.
                    </p>
                </Alert>
            );
        }
        return null;
    }

    render() {
        return (
            <div className="logged-out-container">
                <HelmetWrapperInternal title="Forgot Password" />
                <form onSubmit={this.handleSubmit}>
                    <Heading
                        headingLevel={1}
                        appearance="h3"
                        className="mb-3"
                        headingAlign="center"
                    >
                        Forgot Password
                    </Heading>
                    <div>
                        <Text>
                            Enter your email address and we will send you an email with a
                            link to reset your password.
                        </Text>

                        {this.state.emailValidation?.isInvalid ? (
                            <FormValidationAlert
                                errors={[this.state.emailValidation]}
                                className="my-3"
                            />
                        ) : null}

                        <EmailField
                            id="forgot-password-email"
                            isLabelVisible={false}
                            placeholder="Email address"
                            ref={this.inputRef}
                            onChange={this.handleEmailChange}
                            value={this.state.email}
                            isRequired
                            className="my-3"
                        />
                    </div>

                    {this.response()}

                    <Button
                        type="submit"
                        id="recover"
                        shouldFitContainer
                        className="mb-3"
                        gaTrackingId="694744717423"
                    >
                        Reset
                    </Button>

                    <Text tag="div" align="center">
                        <Button
                            appearance="link"
                            id="back-to-login"
                            to="/internal/account/login"
                            gaTrackingId="694744717423"
                        >
                            Back to Login
                        </Button>
                    </Text>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        resetPassword: state.resetPassword,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        recover: (email) => dispatch(recoverPassword(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordPage);
