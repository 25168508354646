/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import internalAccountReducers from '../src/reducers';

import {
    middleware as idleMiddleware,
    storeSubscription as idleStoreSubscription,
} from '../src/utilities/redux-idle-monitor';

const rootReducer = combineReducers({
    ...internalAccountReducers(),
});

export function setupStore(preloadedState?: any) {
    const newStore = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware({
                immutableCheck: {
                    // TODO: there's a mutation in machineApi that needs to be fixed that causes an annoying popup...
                    ignoredPaths: ['machineApi', 'concessionaireHierarchy'],
                },
                serializableCheck: {
                    ignoredActions: [
                        // ignoring all redux-persist actions as specified in the docs:
                        // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
                        FLUSH,
                        REHYDRATE,
                        PAUSE,
                        PERSIST,
                        PURGE,
                        REGISTER,
                        'launchdarkly/setClient',
                    ],
                    ignoredPaths: [
                        'launchdarkly', // Has ld client object in reducer
                    ],
                },
            }).concat(idleMiddleware);
        },
        preloadedState,
        devTools: process.env.DEV_TOOLS_ENABLED,
    });

    newStore.subscribe(() => idleStoreSubscription(newStore));

    return newStore;
}

export const store = setupStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;

// cSpell:ignore reduxjs
