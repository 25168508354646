/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { get } from 'lodash';
import { Dispatch } from 'react';
import { Action } from 'redux';
import { IDLESTATUS_WARNING } from './constants';

import { displayAutoLogoutWarning } from '../../actions/login';

const secondsToMilliseconds = (seconds: number): number => {
    return seconds * 1000;
};

// this is how long the Warning modal displays, so we need to display it early enough that they are still logged in when they get the modal
const warningLimit: number = 60;

export const idleStatusDelay =
    (idleStatus: string) =>
    (dispatch: Dispatch<Action>, getState: Function): number | undefined => {
        const state = getState();

        const inactivityLimitSeconds =
            get(state, 'login.account.inactivity_limit_seconds', null) - warningLimit;

        if (idleStatus === IDLESTATUS_WARNING) {
            return secondsToMilliseconds(inactivityLimitSeconds);
        }

        return undefined;
    };

export const idleStatusAction =
    (idleStatus: string) =>
    (dispatch: Dispatch<Action>): void => {
        if (idleStatus === IDLESTATUS_WARNING) {
            dispatch(displayAutoLogoutWarning());
        }
    };
