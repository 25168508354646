/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import axios from 'axios';
import * as types from '../constants/types';
import * as globals from '../constants/globals';
import { JSONContentType } from './login';

export const recoverPassword = (email) => {
    return (dispatch) => {
        const data = {
            email,
        };

        return axios
            .post(`${globals.API_URL}/recover-password`, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': JSONContentType,
                },
            })
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return Promise.resolve(response);
                }
                return Promise.reject(new Error(response.statusText));
            })
            .then(() => {
                dispatch({
                    type: types.RECOVER_PASSWORD_SUCCESSFUL,
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.RECOVER_PASSWORD_FAILED,
                    error,
                });
            });
    };
};
