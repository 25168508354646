/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { useSelector } from 'react-redux';
import { User } from 'ui-internal-account/src/constants/types';

type State = {
    login: {
        user: User;
    };
};

export default function useLoggedInUser(): User {
    return useSelector((state: State): User => state.login?.user);
}
