/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';
import * as errors from '../constants/errors';
import { makeAuthorizedRequest } from './login';
import { doneLoading, startLoading } from './loading';

export const getMachineApiKeysFailed = (error) => {
    return {
        type: types.GET_MACHINE_API_KEYS_FAILED,
        getMachineApiKeysError: true,
        error,
    };
};

export const getMachineApiKeys = async (dispatch) => {
    try {
        const url = `${process.env.API}/developer-portal/machine_api_keys`;
        const response = await makeAuthorizedRequest(url, 'GET', dispatch);
        return response.api_keys;
    } catch (error) {
        dispatch(getMachineApiKeysFailed(errors.GET_MACHINE_API_KEYS_FAILED));
        return false;
    }
};

export const generateMachineApiKeyFailed = (error) => {
    return {
        type: types.GENERATE_MACHINE_API_KEY_FAILED,
        generateMachineApiKeyError: true,
        error,
    };
};

export const postMachineApiKey = async (dispatch) => {
    try {
        const url = `${process.env.API}/developer-portal/machine_api_keys/generate`;
        const response = await makeAuthorizedRequest(url, 'POST', dispatch);
        return response.api_key;
    } catch (error) {
        dispatch(generateMachineApiKeyFailed(errors.GENERATE_MACHINE_API_KEY_FAILED));
        return false;
    }
};

export const disableMachineApiKeyFailed = (error) => {
    return {
        type: types.DISABLE_MACHINE_API_KEY_FAILED,
        disableMachineApiKeyError: true,
        error,
    };
};

export const deleteMachineApiKey = async (dispatch, id) => {
    try {
        const url = `${process.env.API}/developer-portal/machine_api_keys/disable/${id}`;
        const response = await makeAuthorizedRequest(url, 'DELETE', dispatch);
        return response.api_key;
    } catch (error) {
        dispatch(disableMachineApiKeyFailed(errors.DISABLE_MACHINE_API_KEY_FAILED));
        return false;
    }
};

export const getMachineApiPermissionsFailed = (error) => {
    return {
        type: types.GET_MACHINE_API_PERMISSIONS_FAILED,
        getMachineApiPermissionsError: true,
        error,
    };
};

export const getMachineApiPermissions = async (dispatch, facilityId, userId) => {
    try {
        const url = `${process.env.API}/developer-portal/internal/facilities/${facilityId}/master_machine_api_keys/${userId}`;
        const response = await makeAuthorizedRequest(url, 'GET', dispatch);
        return response.api_key;
    } catch (error) {
        dispatch(
            getMachineApiPermissionsFailed(errors.GET_MACHINE_API_PERMISSIONS_FAILED)
        );
        return false;
    }
};

export const putMachineApiPermissionsFailed = (error) => {
    return {
        type: types.UPDATE_MACHINE_API_PERMISSIONS_FAILED,
        updateMachineApiPermissionsError: true,
        error,
    };
};

export const putMachineApiPermissions = async (dispatch, facilityId, userId, payload) => {
    try {
        const url = `${process.env.API}/developer-portal/internal/facilities/${facilityId}/master_machine_api_keys/${userId}`;
        const response = await makeAuthorizedRequest(url, 'PUT', dispatch, payload);
        return response.api_key;
    } catch (error) {
        dispatch(
            putMachineApiPermissionsFailed(errors.UPDATE_MACHINE_API_PERMISSIONS_FAILED)
        );
        return false;
    }
};

export const fetchMachineApiKeys = () => {
    return async (dispatch) => {
        dispatch(startLoading());
        const keys = await getMachineApiKeys(dispatch);
        if (keys) {
            dispatch({
                machineApiKeys: keys,
                type: types.GET_MACHINE_API_KEYS_SUCCESSFUL,
            });
        }
        dispatch(doneLoading());
    };
};

export const generateMachineApiKey = () => {
    return async (dispatch) => {
        dispatch(startLoading());
        const key = await postMachineApiKey(dispatch);
        if (key) {
            dispatch({
                machineApiKeys: [key],
                type: types.GENERATE_MACHINE_API_KEY_SUCCESSFUL,
            });
        }
        dispatch(doneLoading());
    };
};

export const disableMachineApiKey = (id) => {
    return async (dispatch) => {
        dispatch(startLoading());
        const key = await deleteMachineApiKey(dispatch, id);
        if (key) {
            dispatch({
                type: types.DISABLE_MACHINE_API_KEY_SUCCESSFUL,
            });
        }
        dispatch(doneLoading());
    };
};

export const fetchMachineApiPermissions = (facilityId, userId) => {
    return async (dispatch) => {
        dispatch(startLoading());
        dispatch({
            type: types.CLEAR_MACHINE_API_KEY_ERRORS,
        });
        const key = await getMachineApiPermissions(dispatch, facilityId, userId);
        if (key) {
            dispatch({
                machineApiPermissions: key,
                type: types.GET_MACHINE_API_PERMISSIONS_SUCCESSFUL,
            });
        }
        dispatch(doneLoading());
    };
};

export const updateMachineApiPermissions = (facilityId, userId, payload) => {
    return async (dispatch) => {
        dispatch(startLoading());
        dispatch({
            type: types.CLEAR_MACHINE_API_KEY_ERRORS,
        });
        const key = await putMachineApiPermissions(dispatch, facilityId, userId, payload);
        if (key) {
            dispatch({
                type: types.UPDATE_MACHINE_API_PERMISSIONS_SUCCESSFUL,
            });
        }
        dispatch(doneLoading());
    };
};

export const fetchLocationInfo = (location) => {
    return async (dispatch) => {
        dispatch(startLoading());
        dispatch({
            location,
            type: types.GET_LOCATION_INFO,
        });
        dispatch(doneLoading());
    };
};
