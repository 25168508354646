/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    managedLocations: [],
};

const fetchManagedLocations = (state = initialState, action) => {
    switch (action.type) {
        case types.CANCEL_UPDATE:
        case types.ACCOUNT_SAVED:
        case types.CREATING_USER:
        case types.EDITING_USER:
            return { ...state, managedLocations: [] };
        case types.LOADING_MANAGED_LOCATIONS_SUCCESSFUL:
            return { ...state, managedLocations: action.locations };
        case types.DELETE_LOCATION:
            return {
                ...state,
                managedLocations: [action.location, ...state.managedLocations],
            };
        case types.ASSIGN_LOCATION: {
            const managedIndexToDelete = state.managedLocations.indexOf(action.location);
            const managedLocations =
                managedIndexToDelete >= 0
                    ? [
                          ...state.managedLocations.slice(0, managedIndexToDelete),
                          ...state.managedLocations.slice(managedIndexToDelete + 1),
                      ]
                    : state.managedLocations;

            return { ...state, error: null, managedLocations };
        }
        default:
            return state;
    }
};

export default fetchManagedLocations;
