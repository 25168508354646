/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

export const setConcessionaire = (concessionaire) => {
    return {
        concessionaire,
        type: types.SET_MANAGED_CONCESSIONAIRE,
    };
};

export const setPermit = (permit) => {
    return {
        permit,
        type: types.SET_CONCESSIONAIRE_PERMIT,
    };
};
