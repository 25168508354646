/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';
import { LAST_NAME_FIELD_TYPE } from '../constants/globals';

export const initialState = {
    selectedTabIndex: 0,
    throttle: false,
    searchField: LAST_NAME_FIELD_TYPE,
    filters: {},
    updateUserStatusErrorMessage: '',
    updateUserStatusSuccessful: false,
    selectedIds: {},
};

const userManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CANCEL_UPDATE:
            return { ...state, selectedTabIndex: 0 };
        case types.CREATING_USER:
            return { ...state, selectedTabIndex: 1 };
        case types.EDITING_USER:
            return { ...state, selectedTabIndex: 2 };
        case types.SET_SELECTED_TAB_INDEX:
            return { ...state, selectedTabIndex: action.index };
        case types.THROTTLE_USER_LIST:
            return { ...state, throttle: action.throttle };
        case types.USER_SEARCH_FIELD_CHANGED:
            return { ...state, searchField: action.field };
        case types.USER_SEARCH_VALUE_CHANGED:
            return { ...state, searchValue: action.value };
        case types.USER_SEARCH_FILTER_CHANGED:
            return { ...state, filters: { ...action.value.filters } };
        case types.LOGGED_OUT:
            return initialState;
        case types.SET_NOTE_ADDED_FOR_EXISTING_USER:
            return {
                ...state,
                updateUserStatusErrorMessage: '',
                updateUserStatusSuccessful: false,
            };
        case types.UPDATE_USER_STATUS_FAILED:
            return {
                ...state,
                updateUserStatusErrorMessage: action.errorMessage,
                updateUserStatusSuccessful: false,
            };
        case types.UPDATE_USER_STATUS_SUCCESSFUL:
            return {
                ...state,
                updateUserStatusErrorMessage: '',
                updateUserStatusSuccessful: true,
            };
        default:
            return state;
    }
};

export default userManagementReducer;
