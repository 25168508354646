/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    Icon,
    TYPES,
    Button,
    TextFieldStateless,
    Spacer,
    ButtonGroup,
    Checkbox,
    Alert,
} from 'sarsaparilla';
import { getAgencyNamesFromLocations } from '../../utilities/locations';
import { errorForConcessionaires } from '../../utilities/errorMessages';
import ActionErrorDisplay from '../ActionErrorDisplay';
import ColumnFilterTable from '../tableUtil/ColumnFilterTable';
import TextHighlight from '../tableUtil/TextHighlight';

const propTypes = {
    history: TYPES.HISTORY,
    gridData: PropTypes.array,
    concessionaire: PropTypes.any,
    setPermit: PropTypes.func,
    error: PropTypes.any,
};

export default function ConcessionaireDetails({
    history,
    gridData,
    concessionaire,
    setPermit,
    error,
}) {
    const navEditPermit = useCallback(
        (permit) => {
            setPermit(permit || {});
            history.push('/internal/account/concessionaire-permit-edit');
        },
        [setPermit, history]
    );

    const renderPermitId = useCallback(
        (row, value, globalFilter) => {
            return (
                <Button onClick={() => navEditPermit(row.original)} appearance="link">
                    <TextHighlight searchTerm={globalFilter || ''} body={value} />
                </Button>
            );
        },
        [navEditPermit]
    );

    const renderFacilityId = (value, globalFilter) => {
        return <TextHighlight searchTerm={globalFilter || ''} body={value} />;
    };

    const renderFacilityName = (value, globalFilter) => {
        return <TextHighlight searchTerm={globalFilter || ''} body={value} />;
    };

    const renderDaysUntilExpiration = (row) => {
        const days = -1 * moment({ hours: 0 }).diff(row.original.permit_end_date, 'days');
        const classes =
            days <= 60 && days >= 0 ? 'datagrid-cell-box ia-urgent' : 'datagrid-cell-box';
        return <div className={classes}>{days < 0 ? 'EXPIRED' : days.toString()}</div>;
    };

    const renderActions = useCallback(
        (row) => {
            return (
                <Button onClick={() => navEditPermit(row.original)} appearance="link">
                    Edit
                </Button>
            );
        },
        [navEditPermit]
    );

    const renderFilter = (filterValue, setFilter, id) => {
        return (
            <Checkbox
                label="Show Only Active Permits"
                isChecked={filterValue}
                onChange={() => setFilter(!filterValue)}
                id={`filter-${id}`}
            />
        );
    };

    const tableColumns = useMemo(
        () => [
            {
                Header: 'Permit ID',
                id: 'permit_id',
                accessor: (row) => row.permit_id.toString(),
                Cell: ({ row, value, state: { globalFilter } }) =>
                    renderPermitId(row, value, globalFilter),
                disableFilters: true,
                width: 50,
                minWidth: 50,
            },
            {
                Header: 'Facility ID',
                id: 'location_id',
                accessor: (row) => row.location_id.toString(),
                Cell: ({ value, state: { globalFilter } }) =>
                    renderFacilityId(value, globalFilter),
                disableFilters: true,
                width: 50,
                minWidth: 50,
            },
            {
                Header: 'Facility Name',
                accessor: 'location_name',
                Cell: ({ value, state: { globalFilter } }) =>
                    renderFacilityName(value, globalFilter),
                disableFilters: true,
                width: 120,
                minWidth: 120,
            },
            {
                Header: 'Permit Start Date',
                id: 'permit_start_date',
                accessor: (row) => moment(row.permit_start_date).utc().format('YYYYMMDD'),
                Cell: ({ row }) =>
                    moment(row.original.permit_start_date).utc().format('MM/DD/YYYY'),
                disableGlobalFilter: true,
                disableFilters: true,
                width: 70,
                minWidth: 70,
            },
            {
                Header: 'Permit Expiration Date',
                id: 'permit_end_date',
                accessor: (row) => moment(row.permit_end_date).utc().format('YYYYMMDD'),
                Cell: ({ row }) =>
                    moment(row.original.permit_end_date).utc().format('MM/DD/YYYY'),
                disableGlobalFilter: true,
                disableFilters: true,
                width: 70,
                minWidth: 70,
            },
            {
                Header: 'Days Until Expiration',
                id: 'days_until_expiration',
                accessor: (row) =>
                    -1 * moment({ hours: 0 }).diff(row.permit_end_date, 'days'),
                Cell: ({ row }) => renderDaysUntilExpiration(row),
                disableGlobalFilter: true,
                filter: (rows, id, filterValue) =>
                    rows.filter((row) => !filterValue || row.values[id] >= 0),
                Filter: ({ column: { filterValue, setFilter, id } }) =>
                    renderFilter(filterValue, setFilter, id),
                width: 70,
                minWidth: 70,
            },
            {
                Header: 'Actions',
                id: 'actions',
                sortable: false,
                Cell: ({ row }) => renderActions(row),
                disableGlobalFilter: true,
                disableFilters: true,
                width: 70,
                maxWidth: 70,
                minWidth: 70,
            },
        ],
        [renderPermitId, renderActions]
    );

    const filterUI = useCallback(
        ({ setGlobalFilter, globalFilter }) => (
            <TextFieldStateless
                label="Search for..."
                isLabelVisible={false}
                placeholder="Search for..."
                onChange={({ target: { value } }) => setGlobalFilter(value)}
                handleClearButtonClick={() => setGlobalFilter('')}
                hasClearButton
                value={globalFilter || ''}
                iconElement={<Icon iconName="search" />}
                id="filter-text-search"
            />
        ),
        []
    );

    function navEditConcessionaire() {
        setPermit(null);
        history.push('/internal/account/concessionaire-edit');
    }

    function formatPhoneForDisplay(phone) {
        let formatted = phone;
        if (RegExp(/\d{10}/).test(phone)) {
            formatted = formatted.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3');
        }
        return formatted;
    }

    function renderDetailPair(label, key, applyFormat) {
        const value = concessionaire[key];

        let text = '- -';
        if (value) {
            text = applyFormat ? applyFormat(value) : value;
        }

        return (
            <div className="concessionaires-detail-box-pair">
                <div className="concessionaires-detail-box-pair-label">{label}</div>
                <div className="concessionaires-detail-box-pair-value">{text}</div>
            </div>
        );
    }

    if (!concessionaire) {
        return (
            <Alert shouldFocusOnMount type="error" className="concessionaires-error">
                Unable to find the concessionaire you wish to edit.
            </Alert>
        );
    }

    let name = concessionaire.concessionaire_name || '';
    name = name.replaceAll(concessionaire.concessionaire_id, ' ');

    return (
        <div className="concessionaire-details-wrapper">
            <div className="concessionaires-header">
                <h2 className="concessionaire-component-title h5">
                    <span className="header-main-title">Concessionaire Name</span>
                    <span className="header-break" />
                    <span className="header-sub-title">{name}</span>
                </h2>
                <div className="concessionaire-controls">
                    <button
                        type="button"
                        className="ia-edit-concessionaire rec-button-primary"
                        onClick={() => navEditConcessionaire(concessionaire)}
                    >
                        <div className="icon-holder">
                            <Icon iconName="edit" />
                        </div>
                        <span>Edit Concessionaire</span>
                    </button>
                </div>
            </div>

            <div className="concessionaires-detail-box">
                <div className="concessionaires-detail-box-left">
                    <div className="concessionaires-detail-box-top">
                        {renderDetailPair('Concessionaire ID', 'concessionaire_id')}
                    </div>
                    <div className="concessionaires-detail-box-bottom">
                        {renderDetailPair('Agency', 'permits', (value) =>
                            getAgencyNamesFromLocations(value).join(', ')
                        )}
                    </div>
                </div>
                <div className="concessionaires-detail-box-right">
                    <div className="concessionaires-detail-box-top">
                        {renderDetailPair('Primary Contact', 'primary_contact_name')}
                        {renderDetailPair(
                            'Phone',
                            'primary_contact_phone',
                            formatPhoneForDisplay
                        )}
                        {renderDetailPair('Email', 'primary_contact_email')}
                    </div>
                    <div className="concessionaires-detail-box-bottom">
                        {renderDetailPair('Secondary Contact', 'secondary_contact_name')}
                        {renderDetailPair(
                            'Phone',
                            'secondary_contact_phone',
                            formatPhoneForDisplay
                        )}
                        {renderDetailPair('Email', 'secondary_contact_email')}
                    </div>
                </div>
            </div>

            <ActionErrorDisplay
                error={error}
                errorStringMapping={errorForConcessionaires}
            />

            <Spacer size="lg" />

            <ButtonGroup align="right">
                <Button
                    iconBeforeElement={<Icon iconName="add-circle" />}
                    onClick={navEditPermit}
                >
                    Add New Permit
                </Button>
            </ButtonGroup>

            <Spacer size="sm" />
            <ColumnFilterTable
                data={gridData || []}
                columns={tableColumns}
                filtersComponent={filterUI}
                noDataText={`Found no permits for ${concessionaire.concessionaire_name}.`}
                defaultSortId="location_name"
                tableCaption="concessionaire"
            />
        </div>
    );
}

ConcessionaireDetails.propTypes = propTypes;
