/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from 'react';

// Redirect to dev if on localhost, otherwise redirect to hosted site
export default function UnsupportedBrowserRedirect() {
    useEffect(() => {
        const redirectRoot = document.location.origin.includes('localhost')
            ? 'https://r1s-dev.com'
            : process.env.SITE_ROOT;

        window.location.assign(`${redirectRoot}/unsupported-browser`);
    }, []);

    return <div>Redirecting...</div>;
}
