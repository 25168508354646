/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import qs from 'query-string';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, HelmetWrapperInternal, Text, useFlags } from 'sarsaparilla';
import {
    AvailableOidcProviders,
    oidcRedirect,
} from 'ui-internal-account/src/actions/oidc';
import useOidcConfig from 'ui-internal-account/src/hooks/useOidcConfig';
import { confirmEmail } from '../actions/confirmEmail';
import { logOut, resetSession } from '../actions/login';
import { freshAccountSetPassword } from '../actions/resetPassword';
import LoginMethods from '../components/login/loginMethods';
import PasswordForm from '../components/PasswordForm';
import { errorForConfirmEmail } from '../utilities/errorMessages';

type ConfirmEmailActions = {
    confirmEmailAction: (token: string | undefined, history: any) => void;
    freshAccountSetPasswordAction: (
        token: string,
        password: string,
        confirmPassword: string,
        history: any
    ) => void;
    logOutAction: () => void;
    resetSessionAction: () => void;
};

type User = {
    must_set_password: boolean;
};

type ConfirmEmailPageState = {
    confirmEmail: {
        token: string;
        user: User;
        error: object;
    };
};

type ConfirmEmailPageProps = ConfirmEmailActions & ConfirmEmailPageState;

function ConfirmEmailPage({
    confirmEmail: confirmEmailProp,
    confirmEmailAction,
    freshAccountSetPasswordAction,
    logOutAction,
    resetSessionAction,
}: ConfirmEmailPageProps) {
    const { enableOidc } = useFlags();
    const history = useHistory();
    const [showConfirmMethods, setShowConfirmMethods] = React.useState<boolean | null>(
        null
    );

    const oidcCfg = useOidcConfig();

    const handleConfirmEmail = () => {
        const queryParams = qs.parse(history.location.search);
        const token = queryParams.token;
        confirmEmailAction(token, history);
    };

    React.useEffect(() => {
        if (enableOidc === true) {
            setShowConfirmMethods(true);
        }

        resetSessionAction();
        if (
            showConfirmMethods === false ||
            (enableOidc === false && showConfirmMethods === null)
        ) {
            handleConfirmEmail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enableOidc]);

    const doLogOut = () => {
        logOutAction();
        history.push('/internal/account/login');
    };

    const error = () => {
        if (confirmEmailProp && confirmEmailProp.error) {
            return (
                <div className="rec-alert rec-alert-danger" role="alert">
                    <div className="rec-alert-header">
                        <h4>
                            <i
                                className="fa fa-exclamation-circle m_R-2x"
                                aria-hidden="true"
                            />
                            {errorForConfirmEmail()}
                        </h4>
                    </div>
                    <div className="rec-alert-body">
                        <p>
                            <Button
                                appearance="link"
                                id="back-to-login"
                                onClick={doLogOut}
                            >
                                Back to Login Page.
                            </Button>
                        </p>
                    </div>
                </div>
            );
        }
        return null;
    };

    const renderConfirmAccount = () => {
        return (
            <div className="confirm-email-component">
                <div className="usa-grid usa-grid-centered">
                    <div className="usa-width-five-twelfths">
                        <div className="confirm-email-message">Confirming email...</div>
                    </div>
                </div>
            </div>
        );
    };

    const handleHubButton = () => {
        setShowConfirmMethods(false);
        handleConfirmEmail();
    };
    const handleOidcButton = () => {
        setShowConfirmMethods(true);
        oidcRedirect(oidcCfg, AvailableOidcProviders.loginDotGov);
    };

    const renderConfirmMethods = () => {
        return (
            <>
                <h1>Confirm Account</h1>
                {enableOidc && (
                    <Text fontWeight="bold">
                        Select the login method recommended by your Agency to access the{' '}
                        <a href="/internal/account/hub">{process.env.SITE_NAME} Hub.</a>
                    </Text>
                )}
                <LoginMethods
                    handleHubButton={handleHubButton}
                    handleOidcButton={handleOidcButton}
                />
            </>
        );
    };

    const user = confirmEmailProp ? confirmEmailProp.user : null;

    if (user != null) {
        if (user.must_set_password) {
            return (
                <PasswordForm
                    token={confirmEmailProp.token}
                    resetPassword={confirmEmailProp}
                    reset={freshAccountSetPasswordAction}
                    logOut={logOutAction}
                />
            );
        }
        return null;
    }

    const errorHtml = error();
    if (errorHtml !== null) {
        return (
            <div className="logged-out-container">
                <HelmetWrapperInternal title="Confirm Email" />
                <div className="confirm-email-component">
                    <div className="usa-grid usa-grid-centered">
                        <div className="usa-width-five-twelfths">{errorHtml}</div>
                    </div>
                </div>
            </div>
        );
    }
    const renderContent = () => {
        if (showConfirmMethods === null) {
            return null;
        }

        return showConfirmMethods ? renderConfirmMethods() : renderConfirmAccount();
    };

    return (
        <div className="logged-out-container">
            <HelmetWrapperInternal title="Confirm Email" />
            {renderContent()}
        </div>
    );
}

const mapStateToProps = (state: ConfirmEmailPageState) => {
    return {
        confirmEmail: state.confirmEmail,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            logOutAction: logOut,
            confirmEmailAction: confirmEmail,
            freshAccountSetPasswordAction: freshAccountSetPassword,
            resetSessionAction: resetSession,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailPage);
