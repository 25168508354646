/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { CalendarDate, CalendarDateTime, ZonedDateTime } from '@internationalized/date';
import { Moment } from 'moment';
import { Action } from 'redux';

/**
 * These constants are used elsewhere, but never defined. Adding them here
 * as undefined to stop console warnings. - Michael Stanaland, 10/9/2019
 */
export const RELOAD_MANAGED_CONCESSIONAIRES_SET: undefined = undefined;
export const RELOAD_MANAGED_COOPERATORS_SET: undefined = undefined;
export const SAVING_MANAGED_CONCESSIONAIRES_FAILED: undefined = undefined;

export const REC_AREA_LOCATION_TYPE: string = 'Rec Area';
export const LOGGED_OUT: string = 'LOGGED_OUT';
export const LOGIN_SUCCESSFUL: string = 'LOGIN_SUCCESSFUL';
export const MFA_VALIDATE_REQUIRED: string = 'MFA_VALIDATE_REQUIRED';
export const MFA_ENROLL_REQUIRED: string = 'MFA_ENROLL_REQUIRED';
export const LOGIN_FAILED: string = 'LOGIN_FAILED';
export const CLEAR_LOGIN_ERROR: string = 'CLEAR_LOGIN_ERROR';
export const SET_EMAIL: string = 'SET_EMAIL';
export const DISPLAY_AUTO_LOGOUT_WARNING: string = 'DISPLAY_AUTO_LOGOUT_WARNING';
export const CLOSE_AUTO_LOGOUT_WARNING: string = 'CLOSE_AUTO_LOGOUT_WARNING';
export const LOGGED_OUT_FOR_INACTIVITY: string = 'LOGGED_OUT_FOR_INACTIVITY';
export const LOGGED_OUT_FOR_MUST_SET_PASSWORD: string =
    'LOGGED_OUT_FOR_MUST_SET_PASSWORD';
export const LOGGED_OUT_FOR_MUST_RESET_PASSWORD: string =
    'LOGGED_OUT_FOR_MUST_RESET_PASSWORD';

export const VALIDATE_SUCCESSFUL: string = 'VALIDATE_SUCCESSFUL';
export const VALIDATE_SUCCESSFUL_MFA: string = 'VALIDATE_SUCCESSFUL_MFA';
export const MFA_RESET_PASSWORD_SUCCESSFUL: string = 'MFA_RESET_PASSWORD_SUCCESSFUL';
export const PASSWORD_RESET: string = 'PASSWORD_RESET';
export const RESET_PASSWORD_FAILED: string = 'RESET_PASSWORD_FAILED';
export const RECOVER_PASSWORD_SUCCESSFUL: string = 'RECOVER_PASSWORD_SUCCESSFUL';
export const RECOVER_PASSWORD_FAILED: string = 'RECOVER_PASSWORD_FAILED';
export const CHANGE_PASSWORD_FAILED: string = 'CHANGE_PASSWORD_FAILED';
export const CHANGE_PASSWORD_SUCCESSFUL: string = 'CHANGE_PASSWORD_SUCCESSFUL';
export const CHANGE_PASSWORD_CANCELED: string = 'CHANGE_PASSWORD_CANCELED';
export const EMAIL_NOT_VERIFIED: string = 'EMAIL_NOT_VERIFIED';
export const FOUND_NEW_USER: string = 'FOUND_NEW_USER';
export const RESET_UPDATE_USER: string = 'RESET_UPDATE_USER';

export const NAVIGATE_USERS_MANAGER: string = 'NAVIGATE_USERS_MANAGER';
export const NAVIGATE_INVENTORY_MANAGER: string = 'NAVIGATE_INVENTORY_MANAGER';
export const NAVIGATE_RESERVATION_MANAGER: string = 'NAVIGATE_RESERVATION_MANAGER';
export const NAVIGATE_LINK_MANAGER: string = 'NAVIGATE_LINK_MANAGER';

export const FETCH_USERS_CLEAR: string = 'FETCH_USERS_CLEAR';
export const FETCH_USERS_SUCCESS: string = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILED: string = 'FETCH_USERS_FAILED';
export const THROTTLE_USER_LIST: string = 'THROTTLE_USER_LIST';

export const FETCH_USER_NOTES_SUCCESS: string = 'FETCH_USER_NOTES_SUCCESS';
export const FETCH_USER_NOTES_FAILED: string = 'FETCH_USER_NOTES_FAILED';
export const ADD_NOTE_FAILED: string = 'ADD_NOTE_FAILED';
export const STORE_NOTE_FOR_CREATE: string = 'STORE_NOTE_FOR_CREATE';
export const NOTE_ADDED_FOR_EXISTING_USER: string = 'NOTE_ADDED_FOR_EXISTING_USER';
export const SET_NOTE_ADDED_FOR_EXISTING_USER: string =
    'SET_NOTE_ADDED_FOR_EXISTING_USER';
export const UPDATE_USER_STATUS_FAILED: string = 'UPDATE_USER_STATUS_FAILED';
export const UPDATE_USER_STATUS_SUCCESSFUL: string = 'UPDATE_USER_STATUS_SUCCESSFUL';
export const SET_SEARCH_BY_LOCATION_FLAG: string = 'SET_SEARCH_BY_LOCATION_FLAG';

export const ACCOUNT_SAVED: string = 'ACCOUNT_SAVED';
export const CANCEL_UPDATE: string = 'CANCEL_UPDATE';
export const CREATING_USER: string = 'CREATING_USER';
export const CREATING_CONCESSIONAIRE_USER: string = 'CREATING_CONCESSIONAIRE_USER';
export const EDITING_USER: string = 'EDITING_USER';
export const SAVE_FAILED: string = 'SAVE_FAILED';
export const ASSIGN_LOCATION: string = 'ASSIGN_LOCATION';
export const DELETE_LOCATION: string = 'DELETE_LOCATION';
export const CANCEL_ASSIGNING_LOCATIONS: string = 'CANCEL_ASSIGNING_LOCATIONS';
export const CONTINUE_FROM_ASSIGNING_LOCATIONS: string =
    'CONTINUE_FROM_ASSIGNING_LOCATIONS';
export const LOADING_MANAGED_LOCATIONS_SUCCESSFUL: string =
    'LOADING_MANAGED_LOCATIONS_SUCCESSFUL';
export const LOADING_MANAGED_LOCATIONS_FAILED: string =
    'LOADING_MANAGED_LOCATIONS_FAILED';
export const ASSIGN_CONCESSIONAIRE: string = 'ASSIGN_CONCESSIONAIRE';
export const DELETE_CONCESSIONAIRE: string = 'DELETE_CONCESSIONAIRE';
export const CANCEL_ASSIGNING_CONCESSIONAIRES: string =
    'CANCEL_ASSIGNING_CONCESSIONAIRES';
export const CONTINUE_FROM_ASSIGNING_CONCESSIONAIRES: string =
    'CONTINUE_FROM_ASSIGNING_CONCESSIONAIRES';
export const CONTINUE_FROM_CREATING_USER: string = 'CONTINUE_FROM_CREATING_USER';
export const ROLE_ASSIGNED: string = 'ROLE_ASSIGNED';
export const ROLE_DELETED: string = 'ROLE_DELETED';
export const ROLE_DELETE_FAILED: string = 'ROLE_DELETE_FAILED';
export const USER_DELETED: string = 'USER_DELETED';
export const USER_DELETE_FAILED: string = 'USER_DELETE_FAILED';
export const UPDATING_ROLES_SUCCESSFUL: string = 'UPDATING_ROLES_SUCCESSFUL';
export const SETTING_DEFAULT_ROLE_SUCCESSFUL: string = 'SETTING_DEFAULT_ROLE_SUCCESSFUL';
export const REQUEST_CONFIRM_STATUS: string = 'REQUEST_CONFIRM_STATUS';
export const RESET_CONFIRM_STATUS: string = 'RESET_CONFIRM_STATUS';
export const RESET_RESET_STATUS: string = 'RESET_RESET_STATUS';
export const GET_USER_FAILED: string = 'GET_USER_FAILED';
export const GETTING_USER: string = 'GETTING_USER';
export const GET_LOCATION_INFO: string = 'GET_LOCATION_INFO';
export const GET_MACHINE_API_KEYS_SUCCESSFUL: string = 'GET_MACHINE_API_KEYS_SUCCESSFUL';
export const GET_MACHINE_API_KEYS_FAILED: string = 'GET_MACHINE_API_KEYS_FAILED';
export const GET_MACHINE_API_PERMISSIONS_SUCCESSFUL: string =
    'GET_MACHINE_API_PERMISSIONS_SUCCESSFUL';
export const GET_MACHINE_API_PERMISSIONS_FAILED: string =
    'GET_MACHINE_API_GET_MACHINE_API_PERMISSIONS_FAILED';
export const UPDATE_MACHINE_API_PERMISSIONS_SUCCESSFUL: string =
    'UPDATE_MACHINE_API_PERMISSIONS_SUCCESSFUL';
export const UPDATE_MACHINE_API_PERMISSIONS_FAILED: string =
    'UPDATE_MACHINE_API_PERMISSIONS_FAILED';
export const GENERATE_MACHINE_API_KEY_SUCCESSFUL: string =
    'GENERATE_MACHINE_API_KEY_SUCCESSFUL';
export const GENERATE_MACHINE_API_KEY_FAILED: string = 'GENERATE_MACHINE_API_KEY_FAILED';
export const DISABLE_MACHINE_API_KEY_SUCCESSFUL: string =
    'DISABLE_MACHINE_API_KEY_SUCCESSFUL';
export const DISABLE_MACHINE_API_KEY_FAILED: string = 'DISABLE_MACHINE_API_KEY_FAILED';
export const CLEAR_MACHINE_API_KEY_ERRORS: string = 'CLEAR_MACHINE_API_KEY_ERRORS';

export const RELOAD_MANAGE_CONCESSIONAIRE_ERRORS: string =
    'RELOAD_MANAGE_CONCESSIONAIRE_ERRORS';
export const LOADING_MANAGED_CONCESSIONAIRES_SUCCESSFUL: string =
    'LOADING_MANAGED_CONCESSIONAIRES_SUCCESSFUL';
export const LOADING_MANAGED_CONCESSIONAIRES_FAILED: string =
    'LOADING_MANAGED_CONCESSIONAIRES_FAILED';
export const LOADING_CONCESSIONAIRE_PERMITS_SUCCESSFUL: string =
    'LOADING_CONCESSIONAIRE_PERMITS_SUCCESSFUL';
export const LOADING_CONCESSIONAIRE_PERMITS_FAILED: string =
    'LOADING_CONCESSIONAIRE_PERMITS_FAILED';
export const SAVING_CONCESSIONAIRE_SUCCESSFUL: string =
    'SAVING_CONCESSIONAIRE_SUCCESSFUL';
export const SAVING_CONCESSIONAIRE_FAILED: string = 'SAVING_CONCESSIONAIRE_FAILED';
export const SAVING_CONCESSIONAIRE_PERMIT_SUCCESSFUL: string =
    'SAVING_CONCESSIONAIRE_PERMIT_SUCCESSFUL';
export const SAVING_CONCESSIONAIRE_PERMIT_FAILED: string =
    'SAVING_CONCESSIONAIRE_PERMIT_FAILED';
export const SET_MANAGED_CONCESSIONAIRE: string = 'SET_MANAGED_CONCESSIONAIRE';
export const SET_CONCESSIONAIRE_PERMIT: string = 'SET_CONCESSIONAIRE_PERMIT';

export const RELOAD_MANAGE_COOPERATOR_ERRORS: string = 'RELOAD_MANAGE_COOPERATOR_ERRORS';
export const LOADING_MANAGED_COOPERATORS_SUCCESSFUL: string =
    'LOADING_MANAGED_COOPERATORS_SUCCESSFUL';
export const LOADING_MANAGED_COOPERATORS_FAILED: string =
    'LOADING_MANAGED_COOPERATORS_FAILED';
export const LOADING_COOPERATOR_EMPLOYEES_SUCCESSFUL: string =
    'LOADING_COOPERATOR_EMPLOYEES_SUCCESSFUL';
export const LOADING_COOPERATOR_EMPLOYEES_FAILED: string =
    'LOADING_COOPERATOR_EMPLOYEES_FAILED';
export const SAVING_MANAGED_COOPERATORS_SUCCESSFUL: string =
    'SAVING_MANAGED_COOPERATORS_SUCCESSFUL';
export const SAVING_MANAGED_COOPERATORS_FAILED: string =
    'SAVING_MANAGED_COOPERATORS_FAILED';
export const SAVING_COOPERATOR_SUCCESSFUL: string = 'SAVING_COOPERATOR_SUCCESSFUL';
export const SAVING_COOPERATOR_FAILED: string = 'SAVING_COOPERATOR_FAILED';
export const SAVING_COOPERATOR_EMPLOYEES_SUCCESSFUL: string =
    'SAVING_COOPERATOR_EMPLOYEES_SUCCESSFUL';
export const SAVING_COOPERATOR_EMPLOYEES_FAILED: string =
    'SAVING_COOPERATOR_EMPLOYEES_FAILED';
export const SAVING_EMPLOYEE_SUCCESSFUL: string = 'SAVING_EMPLOYEE_SUCCESSFUL';
export const DELETING_MANAGED_COOPERATORS_SUCCESSFUL: string =
    'DELETING_MANAGED_COOPERATORS_SUCCESSFUL';
export const DELETING_MANAGED_COOPERATORS_FAILED: string =
    'DELETING_MANAGED_COOPERATORS_FAILED';
export const DELETING_COOPERATOR_SUCCESSFUL: string = 'DELETING_COOPERATOR_SUCCESSFUL';
export const DELETING_COOPERATOR_EMPLOYEES_SUCCESSFUL: string =
    'DELETING_COOPERATOR_EMPLOYEES_SUCCESSFUL';
export const DELETING_COOPERATOR_EMPLOYEES_FAILED: string =
    'DELETING_COOPERATOR_EMPLOYEES_FAILED';
export const DELETING_EMPLOYEE_SUCCESSFUL: string = 'DELETING_EMPLOYEE_SUCCESSFUL';
export const SET_MANAGED_COOPERATOR: string = 'SET_MANAGED_COOPERATOR';
export const SET_COOPERATOR_EMPLOYEE: string = 'SET_COOPERATOR_EMPLOYEE';
export const GET_ASSIGNED_CONCESSIONAIRES_SUCCESS: string =
    'GET_ASSIGNED_CONCESSIONAIRES_SUCCESS';
export const GET_ASSIGNED_CONCESSIONAIRES_FAILED: string =
    'GET_ASSIGNED_CONCESSIONAIRES_FAILED';
export const FETCH_ASSIGNABLE_CONCESSIONAIRE_PERMIT_SUCCESSFUL: string =
    'FETCH_ASSIGNABLE_CONCESSIONAIRE_PERMIT_SUCCESSFUL';
export const FETCH_ASSIGNABLE_CONCESSIONAIRE_PERMIT_FAILED: string =
    'FETCH_ASSIGNABLE_CONCESSIONAIRE_PERMIT_FAILED';

export const CONFIRM_EMAIL_SUCCESS: string = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAILED: string = 'CONFIRM_EMAIL_FAILED';
export const CONFIRM_EMAIL_CHANGE_SUCCESS: string = 'CONFIRM_EMAIL_CHANGE_SUCCESS';
export const CONFIRM_EMAIL_CHANGE_FAILED: string = 'CONFIRM_EMAIL_CHANGE_FAILED';
export const CANCEL_EMAIL_CHANGE_SUCCESSFUL: string = 'CANCEL_EMAIL_CHANGE_SUCCESSFUL';
export const CANCEL_EMAIL_CHANGE_FAILED: string = 'CANCEL_EMAIL_CHANGE_FAILED';
export const CANCEL_EMAIL_CHANGE_RESET: string = 'CANCEL_EMAIL_CHANGE_RESET';

export const SET_SELECTED_TAB_INDEX: string = 'SET_SELECTED_TAB_INDEX';
export const SET_SELECTED_LOCATION: string = 'SET_SELECTED_LOCATION';

export const START_LOADING: string = 'START_LOADING';
export const DONE_LOADING: string = 'DONE_LOADING';

export const ALERTS_FETCHED: string = 'ALERTS_FETCHED';
export const FETCHING_ALERTS_FAILED: string = 'FETCHING_ALERTS_FAILED';
export const CLOSE_SITE_WIDE_ALERT: string = 'CLOSE_SITE_WIDE_ALERT';
export const ClosedSiteWideBannerMap: string = 'closed_site_wide_banner_map';

export const FETCH_DOMAINS_FOR_LOCATION_SUCCESS: string =
    'FETCH_DOMAINS_FOR_LOCATION_SUCCESS';
export const FETCH_DOMAINS_FOR_LOCATION_ERROR: string =
    'FETCH_DOMAINS_FOR_LOCATION_ERROR';
export const FETCH_DOMAINS_FOR_ROLES_SUCCESS: string = 'FETCH_DOMAINS_FOR_ROLES_SUCCESS';

export const ENABLING_PASSWORD_RESET_FAILED: string = 'ENABLING_PASSWORD_RESET_FAILED';
export const ENABLING_PASSWORD_RESET_SUCCESS: string = 'ENABLING_PASSWORD_RESET_SUCCESS';

export const FETCH_FACILITY_INFO_SUCCESS: string = 'FETCH_FACILITY_INFO_SUCCESS';
export const FETCH_FACILITY_INFO_ERROR: string = 'FETCH_FACILITY_INFO_ERROR';
export const DELETE_FACILITY_INFO_CACHE: string = 'DELETE_FACILITY_INFO_CACHE';

export const UPDATE_FACILITY_POC: string = 'UPDATE_FACILITY_POC';
export const SET_FACILITY_POC_ERROR: string = 'SET_FACILITY_POC_ERROR';
export const CLEAR_FACILITY_POC_ERROR: string = 'CLEAR_FACILITY_POC_ERROR';
export const UPDATE_FACILITY_NOTE_SUCCESS: string = 'UPDATE_FACILITY_NOTE_SUCCESS';
export const ADD_FACILITY_NOTE_ERROR: string = 'ADD_FACILITY_NOTE_ERROR';
export const CLEAR_FACILITY_NOTE_ERRORS: string = 'CLEAR_FACILITY_NOTE_ERRORS';
export const INT_FEEDBACK_SUCCESS: string = 'INT_FEEDBACK_SUCCESS';
export const INT_FEEDBACK_FAILED: string = 'INT_FEEDBACK_FAILED';
export const SET_FACILITY_MODAL_RESULT: string = 'SET_FACILITY_MODAL_RESULT';
export const SET_FACILITY_FLAG_SUCCESS: string = 'SET_FACILITY_FLAG_SUCCESS';
export const SET_SHOULD_OPEN_MODAL: string = 'SET_SHOULD_OPEN_MODAL';
export const SET_FACILITY_FLAG_ERROR: string = 'SET_FACILITY_FLAG_ERROR';
export const START_LOCATION_ENTRANCE_REQUEST: string = 'START_LOCATION_ENTRANCE_REQUEST';
export const LOCATION_ENTRANCE_REQUEST_ERROR: string = 'LOCATION_ENTRANCE_REQUEST_ERROR';
export const LOCATION_ENTRANCE_REQUEST_SUCCESS: string =
    'LOCATION_ENTRANCE_REQUEST_SUCCESS';

export const FETCH_ATTESTATION_START: string = 'FETCH_ATTESTATION_START';
export const FETCH_ATTESTATION_SUCCESS: string = 'FETCH_ATTESTATION_SUCCESS';
export const FETCH_ATTESTATION_FAILURE: string = 'FETCH_ATTESTATION_FAILURE';
export const FETCH_ATTESTATION_RESET: string = 'FETCH_ATTESTATION_RESET';
export const POST_ATTESTATION_START: string = 'POST_ATTESTATION_START';
export const POST_ATTESTATION_SUCCESS: string = 'POST_ATTESTATION_SUCCESS';
export const POST_ATTESTATION_FAILURE: string = 'POST_ATTESTATION_FAILURE';
export const ATTESTATION_STATE: string = 'ATTESTATION_STATE';
export const KEEP_ALIVE_CALLED: string = 'KEEP_ALIVE_CALLED';

// OLD KILL //
export const LOADING_CONCESSIONAIRE_PERMIT_DETAILS_SUCCESSFUL: string =
    'LOADING_CONCESSIONAIRE_PERMIT_DETAILS_SUCCESSFUL';
export const LOADING_CONCESSIONAIRE_PERMIT_DETAILS_FAILED: string =
    'LOADING_CONCESSIONAIRE_PERMIT_DETAILS_FAILED';
export const GET_CONCESSIONAIRE_SUCCESS: string = 'GET_CONCESSIONAIRE_SUCCESS';
export const GET_CONCESSIONAIRE_FAILED: string = 'GET_CONCESSIONAIRE_FAILED';
// OLD //

export const USER_SEARCH_FIELD_CHANGED: string = 'USER_SEARCH_FIELD_CHANGED';
export const USER_SEARCH_VALUE_CHANGED: string = 'USER_SEARCH_VALUE_CHANGED';
export const USER_SEARCH_FILTER_CHANGED: string = 'USER_SEARCH_FILTER_CHANGED';

export const LOCATION_CHANGE: string = 'IA_ROUTING_LOCATION_CHANGE';
export const EMAIL_CHANGE_CANCELLED: string = 'EMAIL_CHANGE_CANCELLED';
export const LOGIN_GOV_UNLINKED: string = 'LOGIN_GOV_UNLINKED';
export const LOGIN_GOV_UNLINKED_MY_PROFILE: string = 'LOGIN_GOV_UNLINKED_MY_PROFILE';

export const FETCH_FEATURE_FLAGS_SUCCESS: string = 'FETCH_FEATURE_FLAGS_SUCCESS';
export const FETCH_FEATURE_FLAGS_FAILED: string = 'FETCH_FEATURE_FLAGS_FAILED';

export const SET_TOAST_NOTIFICATION: string = 'SET_TOAST_NOTIFICATION';
export const DISMISS_TOAST_NOTIFICATION: string = 'DISMISS_TOAST_NOTIFICATION';

export const concessionaireHierarchyTypes: Record<string, string> = {
    START: 'HIERARCHY_START',
    ADD: 'HIERARCHY_ADD',
    ERROR: 'HIERARCHY_ERROR',
    END: 'HIERARCHY_END',
};

export const GENERATE_MFA_TOTP_SUCCESSFUL: string = 'GENERATE_MFA_TOTP_SUCCESSFUL';
export const GENERATE_MFA_TOTP_FAILED: string = 'GENERATE_MFA_TOTP_FAILED';
export const GENERATE_MFA_TOTP_CLEAR: string = 'GENERATE_MFA_TOTP_CLEAR';
export const PATCH_MFA_OPT_OUT_SUCCESSFUL: string = 'PATCH_MFA_OPT_OUT_SUCCESSFUL';
export const PATCH_MFA_OPT_OUT_FAILED: string = 'PATCH_MFA_OPT_OUT_FAILED';
export const VALIDATE_MFA_FACTOR_SUCCESSFUL: string = 'VALIDATE_MFA_FACTOR_SUCCESSFUL';
export const VALIDATE_MFA_FACTOR_FAILED: string = 'VALIDATE_MFA_FACTOR_FAILED';
export const CREATE_MFA_BACKUP_CODES_SUCCESSFUL: string =
    'CREATE_MFA_BACKUP_CODES_SUCCESSFUL';
export const CREATE_MFA_BACKUP_CODES_FAILED: string = 'CREATE_MFA_BACKUP_CODES_FAILED';

export type CalendarDateOrString = string | CalendarDate;
export type CalendarDateTimeOrString = string | CalendarDateTime;
export type ZonedDateTimeOrString = string | ZonedDateTime;
export type ErrorOrString = Error | string;

export type Location = {
    location_id: string;
    location_type: string;
    location_parent_id?: string;
    location_parent_type?: string;
    location_type_category?: string;
    location_agency_name?: string;
    location_attributes?: string;
    location_children_count?: string;
    location_description?: string;
    location_legacy_id?: string;
    location_name?: string;
    location_path?: string;
    location_state_code?: string;
    has_lottery?: boolean;
    has_cooperators?: boolean;
    has_park_pass?: boolean;
    hierarchyChildren?: Location[];
};

export type Permit = Location & {
    permit_id: string;
    concessionaire_id: string;
    location_id: string;
    permit_start_date: string;
    permit_end_date: string;
    valid?: boolean;
};

export type Role = {
    user_id: string;
    location_id: string;
    location_type: string;
    agency_name: string;
    is_default_role: boolean;
    role_type: string;
    updated_at: string;
    location: Location;
    can_attest_to_peers?: boolean;
    permit_id?: string;
    poc_type?: string;
};

export type SimplifiedRole = {
    role_type: string;
    can_attest_to_peers: boolean | undefined;
    location: {
        location_id: string;
        location_type: string;
    };
    location_id: string;
    location_type: string;
};

export type RoleForLocation = {
    role_type: string;
    location: Location;
};

export type PhoneNumber = {
    ext: string;
    phone: string;
    type: string;
};

export type MfaData = {
    bypass_at: string;
    opt_out_at: string;
    verified_at: string;
};

export type EmailChange = {
    completed_at: string;
    new_email: string;
    old_email: string;
};

export type OidcInfo = {
    enabled: boolean;
    modified_at: string;
};

export type OidcInfoMap = {
    [key: string]: OidcInfo;
};

export type UserEffectiveDate = {
    user_id: string;
    enforce_effective_dates: boolean;
    effective_start_at: Moment | string | null;
    effective_end_at: Moment | string | null;
};

export type UserPersonalInfo = {
    user_id: string;
    first_name: string;
    last_name: string;
    phone_numbers: PhoneNumber[];
};

export type User = UserEffectiveDate &
    UserPersonalInfo & {
        user_id: string;
        can_run_transactions: boolean;
        created_at: string;
        creator_id: string;
        email: string;
        is_concessionaire: boolean;
        concessionaire_id?: string;
        concessionaire_ids?: string[];
        should_receive_sms?: boolean;
        roles: Role[];
        mfa_data?: MfaData;
        locked?: boolean;
        confirmEmailChangeVerified?: boolean;
        password_set_at: string;
        last_logged_in_at: string;
        is_email_confirmed: boolean;
        email_change: EmailChange;
        oidc_user_info: OidcInfoMap;
        inherits_concessionaire_roles: boolean;
    };

export type Cooperator = {
    cooperator_name: string;
    phone_number: string;
    email_address: string;
};

export type Concessionaire = {
    concessionaire_id: string;
    concessionaire_name: string;
    primary_contact_phone: string;
    secondary_contact_phone: string;
    primary_contact_email: string;
    secondary_contact_email: string;
    permits: Permit[];
};

export type Concessionaires = Concessionaire[];

export type ConcessionairesAction = {
    concessionaires: Concessionaires;
    type: string;
};

export type LocationNote = {
    location_id: string;
    note_id: string;
    mandatory: boolean;
    note: string;
    seq_num: number;
    creator_id?: string;
    created_date?: string;
    last_updated_by?: string;
    last_updated_at?: string;
    submissionId?: string;
};

export type TTL = {
    time_to_live: string;
    error: Error;
};

export type DropdownOption = {
    value: string;
    label: string;
    order?: number;
};

export type RoleAssignmentOption = {
    dropDownOptions: DropdownOption[];
    initiallySelectedOption: DropdownOption | string | null;
};

export type AssignedRole = {
    role_type: string;
    location: Location;
    can_attest_to_peers: boolean;
};

export type UpdateUser = {
    assignedLocations: Location[];
    assignedRoleForLocationId: { [key: string]: AssignedRole };
};

export type State = {
    updateUser: UpdateUser;
};

export type AssigningLocationsAction = Action & {
    roleAssignmentOptions?: { [key: string]: RoleAssignmentOption };
    assignedRoleForLocationId?: { [key: string]: AssignedRole };
    error?: string;
};

export type ConcessionaireAction = Action & {
    concessionaire?: Concessionaire;
};

export type ErrorAction = Action & {
    ttl?: string;
    error: string | Error | undefined;
};

export type LocationAction = Action & {
    location?: Location;
};

export type UserAction = Action & {
    user?: User;
    updatedUser?: User;
};

export type RoleAction = Action & {
    role: Role;
    user?: User;
};

export type MfaAction = Action & {
    accountToSave: User;
    readOnly: boolean;
};

export type DeleteUserAction = Action & {
    deletedUser: User;
    append: boolean;
};
