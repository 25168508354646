/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    role: null,
};

const selectedLocationRoleReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN_SUCCESSFUL:
            return { ...state, role: action.initiallySelectedRole };
        case types.SET_SELECTED_LOCATION:
            return { ...state, role: action.role };
        case types.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};

export default selectedLocationRoleReducer;
